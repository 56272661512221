import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import Image from 'react-bootstrap/Image';
import Section from './Section';
import SectionHeader from './SectionHeader';
import './HeroSection.scss';

function HeroSection({
  bg,
  textColor,
  size,
  bgImage,
  bgImageOpacity,
  title,
  subtitle,
  buttonPath,
  buttonColor,
  buttonText,
  image
}) {
  return (
    <Section
      bg={bg}
      textColor={textColor}
      size={size}
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg={5} className="text-center text-lg-left">
            <SectionHeader title={title} subtitle={subtitle} size={1} spaced />
            <LinkContainer to={buttonPath}>
              <Button variant={buttonColor} size="lg">
                {buttonText}
              </Button>
            </LinkContainer>
          </Col>
          <Col className="offset-lg-1 mt-5 mt-lg-0 ">
            <figure className="HeroSection__image-container mx-auto">
              <Image src={image} fluid />
            </figure>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

HeroSection.propTypes = {
  bg: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonPath: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageOpacity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default HeroSection;
