import React, { useContext } from 'react';
import Table from 'rsuite/Table';
import RoundTable from './RoundTable';
import Filters from '../filters';
import LowerTable from '../../../LowerTable';
import { TableAndPlotContext } from '../../common/TablesAndPlotsContext';

const { Column, HeaderCell, Cell } = Table;

function getColumn(item, index) {
  return (
    <Column flexGrow={1} align="center" key={`${item}-${index}`} fullText>
      <HeaderCell>{item}</HeaderCell>
      <Cell dataKey={`${index}`} />
    </Column>
  );
}

const TablesScreen = () => {
  const {
    ilcpData,
    facility,
    method,
    parameter,
    instrument,
    t1Body,
    loadingLowertables,
    t1Head,
    t2Body,
    t2Head,
    names,
    loading
  } = useContext(TableAndPlotContext);

  return (
    <>
      <Filters names={names} loading={loading} />

      <RoundTable tableData={ilcpData?.[facility]?.[method]?.[parameter]?.[instrument] ?? []} />
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          width: '100%',
          marginBottom: '20px'
        }}
      >
        <div style={{ width: '100%' }}>
          <LowerTable
            title="Table 1: Study Evaluation"
            body={t1Body}
            fullHeight
            loadingChartData={loadingLowertables}
          >
            {t1Head.map((item, index) => getColumn(item, index))}
          </LowerTable>
        </div>

        <div style={{ width: '100%' }}>
          <LowerTable
            title="Table 2: Plotting Position Calculation"
            body={t2Body}
            fullHeight
            loadingChartData={loadingLowertables}
          >
            {t2Head.map((item, index) => getColumn(item, index))}
          </LowerTable>
        </div>
      </div>
    </>
  );
};

export default TablesScreen;
