import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton';
import './PrintReportPage.scss';
import { useLocation } from 'react-router-dom';
import { requireAuth } from '../../util/auth';
import CustomTable from '../Table/CustomTable';

function PrintReportPage() {
  const location = useLocation();
  const { currentModalReport, tenNinetyData, tenNinetyColumns } = location.state || {};
  const pageSizeDefault = 20;
  useEffect(() => {
    setTimeout(() => {
      window.print();
    }, 500);
  }, []);

  return (
    <>
      <Row className="report-header">
        <h4>EPA Compliance Report for Precision of Alternative Procedure: 40 CFR 1090.1365</h4>
      </Row>
      <Row>
        <Col>Method:</Col>
        <Col>{currentModalReport.method}</Col>
        <Col>Location:</Col>
        <Col>{currentModalReport.location}</Col>
      </Row>
      <Row>
        <Col>Instrument:</Col>
        <Col>{currentModalReport.instrument}</Col>
        <Col>Date:</Col>
        <Col>{currentModalReport.completedOn}</Col>
      </Row>
      <Row>
        <Col>Fuel, additive or blendstock:</Col>
        <Col>{currentModalReport.fab}</Col>
        <Col>Assessment by:</Col>
        <Col>{currentModalReport.completedBy}</Col>
      </Row>
      <Row>
        <Col>Parameter:</Col>
        <Col>{currentModalReport.parameter}</Col>
        <Col>Pre-treated:</Col>
        <Col>{currentModalReport.pretreated}</Col>
      </Row>
      <Row className="report-header" />
      <Row className="statistics-table">
        <CustomTable
          numberOfRows={pageSizeDefault}
          data={tenNinetyData}
          header={tenNinetyColumns}
          hideFooter={true}
        />
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '4px' }}>
        <Col lg={7}>
          <h6>6. Assessor comments</h6>
        </Col>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '4px' }}>
        <Col lg={8}>
          <Form>
            <Form.Group>
              <Form.Control
                className="comments"
                as="textarea"
                rows={2}
                aria-label="Basic example"
                value={currentModalReport.comments}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center', marginBottom: '4px' }}>
        <Col lg={7}>
          <h6>7. Assessment outcome</h6>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: 'flex', justifyContent: 'center', gap: '4px' }}>
          <ToggleButton
            key="radioTrending-pass"
            id="radioTrending-pass"
            type="radio"
            variant="outline-primary"
            name="radio"
            value="Pass"
            style={
              currentModalReport.outcome === 'Pass'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            checked={currentModalReport.outcome === 'Pass'}
          >
            Pass
          </ToggleButton>
          <ToggleButton
            key="radioTrending-fail"
            id="radioTrending-fail"
            type="radio"
            variant="outline-primary"
            name="radio"
            value="Fail"
            style={
              currentModalReport.outcome === 'Fail'
                ? { backgroundColor: 'navy', color: 'white' }
                : {}
            }
            checked={currentModalReport.outcome === 'Fail'}
          >
            Fail
          </ToggleButton>
        </Col>
      </Row>
    </>
  );
}

export default requireAuth(PrintReportPage);
