import React from 'react';
import Tag from 'rsuite/Tag';
import Toggle from 'rsuite/Toggle';
import CalculationDetailHeader from './calculationDetailHeader';
import SampleInfo from './sampleInfo';
import CalculationDetail from './calculationDetail';

const CalculationPrint = () => {
  const calculation = JSON.parse(localStorage.getItem('calculation'));

  window.print();

  const baseStyle = {
    fontsize: '1rem',
    color: 'black'
  };

  const noMargin = { ...baseStyle, margin: 0 };
  const blueStyle = { ...noMargin, backgroundColor: '#7fc3ff' };
  return (
    <div
      style={{
        margin: 'auto',
        display: 'grid',
        gap: '1rem',
        justifyContent: 'center'
      }}
    >
      <CalculationDetailHeader />

      <SampleInfo
        program={calculation.program}
        parameter={calculation.parameter}
        fuelType={calculation.fuelType}
        user={calculation.user}
        location={calculation.location}
      />

      <CalculationDetail calculation={calculation} />

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 5fr',
          gap: '1rem'
        }}
      >
        <Tag style={blueStyle} size="lg">
          Assessment comments
        </Tag>
        <Tag style={noMargin}>{calculation.comments}</Tag>
        <Tag style={blueStyle} size="lg">
          Applicable Date
        </Tag>
        <Tag style={noMargin}>{calculation.applicableDate}</Tag>

        <Tag style={blueStyle} size="lg">
          User Code
        </Tag>
        <Tag style={noMargin}>{calculation.userCode}</Tag>

        <Tag style={blueStyle} size="lg">
          Assessment outcome
        </Tag>

        <Toggle
          size="lg"
          checkedChildren="Pass"
          unCheckedChildren="Fail"
          checked={calculation.hasPassed}
        />
      </div>
    </div>
  );
};

export default CalculationPrint;
