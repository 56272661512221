export const tableStyle = {
  '.MuiDataGrid-cellContent': {
    overflow: 'visible',
    textOverflow: 'unset',
    whiteSpace: 'wrap'
  },
  '.MuiTablePagination-displayedRows': {
    marginBottom: 'auto',
    marginTop: 'auto'
  },
  '.MuiTablePagination-selectLabel': {
    marginBottom: 'auto',
    marginTop: 'auto'
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
    overflow: 'visible',
    textOverflow: 'unset',
    whiteSpace: 'wrap',
    lineHeight: '24px'
  },
  '.MuiDataGrid-sortIcon': {
    color: '#000'
  },
  '.MuiDataGrid-columnHeader--withRightBorder:last-child': {
    borderRight: 'none'
  },
  '.MuiDataGrid-cell--withRightBorder:last-child': {
    borderRight: 'none'
  },
  '.actions:not(:first-of-type)': {
    borderRight: 'none'
  },
  '.MuiDataGrid-cell--editing .MuiInputBase-root': {
    fontSize: '0.875rem'
  },
  '.MuiPagination-root .MuiPaginationItem-root.Mui-selected': {
    backgroundColor: '#000080'
  }
};
