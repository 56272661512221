import React from 'react';
import PropTypes from 'prop-types';

import './SectionHeader.scss';

function SectionHeader({ size, title, subtitle, className, spaced }) {
  // Render nothing if no title or subtitle
  if (!title && !subtitle) {
    return null;
  }

  return (
    <header className={`SectionHeader${className ? ` ${className}` : ''}`}>
      {title && (
        <h1
          className={`font-weight-bold${subtitle && spaced ? ' mb-4' : ''}${
            !subtitle ? ' mb-0' : ''
          }${size ? ` h${size}` : ''}`}
        >
          {title}
        </h1>
      )}

      {subtitle && <p className="SectionHeader__subtitle mb-0">{subtitle}</p>}
    </header>
  );
}

SectionHeader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
  spaced: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default SectionHeader;
