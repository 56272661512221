import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Section from './Section';
import SectionHeader from './SectionHeader';
import Auth from './Auth';
import AuthFooter from './AuthFooter';

function AuthSection({
  bg,
  textColor,
  size,
  bgImage,
  bgImageOpacity,
  type,
  providers,
  afterAuthPath
}) {
  // Options by auth type
  const optionsByType = {
    signup: {
      // Top Title
      title: 'Get yourself an account',
      // Button text
      buttonAction: 'Sign up',
      // Footer text and links
      showFooter: true,
      signinText: 'Already have an account?',
      signinAction: 'Sign in',
      signinPath: '/auth/signin',
      // Terms and privacy policy agreement
      showAgreement: true,
      termsPath: '/legal/terms-of-service',
      privacyPolicyPath: '/legal/privacy-policy'
    },
    signin: {
      title: 'Welcome back',
      buttonAction: 'Sign in',
      showFooter: true,
      signupAction: 'Create an account',
      signupPath: '/auth/signup',
      forgotPassAction: 'Forgot Password?',
      forgotPassPath: '/auth/forgotpass'
    },
    forgotpass: {
      title: 'Get a new password',
      buttonAction: 'Reset password',
      showFooter: true,
      signinText: 'Remember it after all?',
      signinAction: 'Sign in',
      signinPath: '/auth/signin'
    },
    changepass: {
      title: 'Choose a new password',
      buttonAction: 'Change password'
    }
  };

  // Ensure we have a valid auth type
  const chosenType = optionsByType[type] ? type : 'signup';

  // Get options object for current auth type
  const options = optionsByType[chosenType];

  return (
    <Section
      bg={bg}
      textColor={textColor}
      size={size}
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      <Container
        style={{
          maxWidth: '450px'
        }}
      >
        <SectionHeader
          style={{ marginBottom: '1rem' }}
          title={options.title}
          subtitle=""
          size={2}
          spaced
          className="text-center"
        />
        <Auth
          type={chosenType}
          buttonAction={options.buttonAction}
          providers={providers}
          afterAuthPath={afterAuthPath}
          key={chosenType}
        />

        {options.showFooter && <AuthFooter type={chosenType} {...options} />}
      </Container>
    </Section>
  );
}

AuthSection.propTypes = {
  bg: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageOpacity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['signup', 'signin', 'forgotpass', 'changepass']).isRequired,
  providers: PropTypes.arrayOf(PropTypes.string).isRequired,
  afterAuthPath: PropTypes.string.isRequired
};

export default AuthSection;
