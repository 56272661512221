import React, { useEffect, useState } from 'react';
import './PersonnelSection.scss';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { UPDATE_TYPE, CUSTOM_FIELD, SCREEN_PERMISSION } from '../../util/Constant';
import './PersonnelSection.scss';

const PermissionEdit = ({
  showPermissionEditModal,
  setShowPermissionEditModal,
  permissionEdit,
  permissionData,
  setPermissionData
}) => {
  const [table, setTable] = useState(null);
  const [tableValue, setTableValue] = useState('');
  const [custom, setCustom] = useState(false);
  const [customData, setCustomData] = useState([]);

  useEffect(() => {
    if (permissionEdit) {
      setTable(permissionEdit.table);
      const table = SCREEN_PERMISSION.find((item) => item.value === permissionEdit.name);
      if (table) {
        setTableValue(table.label);
      }
      const customValue = permissionEdit.table?.update?.type;
      setCustom(customValue === UPDATE_TYPE.CUSTOM);
      if (customValue === UPDATE_TYPE.CUSTOM) {
        setCustomData(CUSTOM_FIELD[permissionEdit.name] || []);
      }
    }
  }, [permissionEdit]);

  const editTable = () => {
    setPermissionData({ ...permissionData, [permissionEdit.name]: table });
    setShowPermissionEditModal(false);
  };

  const onCheckBoxChange = (e, type) => {
    const value = e.target.checked;
    setTable({ ...table, [type]: value });
  };

  const onUpdateChange = (e, type) => {
    const value = e.target.checked;
    setCustom(type === UPDATE_TYPE.CUSTOM && value);
    setTable({ ...table, update: { ...table['update'], type: type } });
    if (value && type === UPDATE_TYPE.CUSTOM) {
      const a = CUSTOM_FIELD[permissionEdit.name];
      setCustomData(a);
    }
  };

  const onAllowFieldChange = (e, type) => {
    const value = e.target.checked;
    let listField;
    if (value) {
      listField = table['update'].fieldAllow;
      listField.push(type);
    } else {
      listField = table['update'].fieldAllow.filter((item) => {
        return item !== type;
      });
    }
    setTable({
      ...table,
      update: { ...table['update'], fieldAllow: listField }
    });
  };

  return (
    <Modal
      size="xl"
      show={showPermissionEditModal}
      onHide={() => setShowPermissionEditModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Contribution Permission Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="manage-data-modal">
          <Form.Group>
            <Form.Label>{tableValue}</Form.Label>
          </Form.Group>
          <Form.Group>
            <Form.Label>Permission</Form.Label>
            <div className="permission-row">
              <span className="permission-title">View</span>
              <Form.Check
                disabled={table === null}
                checked={table?.view || false}
                onChange={(e) => onCheckBoxChange(e, 'view')}
                id="view"
              />
            </div>
            <div className="permission-row">
              <span className="permission-title">Create</span>
              <Form.Check
                disabled={table === null}
                checked={table?.create || false}
                onChange={(e) => onCheckBoxChange(e, 'create')}
                id="create"
              />
            </div>
            <div className="permission-row">
              <span className="permission-title">Delete</span>

              <Form.Check
                disabled={table === null}
                checked={table?.delete || false}
                onChange={(e) => onCheckBoxChange(e, 'delete')}
              />
            </div>
            <div className="permission-row">
              <span className="permission-title">Update</span>
              <div>
                <input
                  disabled={table === null}
                  class="form-check-input"
                  type="radio"
                  name="update"
                  checked={table?.update?.type === UPDATE_TYPE.NONE || false}
                  id="none"
                  onChange={(e) => {
                    onUpdateChange(e, UPDATE_TYPE.NONE);
                  }}
                />
                <label className="checkbox-title" for="None">
                  None
                </label>
              </div>
              <div>
                <input
                  disabled={table === null}
                  class="form-check-input"
                  type="radio"
                  name="update"
                  id="all"
                  checked={table?.update?.type === UPDATE_TYPE.ALL || false}
                  onChange={(e) => {
                    onUpdateChange(e, UPDATE_TYPE.ALL);
                  }}
                />
                <label className="checkbox-title" for="all">
                  All
                </label>
              </div>
              <div class="form-check">
                <input
                  disabled={table === null}
                  class="form-check-input"
                  type="radio"
                  name="update"
                  id="custom"
                  checked={table?.update?.type === UPDATE_TYPE.CUSTOM || false}
                  onChange={(e) => {
                    onUpdateChange(e, UPDATE_TYPE.CUSTOM);
                  }}
                />
                <label className="checkbox-title" for="custom">
                  Custom
                </label>
              </div>
            </div>
            <div>
              {custom &&
                customData &&
                customData.length > 0 &&
                customData.map((item) => {
                  return (
                    <div className="permission-row">
                      <Form.Check
                        disabled={table === null}
                        defaultChecked={table?.update?.fieldAllow?.includes(item.value) || false}
                        onChange={(e) => onAllowFieldChange(e, item.value)}
                        id="create"
                      />
                      <span className="checkbox-title">{item.lable}</span>
                    </div>
                  );
                })}
            </div>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton text="Cancel" clicked={() => setShowPermissionEditModal(false)} />
        <PrimaryButton text="Edit" clicked={() => editTable()} />
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionEdit;
