import React, { useEffect, useState } from 'react';
import CustomTable from '../../Table/CustomTable';
import { associatedEventColumns, reassignInstrumentColumns } from './Columns';
import {
  setInstrumentEquipments,
  setInstrumentEvents,
  useGetPersonnel,
  useInstrumentEquipments,
  useInstrumentSettings
} from '../../../util/db';
import { SETTING_TYPE } from './DefineCategories';
import { Col, Form, Row } from 'react-bootstrap';
import { useAuth } from '../../../util/auth';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';
import { alphaNumericSorter, renderMUIButtonWithPermissions } from '../../../util/util';

const ReassignInstruments = () => {
  const auth = useAuth();
  const [fromResponsiblePartyId, setFromResponsiblePartyId] = useState();
  const [toResponsiblePartyId, setToResponsiblePartyId] = useState();

  const { data: instruments = [] } = useInstrumentEquipments({
    responsiblePartyId: fromResponsiblePartyId
  });
  const { data: personnel = [] } = useGetPersonnel();

  const { data: instrumentSettingsInstrumentStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_STATUS
  );
  const { data: instrumentSettingsInstrumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_TYPE
  );
  const { data: instrumentSettingsInstrumentParameter = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_PARAMETER
  );
  const { data: allManufacturers = [] } = useInstrumentSettings(
    SETTING_TYPE.INSTRUMENT_MANUFACTURER
  );
  const { data: allLocations = [] } = useInstrumentSettings(SETTING_TYPE.INSTRUMENT_LOCATION);

  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  const handleSubmit = async () => {
    if (!fromResponsiblePartyId || !toResponsiblePartyId) {
      return;
    }

    await Promise.all(
      instruments
        .filter((i) => rowSelectionModel.includes(i.id))
        .map((i) => {
          const { statusName, typeName, parameterName, responsiblePartyName, ...instrument } = i;

          return setInstrumentEquipments({ ...instrument, responsibleParty: toResponsiblePartyId });
        })
    );

    setFromResponsiblePartyId('');
    setToResponsiblePartyId('');
  };

  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.VIEW) ? (
        <>
          <div className="filter">
            <div>
              <Row className="flex-end">
                <Form.Group as={Col}>
                  <Form.Label>Responsible Party</Form.Label>
                  <Form.Select
                    value={fromResponsiblePartyId}
                    onChange={(event) => setFromResponsiblePartyId(event.target.value)}
                  >
                    <option value="">All Responsible Party</option>
                    {personnel.sort(alphaNumericSorter).map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Assign To</Form.Label>
                  <Form.Select
                    onChange={(event) => setToResponsiblePartyId(event.target.value)}
                    value={toResponsiblePartyId}
                  >
                    <option value="">All Responsible Party</option>
                    {personnel
                      .sort(alphaNumericSorter)
                      ?.filter((p) => p?.id !== fromResponsiblePartyId)
                      .map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col}>
                  {renderMUIButtonWithPermissions(
                    'Re-assign',
                    () => handleSubmit(),
                    SCREEN.INSTRUMENT_SETTINGS,
                    RULES.UPDATE,
                    permissionData
                  )}
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="grid-view">
            <h3>List of instruments with selected Responsible Party</h3>
            <div className="table-box">
              <CustomTable
                data={instruments.map((row) => {
                  const dataStatus = instrumentSettingsInstrumentStatus.find(
                    (item) => item.id === row.status
                  );
                  const dataType = instrumentSettingsInstrumentType.find(
                    (item) => item.id === row.type
                  );
                  const dataParameter = instrumentSettingsInstrumentParameter.find(
                    (item) => item.id === row.parameter
                  );
                  const responsibleParty = personnel.find(
                    (item) => item.id === row?.responsibleParty
                  );
                  const location = allLocations.find((l) => l.id === row.location);
                  const manufacturer = allManufacturers.find((m) => m.id === row.manufacturer);
                  return {
                    ...row,
                    manufacturer: manufacturer?.name,
                    status: dataStatus?.name,
                    type: dataType?.name,
                    parameter: dataParameter?.name,
                    location: location?.name,
                    responsibleParty: responsibleParty?.name
                  };
                })}
                header={reassignInstrumentColumns}
                numberOfRows={20}
                rowHeight="auto"
                sort={{ sorting: { sortModel: [{ field: 'eventNumber', sort: 'asc' }] } }}
                sx={{
                  '.MuiDataGrid-cell': {
                    minHeight: '52px !important'
                  },
                  '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
                    display: 'none'
                  }
                }}
                checkboxSelection={hasPermission(
                  permissionData,
                  SCREEN.INSTRUMENT_SETTINGS,
                  RULES.UPDATE
                )}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
              />
            </div>
          </div>
        </>
      ) : (
        <> </>
      )}
    </>
  );
};

export default ReassignInstruments;
