import { useEffect, useState } from 'react';
import { getStcmpPrograms, getLocationData } from '../../../util/db';
import mapToSelect from './mapToSelect';

const mappingProgram = (program) => ({
  name: program.name,
  select: mapToSelect(program.name),
  substances: program.header.map(({ col }) => mapToSelect(col)),
  header: program.header,
  programId: program.programId,
  facility: program.facility,
  sheetId: program.sheetId,
  root: program
});

const usePrograms = () => {
  const [facilities, setFacilities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [reload, setReload] = useState(false);
  const [, setProgram] = useState('');
  const [selectedFacility, setSelectedFacility] = useState('');
  const [locations, setLocations] = useState([]);

  function loadPrograms() {
    const facs = [];
    const prog = [];
    setLoading(true);
    getStcmpPrograms()
      .then((ps) => {
        ps.forEach((p) => {
          prog.push(mappingProgram(p));
          facs.push(p.facility);
        });
        setPrograms(prog);
        setFacilities([...new Set(facs)]);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const loadLocations = async () => {
    const locations = await getLocationData();
    setLocations(locations);
  };

  useEffect(() => {
    loadLocations();
    loadPrograms();
  }, []);

  useEffect(() => {
    loadPrograms();
  }, [reload]);

  const filterPrograms = () => {
    return programs.filter((p) => p.facility === selectedFacility);
  };

  const filterByProgram = (p) => {
    return filterPrograms().find((pr) => pr.name === p);
  };

  const dataSets = filterPrograms().map(({ select }) => select);

  const getSubstances = (p) => filterByProgram(p)?.substances || [];

  const getProgramId = (p) => filterByProgram(p)?.programId || '';
  const getSheetId = (p) => filterByProgram(p)?.sheetId || '';

  const newPrograms = (p) => setPrograms(p);

  return [
    loading,
    () => setReload(!reload),
    dataSets,
    getSubstances,
    getProgramId,
    getSheetId,
    setProgram,
    facilities,
    selectedFacility,
    setSelectedFacility,
    programs,
    newPrograms,
    locations
  ];
};

export default usePrograms;
