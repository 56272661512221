import EyeCloseIcon from '@rsuite/icons/EyeClose';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import VisibleIcon from '@rsuite/icons/Visible';
import PropTypes from 'prop-types';
import React from 'react';
import List from 'rsuite/List';
import Notification from 'rsuite/Notification';
import Table from 'rsuite/Table';
import Tag from 'rsuite/Tag';
import Toggle from 'rsuite/Toggle';
import Whisper from 'rsuite/Whisper';
import { notes } from './constants';

const { Column, HeaderCell, Cell } = Table;
export const EditCell = ({ rowData, dataKey, onChange, onUpdate, ...props }) => {
  return (
    <Cell {...props}>
      <input
        className="input"
        style={{ width: '100%' }}
        value={rowData[dataKey] || ''}
        onChange={(event) => {
          // eslint-disable-next-line no-param-reassign
          rowData[dataKey] = event.target.value;
          if (onChange) {
            onChange(rowData);
          }
        }}
        onBlur={(event) => {
          if (event) onUpdate(rowData);
        }}
      />
    </Cell>
  );
};

EditCell.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string,
  onChange: PropTypes.func,
  onUpdate: PropTypes.func
};

EditCell.defaultProps = {
  rowData: {},
  dataKey: '',
  onChange: () => {},
  onUpdate: () => {}
};

const OmitCell = ({ rowData, dataKey, onChange, ...props }) => {
  return (
    <Cell {...props}>
      <Toggle
        checked={rowData[dataKey] || false}
        // disabled={!rowData.omit}
        disabled={!rowData.omit && !rowData.explanation}
        onChange={() => {
          // eslint-disable-next-line no-param-reassign
          rowData[dataKey] = !rowData[dataKey];

          onChange(rowData);
        }}
        checkedChildren={<EyeCloseIcon />}
        unCheckedChildren={<VisibleIcon />}
        size="lg"
      />
    </Cell>
  );
};

OmitCell.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string,
  onChange: PropTypes.func
};

OmitCell.defaultProps = {
  rowData: {},
  dataKey: '',
  onChange: () => {}
};

const Notes = React.forwardRef(({ style, onClose, ...rest }, ref) => {
  const styles = {
    ...style,
    color: '#000',
    background: '#fff',
    width: 400,
    position: 'absolute',
    boxShadow: '0 3px 6px -2px rgba(0, 0, 0, 0.6)',
    zIndex: 99
  };

  return (
    <Notification type="info" header="Notes" ref={ref} style={styles} {...rest}>
      <List>
        {notes.map((item) => (
          <List.Item key={`note-${item.key}`}>
            <Tag color="blue"> {item.key} </Tag> {item.value}
          </List.Item>
        ))}
      </List>
    </Notification>
  );
});

Notes.propTypes = {
  style: PropTypes.object,
  onClose: PropTypes.func
};

Notes.defaultProps = {
  style: {},
  onClose: () => {}
};

function CustomCell(dataKey) {
  if (dataKey) {
    return (
      <Cell>
        {(rowData) => {
          if (rowData[dataKey]) {
            return Math.round(rowData[dataKey] * 1000) / 1000;
          }
          return '';
        }}
      </Cell>
    );
  }
}

function Columns(round, updateRow, updateExplanation, onChangeExplanation) {
  return (
    <>
      <Column width={200} align="center">
        <HeaderCell>Facility</HeaderCell>
        <Cell dataKey="facilityName" />
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Instrument</HeaderCell>
        <Cell dataKey="equipment" />
      </Column>
      <Column width={200} align="center">
        <HeaderCell>{round}</HeaderCell>
        <Cell dataKey="result" />
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Deviation</HeaderCell>
        {CustomCell('deviation')}
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Z-score</HeaderCell>
        {CustomCell('z')}
      </Column>
      <Column width={200} align="center">
        <HeaderCell>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                marginRight: 5
              }}
            >
              Notes
            </div>
            <Whisper
              placement="bottomStart"
              trigger="click"
              speaker={(props, ref) => {
                // eslint-disable-next-line react/prop-types
                const { className, left, top, onClose } = props;
                return (
                  <Notes
                    style={{
                      left,
                      top
                    }}
                    onClose={onClose}
                    className={className}
                    ref={ref}
                  />
                );
              }}
            >
              <InfoOutlineIcon />
            </Whisper>
          </div>
        </HeaderCell>
        <Cell dataKey="notes" />
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Prior Round</HeaderCell>
        {CustomCell('priorRoundZ')}
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Prior Round 2</HeaderCell>
        {CustomCell('priorRound2Z')}
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Prior Round 3</HeaderCell>
        {CustomCell('priorRound3Z')}
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Avg Z</HeaderCell>
        {CustomCell('avgZ')}
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Std Z</HeaderCell>
        {CustomCell('stdZ')}
      </Column>
      <Column width={80} align="center">
        <HeaderCell>Omit</HeaderCell>
        <OmitCell dataKey="omit" onChange={updateRow} />
      </Column>
      <Column width={200} align="center">
        <HeaderCell>Explanation</HeaderCell>
        <EditCell
          dataKey="explanation"
          onChange={onChangeExplanation}
          onUpdate={updateExplanation}
        />
      </Column>
    </>
  );
}

export const ReportTable = ({
  tableData,
  round,
  updateRow,
  updateExplanation,
  onChangeExplanation,
  loading
}) => {
  return (
    <Table data={tableData} loading={loading} rowHeight={60} bordered cellBordered height={600}>
      {Columns(round, updateRow, updateExplanation, onChangeExplanation)}
    </Table>
  );
};

ReportTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  round: PropTypes.string.isRequired,
  updateRow: PropTypes.func.isRequired,
  updateExplanation: PropTypes.func.isRequired,
  onChangeExplanation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
