import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { v4 as uuidv4 } from 'uuid';

const ButtonLoading = ({ text, loading = false, ...props }) => {
  return (
    <Button key={uuidv4()} disabled={loading} {...props}>
      {loading ? (
        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"></Spinner>
      ) : (
        <span>{text}</span>
      )}
    </Button>
  );
};
export default ButtonLoading;
