import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'rsuite/Table';
import Toggle from 'rsuite/Toggle';
import VisibleIcon from '@rsuite/icons/Visible';
import EyeCloseIcon from '@rsuite/icons/EyeClose';
import { Input, InputGroup } from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import Filters from '../filters';
import CustomChart from '../../../CustomChart';
import Round from '../../round';

const { Column, HeaderCell, Cell } = Table;

const BiasScreen = ({ data, loading, locationData, runData, metaData, updateMetaData }) => {
  const [selectedMethod, setSelectedMethod] = useState('');
  const [selectedParameter, setSelectedParameter] = useState('');
  const [latestRound, setLatestRound] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [includeAll, setIncludeAll] = useState(false);
  const [dataResult, setDataResult] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const result = {};
    let roundResult;

    data.forEach((item) => {
      const { method: itemMethod, parameter: itemParameter, round: itemRound } = item;
      if (!result[itemMethod]) {
        result[itemMethod] = {};
      }

      if (!result[itemMethod][itemParameter]) {
        result[itemMethod][itemParameter] = {};
      }

      if (!result[itemMethod][itemParameter][itemRound]) {
        result[itemMethod][itemParameter][itemRound] = [];
      }
      result[itemMethod][itemParameter][itemRound].push(item);
      if (!roundResult) {
        roundResult = new Round(itemRound);
      } else if (roundResult.compare(new Round(itemRound)) < 0) {
        roundResult = new Round(itemRound);
      }
    });

    setLatestRound(roundResult);
    setDataResult(result);
  }, [data, locationData]);

  const getZvalue = (row, earlierArray) => {
    const metaDataKey =
      `${selectedMethod}-${selectedParameter}-${row.round}-${row.facility}-${row.equipment}`.toUpperCase();
    const metadata = metaData[metaDataKey];
    if (metadata && metadata.omit) {
      return '';
    }
    return (
      earlierArray?.find(
        (item) =>
          item.equipment === row.equipment &&
          item.facility === row.facility &&
          Number(row.result) === item.result
      )?.z ?? ''
    );
  };

  const getZKey = (index) => {
    switch (index) {
      case 1:
        return 'priorRoundZ';
      case 2:
        return 'priorRound2Z';
      case 3:
        return 'priorRound3Z';
      default:
        return 'z';
    }
  };

  useEffect(() => {
    if (selectedParameter && selectedMethod) {
      const tempTable = new Map();
      let isIncludeAll = true;
      [
        latestRound,
        latestRound.quarterEarlier(),
        latestRound.halfYearEarlier(),
        latestRound.threeQuarterEarlier()
      ].forEach((item, index) => {
        dataResult[selectedMethod][selectedParameter][item]?.forEach((value) => {
          const key = `${value.facility}-${value.equipment}`;
          const zValue = getZvalue(value, runData[value.runKey].rows);
          const zKey = getZKey(index);
          if (tempTable.has(key)) {
            const roundData = {};
            roundData[zKey] = zValue;
            const currentData = tempTable.get(key);
            tempTable.set(key, { ...currentData, ...roundData });
          } else {
            const metaDataKey =
              `${selectedMethod}-${selectedParameter}-${item}-${value.facility}-${value.equipment}`.toUpperCase();
            const includeValue = metaData[metaDataKey]?.include || false;
            if (!includeValue) {
              isIncludeAll = false;
            }
            const roundData = { priorRound3Z: '', priorRound2Z: '', priorRoundZ: '', z: '' };
            roundData[zKey] = zValue;
            tempTable.set(key, {
              include: includeValue,
              facility: value.facility,
              equipment: value.equipment,
              facilityName: locationData.get(value.facility)?.locationName || value.facility,
              ...roundData
            });
          }
        });
      });
      // eslint-disable-next-line no-unused-vars
      const arr = Array.from(tempTable, ([name, value]) => {
        const array = [value.z, value.priorRoundZ, value.priorRound2Z, value.priorRound3Z]
          .filter((i) => !!i)
          .map((i) => Number(i));
        const avgZ = array?.reduce((a, b) => a + b, 0) / array.length || '';
        return { ...value, avgZ };
      }).sort((a, b) => {
        const nameA = a.facilityName.toUpperCase();
        const nameB = b.facilityName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setIncludeAll(isIncludeAll);
      setTableData(arr);
    } else {
      setTableData([]);
    }
  }, [selectedParameter, selectedMethod, metaData]);

  const names = [
    {
      label: 'Method',
      disabled: false,
      values: Object.keys(dataResult).map((item) => ({
        label: item,
        value: item
      })),
      value: selectedMethod,
      onChange: (value) => {
        setSelectedMethod(value);
        setSelectedParameter('');
      }
    },
    {
      label: 'Parameter',
      disabled: !selectedMethod,
      values: [...Object.keys(dataResult?.[selectedMethod] ?? [])].map((item) => ({
        label: item,
        value: item
      })),
      value: selectedParameter,
      onChange: (value) => {
        setSelectedParameter(value);
      }
    }
  ];

  const columns = [
    {
      label: 'Facility',
      key: 'facilityName',
      flexGrow: 1.5,
      align: 'center'
    },
    {
      label: 'Instrument',
      key: 'equipment',
      flexGrow: 1,
      align: 'center'
    },
    {
      label: 'Mean Z',
      key: 'avgZ',
      flexGrow: 1,
      align: 'center'
    },
    {
      label: `${latestRound?.threeQuarterEarlier() || ''} Z`,
      key: 'priorRound3Z',
      flexGrow: 1,
      align: 'center'
    },
    {
      label: `${latestRound?.halfYearEarlier() || ''} Z`,
      key: 'priorRound2Z',
      flexGrow: 1,
      align: 'center'
    },
    {
      label: `${latestRound?.quarterEarlier() || ''} Z`,
      key: 'priorRoundZ',
      flexGrow: 1,
      align: 'center'
    },
    {
      label: `${latestRound} Z`,
      key: 'z',
      flexGrow: 1,
      align: 'center'
    },
    {
      label: 'Include',
      key: 'include',
      flexGrow: 1,
      align: 'center'
    }
  ];

  const options = {
    title: 'Company Performance',
    pointSize: 5,
    // curveType: "function",
    legend: { position: 'top' },

    chartArea: {
      left: '5%',
      width: '90%'
    }
  };

  const chartData = () => {
    const fData = tableData
      .filter((item) => item.include)
      .map((item) => ({
        key: `${item.facilityName} ${item.equipment}`,
        q4: typeof item.priorRound3Z === 'number' ? item.priorRound3Z : 0,
        q3: typeof item.priorRound2Z === 'number' ? item.priorRound2Z : 0,
        q2: typeof item.priorRoundZ === 'number' ? item.priorRoundZ : 0,
        q1: typeof item.z === 'number' ? item.z : 0
      }));

    const ticks = Array.from(
      new Set(fData.flatMap((item) => Object.keys(item).filter((key) => key !== 'key')))
    );

    const labelMappings = {
      q4: latestRound?.threeQuarterEarlier(),
      q3: latestRound?.halfYearEarlier(),
      q2: latestRound?.quarterEarlier(),
      q1: latestRound?.toString()
    };

    // Build formattedData dynamically
    const formattedData = [
      ['Tick', ...fData.map((item) => item.key)] // Include line names as additional series
    ];

    ticks.forEach((tick) => {
      const tickValues = fData.map((item) => item[tick]);
      formattedData.push([labelMappings[tick], ...tickValues]);
    });

    return formattedData;
  };

  const changeIncludeAll = async () => {
    setTableLoading(true);

    const metaDatas = [];
    [
      latestRound,
      latestRound.quarterEarlier(),
      latestRound.halfYearEarlier(),
      latestRound.threeQuarterEarlier()
    ].forEach((item) => {
      tableData.forEach((value) => {
        const metaDataKey =
          `${selectedMethod}-${selectedParameter}-${item}-${value.facility}-${value.equipment}`.toUpperCase();
        const metadata = metaData[metaDataKey];
        if (!metadata || metadata.include === includeAll) {
          const newMetaData = { ...metaData[metaDataKey], include: !includeAll, key: metaDataKey };
          metaDatas.push(newMetaData);
        }
      });
    });
    setIncludeAll(!includeAll);
    await updateMetaData(metaDatas);
    setTableLoading(false);
  };

  const changeSilence = async (rd, include) => {
    setTableLoading(true);

    const metaDatas = [];
    [
      latestRound,
      latestRound.quarterEarlier(),
      latestRound.halfYearEarlier(),
      latestRound.threeQuarterEarlier()
    ].forEach((item) => {
      const metaDataKey =
        `${selectedMethod}-${selectedParameter}-${item}-${rd.facility}-${rd.equipment}`.toUpperCase();
      const newMetaData = { ...metaData[metaDataKey], include, key: metaDataKey };
      metaDatas.push(newMetaData);
    });
    await updateMetaData(metaDatas);
    setTableLoading(false);
  };

  function CustomCell(dataKey) {
    switch (dataKey) {
      case 'include':
        return (
          <Cell>
            {(rd) => {
              return (
                <Toggle
                  style={{ width: '100%' }}
                  size="lg"
                  unCheckedChildren={<EyeCloseIcon />}
                  checkedChildren={<VisibleIcon />}
                  checked={rd.include}
                  onChange={(include) => {
                    changeSilence(rd, include);
                  }}
                />
              );
            }}
          </Cell>
        );
      case 'z':
      case 'avgZ':
      case 'priorRoundZ':
      case 'priorRound2Z':
      case 'priorRound3Z':
        return (
          <Cell>
            {(rowData) => {
              if (typeof rowData[dataKey] !== 'number') {
                return '';
              }
              return Math.round(rowData[dataKey] * 1000) / 1000;
            }}
          </Cell>
        );
      default:
        return <Cell dataKey={dataKey} />;
    }
  }

  const getDataTable = () => {
    return tableData.filter(
      (item) => !search || item.facilityName.toLowerCase().includes(search.toLocaleLowerCase())
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '9fr 1fr',
          gap: '1rem'
        }}
      >
        <Filters names={names} loading={loading} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '.5rem'
          }}
        >
          <p style={{ margin: 0 }}>Include All</p>
          <Toggle
            disabled={tableData.length < 1}
            size="lg"
            unCheckedChildren={<EyeCloseIcon />}
            checkedChildren={<VisibleIcon />}
            checked={includeAll}
            onChange={(include) => {
              changeIncludeAll(include);
            }}
          />
        </div>
      </div>
      <InputGroup inside style={{ width: '400px', marginTop: '10px', marginBottom: '10px' }}>
        <Input
          placeholder="Facility"
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
        />
        <InputGroup.Button>
          <SearchIcon onClick={() => {}} />
        </InputGroup.Button>
      </InputGroup>
      <Table
        data={getDataTable()}
        bordered
        cellBordered
        rowHeight={50}
        height={600}
        loading={tableLoading}
      >
        {columns.map((item) => {
          return (
            <Column flexGrow={item.flexGrow} align={item.align} key={item.key}>
              <HeaderCell>{item.label}</HeaderCell>
              {CustomCell(item.key)}
            </Column>
          );
        })}
      </Table>
      {tableData.length > 0 && (
        <span style={{ padding: '16px', fontSize: '12px' }}>Total Rows: {tableData.length}</span>
      )}
      <CustomChart
        type="LineChart"
        data={chartData()}
        loading={loading}
        options={options}
        height="800px"
      />
    </div>
  );
};

BiasScreen.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  locationData: PropTypes.instanceOf(Map),
  runData: PropTypes.object,
  metaData: PropTypes.object,
  updateMetaData: PropTypes.func
};

BiasScreen.defaultProps = {
  data: [],
  loading: false,
  locationData: new Map(),
  runData: {},
  metaData: {},
  updateMetaData: () => {}
};

export default BiasScreen;
