import React from 'react';
import PropTypes from 'prop-types';
import CustomChart from '../../../CustomChart';

const ReportGraphics = ({ firstChartData, histogram }) => {
  const optionsChart1 = {
    title: 'Q-Q Plot: Anderson-Darling 0.204 (RMS)',
    legend: { position: 'top' },
    pointSize: 5,
    trendlines: { 0: { labelInLegend: 'Best Fit Line' } }
  };

  const reportHistogramData = [
    ['bin', 'Bin Count', 'Normal PDF'],
    ...histogram.map((h) => [Number(h.bin), Number(h.binCount), Number(h.hPDF)])
  ];

  const options = {
    title: 'Frequency Histogram',
    seriesType: 'bars',
    series: {
      0: {
        type: 'bars',
        color: '#ffc800'
      },
      1: {
        type: 'line',
        targetAxisIndex: 1,
        color: 'black'
      },
      2: {
        type: 'line',
        targetAxisIndex: 1,
        color: 'blue'
      }
    },
    vAxes: {
      1: {
        title: 'Frequency',
        minValue: 0
      },
      0: {
        title: 'Normal PDF',
        minValue: 0
      }
    }
  };

  return (
    <>
      <CustomChart data={firstChartData()} options={optionsChart1} type="ScatterChart" />

      <CustomChart data={reportHistogramData} options={options} type="ComboChart" />
    </>
  );
};

ReportGraphics.propTypes = {
  firstChartData: PropTypes.func.isRequired,
  histogram: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ReportGraphics;
