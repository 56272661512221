import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useSheetCallback from '../Screen/Tables/useSheetCallback';
import usePlotCharts from './usePlotCharts';
import Round from '../round';

export const TableAndPlotContext = createContext();

export function TableAndPlots({ data, runData, loading, children, locationData }) {
  const [ilcpData, setIlcpData] = useState({});
  const [facility, setFacility] = useState('');
  const [method, setMethod] = useState('');
  const [parameter, setParameter] = useState('');
  const [instrument, setInstrument] = useState('');
  const {
    t1Head,
    t1Body,
    t2Head,
    t2Body,
    updateData,
    loading: loadingLowertables,
    chartData,
    ADRMS,
    ADMR,
    setT1Body,
    setT2Body
  } = useSheetCallback();

  const {
    qqChartAllData,
    iChartAllDataILCP,
    mrChartAllData,
    setData,
    setMrChartAllData,
    setIChartAllDataILCP,
    setQqChartAllData
  } = usePlotCharts();

  useEffect(() => {
    const result = {};
    data.forEach((item) => {
      const {
        facility: itemFacility,
        method: itemMethod,
        parameter: itemParameter,
        equipment: itemEquipment
      } = item;

      if (!result[itemFacility]) {
        result[itemFacility] = {};
      }

      if (!result[itemFacility][itemMethod]) {
        result[itemFacility][itemMethod] = {};
      }

      if (!result[itemFacility][itemMethod][itemParameter]) {
        result[itemFacility][itemMethod][itemParameter] = {};
      }

      if (!result[itemFacility][itemMethod][itemParameter][itemEquipment]) {
        result[itemFacility][itemMethod][itemParameter][itemEquipment] = [];
      }

      result[itemFacility][itemMethod][itemParameter][itemEquipment].push(item);
    });
    setIlcpData(result);
  }, [data]);

  useEffect(() => {
    setData(chartData);
  }, [chartData]);

  useEffect(() => {
    if (!instrument) {
      setT1Body([]);
      setT2Body([]);
      setMrChartAllData([]);
      setIChartAllDataILCP([]);
      setQqChartAllData([]);
    }
  }, [instrument]);

  useEffect(() => {
    if (Object.keys(runData).length > 0) {
      const value = ilcpData?.[facility]?.[method]?.[parameter]?.[instrument];
      if (value?.length > 0) {
        const result = value
          .map((item) => {
            const runResult = runData[item.runKey];
            const zResult = runResult?.rows?.find(
              (row) => row.equipment === instrument && facility === row.facility
            )?.z;
            return {
              ...item,
              runData: runResult,
              z: zResult
            };
          })
          .sort((a, b) => {
            const roundA = new Round(a.round);
            const roundB = new Round(b.round);
            return roundA.compare(roundB);
          });
        ilcpData[facility][method][parameter][instrument] = [...result];
        setIlcpData({ ...ilcpData });
        const updateValue = ilcpData[facility][method][parameter][instrument];

        updateData(updateValue);
      }
    }
  }, [instrument, runData]);

  const getName = (facilityCode) => {
    const result = locationData.get(facilityCode);
    if (result) {
      return result.locationName;
    }
    return facilityCode;
  };

  const getValues = (obj) => {
    if (!obj) return [];
    return Object.keys(obj).map((item) => ({
      label: item,
      value: item
    }));
  };

  const getFacilityValues = (obj) => {
    if (!obj) return [];
    return Object.keys(obj)
      .map((item) => ({
        label: getName(item),
        value: item
      }))
      .sort((a, b) => {
        const nameA = a.label;
        const nameB = b.label;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  };

  const names = [
    {
      label: 'Facility',
      disabled: loading,
      values: getFacilityValues(ilcpData),
      value: facility,
      onChange: (value) => {
        setFacility(value);
        setMethod('');
        setParameter('');
        setInstrument('');
      }
    },
    {
      label: 'Method',
      disabled: loading || !facility,
      values: getValues(ilcpData?.[facility]),
      value: method,
      onChange: (value) => {
        setMethod(value);
        setParameter('');
        setInstrument('');
      }
    },
    {
      label: 'Parameter',
      disabled: loading || !method,
      values: getValues(ilcpData?.[facility]?.[method]),
      value: parameter,
      onChange: (value) => {
        setParameter(value);
        setInstrument('');
      }
    },
    {
      label: 'Instrument',
      disabled: loading || !parameter,
      values: getValues(ilcpData?.[facility]?.[method]?.[parameter]),
      value: instrument,
      onChange: (value) => setInstrument(value)
    }
  ];
  const values = {
    ilcpData,
    facility,
    method,
    parameter,
    instrument,
    t1Head,
    t1Body,
    t2Head,
    t2Body,
    loadingLowertables,
    names,
    loading,
    qqChartAllData,
    iChartAllDataILCP,
    mrChartAllData,
    locationData,
    ADRMS,
    ADMR
  };

  return <TableAndPlotContext.Provider value={values}>{children}</TableAndPlotContext.Provider>;
}

TableAndPlots.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  children: PropTypes.node,
  locationData: PropTypes.instanceOf(Map),
  runData: PropTypes.object
};

TableAndPlots.defaultProps = {
  data: [],
  loading: false,
  children: null,
  locationData: new Map(),
  runData: {}
};
