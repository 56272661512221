import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Table from 'rsuite/Table';
import Pagination from 'rsuite/Pagination';
import './TableExternalEdit.scss';
import Button from 'rsuite/Button';
import { updatePrograms } from '../../../../util/db';

const { Column, HeaderCell, Cell } = Table;

const EditableCell = ({ rowData, dataKey, onChange, status, ...props }) => {
  return (
    <Cell {...props} className={status ? 'table-content-editing' : ''}>
      {status ? (
        <input
          className="rs-input"
          defaultValue={rowData[dataKey]}
          onChange={(event) => {
            if (onChange) {
              onChange(rowData.id, dataKey, event.target.value);
            }
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};

EditableCell.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string,
  onChange: PropTypes.func,
  status: PropTypes.bool
};

EditableCell.defaultProps = {
  rowData: {},
  dataKey: '',
  onChange: () => {},
  status: false
};

const TableExternalEdit = ({ tableData, loading }) => {
  const [editState, setEditState] = useState(false);
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [defaultData, setDefaultData] = useState([]);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  useEffect(() => {
    setDefaultData(tableData);
  }, [tableData]);

  const handleChange = (id, key, value) => {
    const nextData = Object.assign([], defaultData);
    const find = nextData.find((item) => item.id === id);
    find[key] = value;
    find.changed = true;
    setDefaultData(nextData);
  };
  const handleEditState = async () => {
    if (editState) {
      setLoadingUpdate(true);
      const valuesToUpdate = defaultData
        .filter((v) => v.changed)
        .map((v) => {
          const value = { ...v };
          delete value.changed;
          return value;
        });

      await updatePrograms(valuesToUpdate);

      setDefaultData(
        defaultData.map((v) => ({
          ...v,
          changed: false
        }))
      );

      setEditState(!editState);
      setLoadingUpdate(false);
    } else {
      setEditState(!editState);
    }
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const data = defaultData.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  return (
    <div className="contentTable">
      <Table
        data={data}
        rowHeight={40}
        bordered
        cellBordered
        loading={loading || loadingUpdate}
        headerHeight={35}
        height={360}
      >
        <Column key={1} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Lab Code</HeaderCell>
          <EditableCell dataKey="labCode" status={editState} onChange={handleChange} />
        </Column>
        <Column key={2} flexGrow={1} align="center" verticalAlign="middle" fullText>
          <HeaderCell>Lab</HeaderCell>
          <EditableCell dataKey="labName" status={editState} onChange={handleChange} />
        </Column>
        <Column key={3} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Round</HeaderCell>
          <EditableCell dataKey="round" status={editState} onChange={handleChange} />
        </Column>
        <Column key={4} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Value</HeaderCell>
          <EditableCell dataKey="result" status={editState} onChange={handleChange} />
        </Column>
        <Column key={5} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Mean</HeaderCell>
          <EditableCell dataKey="mean" status={editState} onChange={handleChange} />
        </Column>
        <Column key={6} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Count</HeaderCell>
          <EditableCell dataKey="count" status={editState} onChange={handleChange} />
        </Column>
        <Column key={7} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Std Dev</HeaderCell>
          <EditableCell dataKey="stdev" status={editState} onChange={handleChange} />
        </Column>
        <Column key={8} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Site Std Dev</HeaderCell>
          <EditableCell dataKey="sitestdev" status={editState} onChange={handleChange} />
        </Column>
        <Column key={9} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>R_ASTM</HeaderCell>
          <EditableCell dataKey="rastm" status={editState} onChange={handleChange} />
        </Column>
        <Column key={10} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>R_Data</HeaderCell>
          <EditableCell dataKey="r" status={editState} onChange={handleChange} />
        </Column>
        <Column key={11} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Z-Score</HeaderCell>
          <EditableCell dataKey="z" status={editState} onChange={handleChange} />
        </Column>
        <Column key={12} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Outlier</HeaderCell>
          <EditableCell dataKey="o" status={editState} onChange={handleChange} />
        </Column>
        <Column key={13} flexGrow={1} align="center" verticalAlign="middle">
          <HeaderCell>Site std dev</HeaderCell>
          <EditableCell dataKey="siteStdev" status={editState} onChange={handleChange} />
        </Column>
      </Table>
      <div className="paginationButton">
        <div className="contentButton">
          <Button onClick={() => handleEditState()}>{editState ? 'Save' : 'Edit'}</Button>
        </div>
        <div className="contentPage">
          <Pagination
            className="pagination"
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={defaultData.length}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  );
};

TableExternalEdit.propTypes = {
  tableData: PropTypes.array,
  loading: PropTypes.bool
};

TableExternalEdit.defaultProps = {
  tableData: [],
  loading: false
};

export default TableExternalEdit;
