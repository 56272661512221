import { Button, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { renderMUILoadingButton } from '../../../util/util';

const ModalFormNotificationGroup = ({
  title,
  show,
  currentGroup,
  personnel = [],
  handleClose,
  handleSave
}) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      name: currentGroup?.name || ''
    }
  });

  useEffect(() => {
    const defaultValues = {
      name: currentGroup?.name || ''
    };
    personnel.map((person) => {
      defaultValues[person.id] = (currentGroup?.users || [])
        .map((user) => user.id)
        .includes(person.id);
    });

    reset(defaultValues);
  }, [personnel, show]);

  const onSubmit = async (data) => {
    setLoading(true);
    await handleSave(data);
    setLoading(false);
  };
  return (
    <Modal key={title} size="md" show={show} onHide={handleClose} backdrop="static">
      <form onSubmit={handleSubmit(onSubmit)} onReset={handleClose}>
        <Modal.Header closeButton>{title}</Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Group name</Form.Label>
            <Form.Control name="name" {...register('name', { required: true })} />
          </Form.Group>
          <Form.Label style={{ marginTop: 10 }}>Person</Form.Label>
          {personnel.length
            ? personnel.map((item) => (
                <Form.Group key={`${item.id}-${Math.random() * 1000}`}>
                  <Form.Check label={`${item.name}`} {...register(item.id)} />
                </Form.Group>
              ))
            : ''}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="text" type="reset">
            Cancel
          </Button>
          &nbsp; &nbsp;
          {renderMUILoadingButton({
            buttonText: 'Save',
            isLoading: loading,
            variant: 'contained',
            type: 'submit'
          })}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ModalFormNotificationGroup;
