import React from 'react';
import PropTypes from 'prop-types';
import Table from 'rsuite/Table';
import PaginatedTable from '../../../PaginatedTable';

const { Column, HeaderCell, Cell } = Table;

const RoundTable = ({ tableData }) => {
  const headers = [
    {
      label: 'Round',
      key: 'round'
    },
    {
      label: 'Value',
      key: 'result'
    },
    {
      label: 'Mean',
      key: 'runData.summary.average'
    },
    {
      label: 'Count',
      key: 'runData.summary.resultsUsed'
    },
    {
      label: 'Std Dev',
      key: 'runData.summary.stDev'
    },
    {
      label: 'R_ASTM',
      key: 'runData.summary.astmR'
    },
    {
      label: 'R_Data',
      key: 'runData.summary.theseDataR'
    },
    {
      label: 'Z-Score',
      key: 'z'
    }
  ];

  function CustomCell(dataKey) {
    switch (dataKey) {
      case 'runData.summary.average':
      case 'runData.summary.resultsUsed':
      case 'runData.summary.stDev':
      case 'runData.summary.astmR':
      case 'runData.summary.theseDataR':
        return (
          <Cell>
            {(rowData) => {
              const value = rowData?.runData?.summary[dataKey?.replace('runData.summary.', '')];
              if (value) {
                return Math.round(value * 1000) / 1000;
              }
              return '';
            }}
          </Cell>
        );
      case 'z':
        return (
          <Cell>
            {(rowData) => {
              if (rowData[dataKey]) {
                return Math.round(rowData[dataKey] * 1000) / 1000;
              }
              return '';
            }}
          </Cell>
        );
      default:
        return <Cell dataKey={dataKey} />;
    }
  }

  return (
    <PaginatedTable bodyData={tableData} loading={false}>
      {headers.map((header) => (
        <Column key={`header-${header.label}`} flexGrow={1} align="center">
          <HeaderCell>{header.label}</HeaderCell>
          {CustomCell(header.key)}
        </Column>
      ))}
    </PaginatedTable>
  );
};

RoundTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default RoundTable;
