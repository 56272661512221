import SelectPicker from 'rsuite/SelectPicker';
import React from 'react';
import PropTypes from 'prop-types';

const Filters = ({ names, loading }) => {
  const selectStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  };

  return (
    <div
      style={{
        display: 'grid',
        gap: '1rem',
        gridTemplateColumns: `repeat(${names.length},1fr)`
      }}
    >
      {names.map((item) => {
        return (
          <div style={selectStyle} key={`select-${item.label}`}>
            <label>{item.label}</label>
            <SelectPicker
              className="select"
              data={item.values}
              loading={loading}
              onChange={(value) => item.onChange(value)}
              disabled={item.disabled}
              value={item.value}
            />
          </div>
        );
      })}
    </div>
  );
};

Filters.propTypes = {
  names: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool
};

Filters.defaultProps = {
  loading: false
};

export default Filters;
