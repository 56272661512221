import { Button, Rating } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { VendorStatusOptions } from '../TabSettings/Columns';
import { renderMUILoadingButton } from '../../../util/util';

const ModalFormVendor = ({ show, handleClose, handleSave, data, instrumentSettingsVendorType }) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, control, reset, watch, setValue } = useForm({
    defaultValues: {
      id: data?.id || '',
      vendorId: data?.vendorId || '',
      vendorType: data?.vendorType || '',
      companyName: data?.companyName || '',
      department: data?.department || '',
      status: data?.status || '',
      contactName: data?.contactName || '',
      contactEmail: data?.contactEmail || '',
      contactPhone: data?.contactPhone || '',
      notes: data?.notes || '',
      rating: data?.rating || ''
    }
  });

  useEffect(() => {
    reset({
      id: data?.id || '',
      vendorId: data?.vendorId || '',
      vendorType: data?.vendorType || '',
      companyName: data?.companyName || '',
      department: data?.department || '',
      status: data?.status || '',
      contactName: data?.contactName || '',
      contactEmail: data?.contactEmail || '',
      contactPhone: data?.contactPhone || '',
      notes: data?.notes || '',
      rating: data?.rating || ''
    });
  }, [show]);

  const onSubmit = async (data) => {
    setLoading(true);
    await handleSave(data);
    setLoading(false);
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)} onReset={handleClose}>
          <Modal.Header closeButton>{data?.id ? 'Edit Vendor' : 'Add Vendor'}</Modal.Header>

          <Modal.Body>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Vendor ID</Form.Label>
                <Form.Control name="vendorId" {...register('vendorId')} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Company Name</Form.Label>
                <Form.Control name="companyName" {...register('companyName', { required: true })} />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Department</Form.Label>
                <Form.Control name="department" {...register('department', { required: false })} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Vendor Type</Form.Label>
                <Form.Select name="vendorType" {...register('vendorType', { required: true })}>
                  <option value="">-- Select --</option>
                  {instrumentSettingsVendorType.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Primary Contact Name</Form.Label>
                <Form.Control
                  name="contactName"
                  {...register('contactName', { required: false })}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Vendor Status</Form.Label>
                <Form.Select name="status" {...register('status', { required: true })}>
                  <option value="">-- Select --</option>
                  {VendorStatusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Email</Form.Label>
                <Form.Control name="contactEmail" {...register('contactEmail')} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Phone</Form.Label>
                <Form.Control name="contactPhone" {...register('contactPhone')} />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Vendor Rating</Form.Label>
                <Form.Control name="rating" {...register('rating', { required: false })} />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="notes"
                  {...register('notes', { required: false })}
                />
              </Form.Group>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="text" type="reset">
              Cancel
            </Button>
            &nbsp; &nbsp;
            {renderMUILoadingButton({
              buttonText: 'Save',
              isLoading: loading,
              variant: 'contained',
              type: 'submit'
            })}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalFormVendor;
