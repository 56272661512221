import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useAuth } from '../../util/auth';
import './OrganizationSection.scss';

import {
  createArea,
  createCompany,
  setCompanyType,
  createDepartment,
  createDivision,
  createLaboratory,
  createOrganization,
  createRegion,
  createUnit,
  deleteArea,
  deleteCompany,
  deleteDepartment,
  deleteDivision,
  deleteLaboratory,
  deleteOrganization,
  deleteRegion,
  deleteUnit,
  updateCompany,
  useGetAreas,
  useGetCompanies,
  useGetCompanyType,
  useGetDepartments,
  useGetDivisions,
  useGetLaboratories,
  useGetOrganizations,
  useGetPersonnel,
  useGetRegions,
  useGetUnits
} from '../../util/db';

import PrimaryButton from '../PrimaryButton/PrimaryButton';
import CustomTable from '../Table/CustomTable';
import {
  renderButtonWithPermissions,
  checkDelete,
  checkUpdate,
  renderAuthButtonWithPermission,
  isAdmin
} from '../../util/util';
import { RULES, SCREEN } from '../../util/Constant';
import { FormControlLabel, Switch } from '@mui/material';
import { FormLabel } from 'react-bootstrap';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../../util/firebase';
import Loading from '../Common/Loading';

function OrganizationSection() {
  const auth = useAuth();
  const [currentOrganizations, setCurrentOrganizations] = useState([]);
  const [currentOrganizationsDropdown, setCurrentOrganizationsDropdown] = useState([]);
  const { data: organizations } = useGetOrganizations();
  const [currentCompanies, setCurrentCompanies] = useState([]);
  const { data: companies } = useGetCompanies();
  const [currentDivisions, setCurrentDivisions] = useState([]);
  const [currentDivisionsDropdown, setCurrentDivisionsDropdown] = useState([]);
  const { data: divisions } = useGetDivisions();
  const [currentUnits, setCurrentUnits] = useState([]);
  const [currentUnitsDropdown, setCurrentUnitsDropdown] = useState([]);
  const { data: units } = useGetUnits();
  const [currentRegions, setCurrentRegions] = useState([]);
  const [currentRegionsDropdown, setCurrentRegionsDropdown] = useState([]);
  const { data: regions } = useGetRegions();
  const { data: companyTypes } = useGetCompanyType();
  const [currentAreas, setCurrentAreas] = useState([]);
  const [currentAreasDropdown, setCurrentAreasDropdown] = useState([]);
  const { data: areas } = useGetAreas();
  const [currentLaboratories, setCurrentLaboratories] = useState([]);
  const [currentLaboratoriesDropdown, setCurrentLaboratoriesDropdown] = useState([]);
  const { data: laboratories } = useGetLaboratories();
  const [currentDepartments, setCurrentDepartments] = useState([]);
  const [currentDepartmentsDropdown, setCurrentDepartmentsDropdown] = useState([]);
  const { data: departments } = useGetDepartments();
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const [showDivisionModal, setShowDivisionModal] = useState(false);
  const [showUnitModal, setShowUnitModal] = useState(false);
  const [showRegionModal, setShowRegionModal] = useState(false);
  const [showAreaModal, setShowAreaModal] = useState(false);
  const [showLaboratoryModal, setShowLaboratoryModal] = useState(false);
  const [showDepartmentModal, setShowDepartmentModal] = useState(false);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [newOrganization, setNewOrganization] = useState('');
  const [newDivision, setNewDivision] = useState('');
  const [newUnit, setNewUnit] = useState('');
  const [newRegion, setNewRegion] = useState('');
  const [newArea, setNewArea] = useState('');
  const [newLaboratory, setNewLaboratory] = useState('');
  const [newDepartment, setNewDepartment] = useState('');
  const [companiesToUpdate, setCompaniesToUpdate] = useState([]);
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const { data: personnel } = useGetPersonnel();
  const [actions, setActions] = useState(['none']);
  const [hasEdit, setHasEdit] = useState(false);
  const [permission, setPermission] = useState(null);
  const [loading, setLoading] = useState(false);

  const functions = getFunctions(firebaseApp);
  const runReBuildIndexFunctions = httpsCallable(functions, 'reBuildIndex');

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      setCurrentPersonnel(personnel);
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        setPermission(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  useEffect(() => {
    if (permission) {
      if (checkDelete(permission, SCREEN.ORGANIZTION)) {
        setActions(['delete']);
      }

      setHasEdit(checkUpdate(permission, SCREEN.ORGANIZTION));
    }
  }, [permission]);

  const handleResults = (results) => {
    results.forEach((company, index) => {
      if (index > 0) {
        const companyToAdd = {
          name: company[0],
          division: company[1],
          businessUnit: company[2],
          region: company[3],
          area: company[4],
          laboratory: company[5],
          department: company[6]
        };
        createCompany(companyToAdd);
      }
    });
  };

  const handleNewOrganizationChange = (e) => {
    setNewOrganization(e.target.value);
  };

  const handleNewDivisionChange = (e) => {
    setNewDivision(e.target.value);
  };

  const handleNewUnitChange = (e) => {
    setNewUnit(e.target.value);
  };

  const handleNewRegionChange = (e) => {
    setNewRegion(e.target.value);
  };

  const handleNewAreaChange = (e) => {
    setNewArea(e.target.value);
  };

  const handleNewLaboratoryChange = (e) => {
    setNewLaboratory(e.target.value);
  };

  const handleNewDepartmentChange = (e) => {
    setNewDepartment(e.target.value);
  };

  const saveNewCompany = () => {
    const newOrganizationToSave = { name: newOrganization };
    createOrganization(newOrganizationToSave);
    setShowOrganizationModal(false);
  };

  const saveNewDivision = () => {
    const newDivisionToSave = { name: newDivision };
    createDivision(newDivisionToSave);
    setShowDivisionModal(false);
  };

  const saveNewUnit = () => {
    const newUnitToSave = { name: newUnit };
    createUnit(newUnitToSave);
    setShowUnitModal(false);
  };

  const saveNewRegion = () => {
    const newRegionToSave = { name: newRegion };
    createRegion(newRegionToSave);
    setShowRegionModal(false);
  };

  const saveNewArea = () => {
    const newAreaToSave = { name: newArea };
    createArea(newAreaToSave);
    setShowAreaModal(false);
  };

  const saveNewLaboratory = () => {
    const newLaboratoryToSave = { name: newLaboratory };
    createLaboratory(newLaboratoryToSave);
    setShowLaboratoryModal(false);
  };

  const saveNewDepartment = () => {
    const newDepartmentToSave = { name: newDepartment };
    createDepartment(newDepartmentToSave);
    setShowDepartmentModal(false);
  };

  const saveCompanies = () => {
    const currentCompaniesToUpdate = companiesToUpdate;
    currentCompaniesToUpdate.forEach((company) => {
      updateCompany(company.id, company);
    });
    setCompaniesToUpdate([]);
  };

  const saveNewOrganization = () => {
    createCompany(newOrganization);
    setShowOrganizationModal(false);
    setShowCompanyModal(false);
  };

  const renderOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.name}>
          {option.name}
        </option>
      );
    });
    return rows;
  };

  useEffect(() => {
    if (Array.isArray(companies) && companies.length > 0) {
      setCurrentCompanies(companies);
    }
  }, [companies]);

  useEffect(() => {
    if (Array.isArray(divisions) && divisions.length > 0) {
      const divisionsDropdown = divisions.map((division) => {
        const divisionToReturn = {
          id: division.id,
          value: division.name,
          label: division.name
        };

        return divisionToReturn;
      });
      setCurrentDivisionsDropdown(divisionsDropdown);
      setCurrentDivisions(divisions);
    }
  }, [divisions]);

  useEffect(() => {
    if (Array.isArray(units) && units.length > 0) {
      const unitsDropdown = units.map((unit) => {
        const unitToReturn = {
          id: unit.id,
          value: unit.name,
          label: unit.name
        };

        return unitToReturn;
      });
      setCurrentUnitsDropdown(unitsDropdown);
      setCurrentUnits(units);
    }
  }, [units]);

  useEffect(() => {
    if (Array.isArray(regions) && regions.length > 0) {
      const regionsDropdown = regions.map((region) => {
        const regionToReturn = {
          id: region.id,
          value: region.name,
          label: region.name
        };

        return regionToReturn;
      });
      setCurrentRegionsDropdown(regionsDropdown);
      setCurrentRegions(regions);
    }
  }, [regions]);

  useEffect(() => {
    if (Array.isArray(laboratories) && laboratories.length > 0) {
      const laboratoriesDropdown = laboratories.map((laboratory) => {
        const laboratoryToReturn = {
          id: laboratory.id,
          value: laboratory.name,
          label: laboratory.name
        };

        return laboratoryToReturn;
      });
      setCurrentLaboratoriesDropdown(laboratoriesDropdown);
      setCurrentLaboratories(laboratories);
    }
  }, [laboratories]);

  useEffect(() => {
    if (Array.isArray(areas) && areas.length > 0) {
      const areasDropdown = areas.map((area) => {
        const areaToReturn = {
          id: area.id,
          value: area.name,
          label: area.name
        };

        return areaToReturn;
      });
      setCurrentAreasDropdown(areasDropdown);
      setCurrentAreas(areas);
    }
  }, [areas]);

  useEffect(() => {
    if (Array.isArray(departments) && departments.length > 0) {
      const departmentsDropdown = departments.map((department) => {
        const departmentToReturn = {
          id: department.id,
          value: department.name,
          label: department.name
        };

        return departmentToReturn;
      });
      setCurrentDepartmentsDropdown(departmentsDropdown);
      setCurrentDepartments(departments);
    }
  }, [departments]);

  useEffect(() => {
    if (Array.isArray(organizations) && organizations.length > 0) {
      const organizationsDropdown = organizations.map((organization) => {
        const organizationToReturn = {
          id: organization.id,
          value: organization.name,
          label: organization.name
        };

        return organizationToReturn;
      });
      setCurrentOrganizationsDropdown(organizationsDropdown);
      setCurrentOrganizations(organizations);
    }
  }, [organizations]);

  const organizationTableColumns = [
    {
      field: 'name',
      headerName: 'Parent Company',
      editable: hasEdit,
      type: 'singleSelect',
      flex: 4,
      valueOptions: currentOrganizationsDropdown
    },
    {
      field: 'division',
      headerName: 'Division',
      editable: hasEdit,
      type: 'singleSelect',
      flex: 4,
      valueOptions: currentDivisionsDropdown
    },
    {
      field: 'businessUnit',
      headerName: 'Business Unit',
      editable: hasEdit,
      type: 'singleSelect',
      flex: 4,
      valueOptions: currentUnitsDropdown
    },
    {
      field: 'region',
      headerName: 'Region',
      editable: hasEdit,
      type: 'singleSelect',
      flex: 1.5,
      valueOptions: currentRegionsDropdown
    },
    {
      field: 'area',
      headerName: 'Area',
      editable: hasEdit,
      type: 'singleSelect',
      flex: 1.5,
      valueOptions: currentAreasDropdown
    },
    {
      field: 'laboratory',
      headerName: 'Laboratory',
      editable: hasEdit,
      type: 'singleSelect',
      flex: 2,
      valueOptions: currentLaboratoriesDropdown
    },
    {
      field: 'department',
      headerName: 'Department',
      editable: hasEdit,
      type: 'singleSelect',
      flex: 3,
      valueOptions: currentDepartmentsDropdown
    }
  ];

  const parentCompanyColumns = [
    {
      field: 'name',
      headerName: 'Parent Company',
      sortable: false,
      flex: 1
    }
  ];

  const divisionDataColumns = [
    {
      field: 'name',
      headerName: 'Division',
      sortable: false,
      flex: 1
    }
  ];

  const businessUnitDataColumns = [
    {
      field: 'name',
      headerName: 'Business Unit',
      sortable: false,
      flex: 1
    }
  ];

  const regionColumns = [
    {
      field: 'name',
      headerName: 'Region',
      sortable: false,
      flex: 1
    }
  ];

  const areaColumns = [
    {
      field: 'name',
      headerName: 'Area',
      sortable: false,
      flex: 1
    }
  ];

  const handleOrgCompanyChange = (e) => {
    setNewOrganization({
      ...newOrganization,
      name: e.target.value
    });
  };

  const handleOrgDivisionChange = (e) => {
    setNewOrganization({ ...newOrganization, division: e.target.value });
  };

  const handleOrgUnitChange = (e) => {
    setNewOrganization({
      ...newOrganization,
      businessUnit: e.target.value
    });
  };

  const handleOrgRegionChange = (e) => {
    setNewOrganization({ ...newOrganization, region: e.target.value });
  };

  const handleOrgAreaChange = (e) => {
    setNewOrganization({ ...newOrganization, area: e.target.value });
  };

  const handleOrgLaboratoryChange = (e) => {
    setNewOrganization({ ...newOrganization, laboratory: e.target.value });
  };

  const handleOrgDepartmentChange = (e) => {
    setNewOrganization({ ...newOrganization, department: e.target.value });
  };

  const laboratoryColumns = [
    {
      field: 'name',
      headerName: 'Laboratory',
      sortable: false,
      flex: 1
    }
  ];

  const departmentColumns = [
    {
      field: 'name',
      headerName: 'Department',
      sortable: false,
      flex: 1,
      minWidth: 150
    }
  ];

  const options = {
    // pageStartIndex: 0,
    sizePerPage: 3,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true
  };

  const handleOrganizationModalOpen = () => {
    const newModalOrganization = {
      name: '',
      division: '',
      businessUnit: '',
      region: '',
      area: '',
      laboratory: '',
      department: ''
    };
    setNewOrganization(newModalOrganization);
    setShowCompanyModal(true);
  };

  const handleToggle = (event) => {
    if (companyTypes && companyTypes[0]) {
      setCompanyType({
        ...companyTypes[0],
        [event.target.name]: event.target.checked
      });
    } else {
      setCompanyType({
        [event.target.name]: event.target.checked
      });
    }
  };

  const changeOrderType = async (event) => {
    try {
      setLoading(true);
      handleToggle(event);
      await runReBuildIndexFunctions();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const isNonUs = (companyTypes && companyTypes[0] && companyTypes[0]['nonUs']) || false;
  const isEverFlags = (companyTypes && companyTypes[0] && companyTypes[0]['everFlags']) || false;
  const isOrderTypeInstrumentBefore =
    (companyTypes && companyTypes[0] && companyTypes[0]['orderTypeInstrumentBefore']) || false;

  return (
    <div>
      <div className="header">
        <h2>Organization </h2>
        {isAdmin(permission) && (
          <div>
            <FormControlLabel
              control={<Switch name="nonUs" checked={isNonUs} onClick={handleToggle} />}
              label="NonUS"
              labelPlacement="start"
            />
            <FormControlLabel
              control={<Switch name="everFlags" checked={isEverFlags} onClick={handleToggle} />}
              label="EverFlags"
              labelPlacement="start"
            />
            <FormControlLabel
              control={
                <Switch
                  name="orderTypeInstrumentBefore"
                  checked={isOrderTypeInstrumentBefore}
                  onClick={changeOrderType}
                />
              }
              label="OrderType"
              labelPlacement="start"
            />
          </div>
        )}
      </div>
      <div className="underline-header">
        <hr />
      </div>
      <div className="data-entry-container">
        <div className="org-table-container-start">
          <div>
            <CustomTable
              data={currentOrganizations}
              header={parentCompanyColumns}
              action={actions}
              deleteRowData={(data) => {
                deleteCompany(data.id);
              }}
              numberOfRows={5}
            />
            <div className="add-row-container">
              {/* <PrimaryButton
                clicked={() => setShowOrganizationModal(true)}
                text="Add Company"
              ></PrimaryButton> */}
              {renderButtonWithPermissions(
                'Add Company',
                () => setShowOrganizationModal(true),
                SCREEN.ORGANIZTION,
                RULES.CREATE,
                permission
              )}
            </div>
          </div>
          <Modal
            size="xl"
            show={showOrganizationModal}
            onHide={() => setShowOrganizationModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="manage-data-modal">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Company</Form.Label>
                  <Form.Control value={newOrganization} onChange={handleNewOrganizationChange} />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <PrimaryButton text="Cancel" clicked={() => setShowOrganizationModal(false)} />
              <PrimaryButton text="Save" clicked={() => saveNewCompany()} />
            </Modal.Footer>
          </Modal>
        </div>
        <div className="org-table-container">
          <div>
            <CustomTable
              data={currentDivisions}
              header={divisionDataColumns}
              action={actions}
              deleteRowData={(data) => {
                deleteDivision(data.id);
              }}
              numberOfRows={5}
            />
            <div className="add-row-container">
              {/* <PrimaryButton
                clicked={() => setShowDivisionModal(true)}
                text="Add Division"
              ></PrimaryButton> */}
              {renderButtonWithPermissions(
                'Add Division',
                () => setShowDivisionModal(true),
                SCREEN.ORGANIZTION,
                RULES.CREATE,
                permission
              )}
            </div>
          </div>
          <Modal size="xl" show={showDivisionModal} onHide={() => setShowDivisionModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Division</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="manage-data-modal">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Division</Form.Label>
                  <Form.Control value={newDivision} onChange={handleNewDivisionChange} />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <PrimaryButton text="Cancel" clicked={() => setShowDivisionModal(false)} />
              <PrimaryButton text="Save" clicked={() => saveNewDivision()} />
            </Modal.Footer>
          </Modal>
        </div>
        <div className="org-table-container">
          <div>
            <CustomTable
              data={currentUnits}
              header={businessUnitDataColumns}
              action={actions}
              deleteRowData={(data) => {
                deleteUnit(data.id);
              }}
              numberOfRows={5}
            />
            <div className="add-row-container">
              {/* <PrimaryButton
                clicked={() => setShowUnitModal(true)}
                text="Add Unit"
              ></PrimaryButton> */}
              {renderButtonWithPermissions(
                'Add Unit',
                () => setShowUnitModal(true),
                SCREEN.ORGANIZTION,
                RULES.CREATE,
                permission
              )}
            </div>
          </div>
          <Modal size="xl" show={showUnitModal} onHide={() => setShowUnitModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Unit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="manage-data-modal">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Unit</Form.Label>
                  <Form.Control value={newUnit} onChange={handleNewUnitChange} />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <PrimaryButton text="Cancel" clicked={() => setShowUnitModal(false)} />
              <PrimaryButton text="Save" clicked={() => saveNewUnit()} />
            </Modal.Footer>
          </Modal>
        </div>
        <div className="org-table-container">
          <div>
            <CustomTable
              data={currentRegions}
              header={regionColumns}
              action={actions}
              deleteRowData={(data) => {
                deleteRegion(data.id);
              }}
              numberOfRows={5}
            />
            <div className="add-row-container">
              {/* <PrimaryButton
                clicked={() => setShowRegionModal(true)}
                text="Add Region"
              ></PrimaryButton> */}
              {renderButtonWithPermissions(
                'Add Region',
                () => setShowRegionModal(true),
                SCREEN.ORGANIZTION,
                RULES.CREATE,
                permission
              )}
            </div>
          </div>
          <Modal size="xl" show={showRegionModal} onHide={() => setShowRegionModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Region</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="manage-data-modal">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Region</Form.Label>
                  <Form.Control value={newRegion} onChange={handleNewRegionChange} />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <PrimaryButton text="Cancel" clicked={() => setShowRegionModal(false)} />
              <PrimaryButton text="Save" clicked={() => saveNewRegion()} />
            </Modal.Footer>
          </Modal>
        </div>
        <div className="org-table-container">
          <div>
            <CustomTable
              data={currentAreas}
              header={areaColumns}
              action={actions}
              deleteRowData={(data) => {
                deleteArea(data.id);
              }}
              numberOfRows={5}
            />
            <div className="add-row-container">
              {/* <PrimaryButton
                clicked={() => setShowAreaModal(true)}
                text="Add Area"
              ></PrimaryButton> */}
              {renderButtonWithPermissions(
                'Add Area',
                () => setShowAreaModal(true),
                SCREEN.ORGANIZTION,
                RULES.CREATE,
                permission
              )}
            </div>
          </div>
          <Modal size="xl" show={showAreaModal} onHide={() => setShowAreaModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Area</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="manage-data-modal">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Area</Form.Label>
                  <Form.Control value={newArea} onChange={handleNewAreaChange} />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <PrimaryButton text="Cancel" clicked={() => setShowAreaModal(false)} />
              <PrimaryButton text="Save" clicked={() => saveNewArea()} />
            </Modal.Footer>
          </Modal>
        </div>
        <div className="org-table-container">
          <div>
            <CustomTable
              data={currentLaboratories}
              header={laboratoryColumns}
              action={actions}
              deleteRowData={(data) => {
                deleteLaboratory(data.id);
              }}
              numberOfRows={5}
            />
            <div className="add-row-container">
              {/* <PrimaryButton
                clicked={() => setShowLaboratoryModal(true)}
                text="Add Laboratory"
              ></PrimaryButton> */}
              {renderButtonWithPermissions(
                'Add Laboratory',
                () => setShowLaboratoryModal(true),
                SCREEN.ORGANIZTION,
                RULES.CREATE,
                permission
              )}
            </div>
          </div>
          <Modal size="xl" show={showLaboratoryModal} onHide={() => setShowLaboratoryModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Laboratory</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="manage-data-modal">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Laboratory</Form.Label>
                  <Form.Control value={newLaboratory} onChange={handleNewLaboratoryChange} />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <PrimaryButton text="Cancel" clicked={() => setShowLaboratoryModal(false)} />
              <PrimaryButton text="Save" clicked={() => saveNewLaboratory()} />
            </Modal.Footer>
          </Modal>
        </div>
        <div className="org-table-container-end">
          <div>
            <CustomTable
              data={currentDepartments}
              header={departmentColumns}
              action={actions}
              deleteRowData={(data) => {
                deleteDepartment(data.id);
              }}
              numberOfRows={5}
            />
            <div className="add-row-container">
              {/* <PrimaryButton
                clicked={() => setShowDepartmentModal(true)}
                text="Add Department"
              ></PrimaryButton> */}
              {renderButtonWithPermissions(
                'Add Department',
                () => setShowDepartmentModal(true),
                SCREEN.ORGANIZTION,
                RULES.CREATE,
                permission
              )}
            </div>
          </div>
          <Modal size="xl" show={showDepartmentModal} onHide={() => setShowDepartmentModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Department</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="manage-data-modal">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Department</Form.Label>
                  <Form.Control value={newDepartment} onChange={handleNewDepartmentChange} />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <PrimaryButton text="Cancel" clicked={() => setShowLaboratoryModal(false)} />
              <PrimaryButton text="Save" clicked={() => saveNewDepartment()} />
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div className="organization-table-container">
        <Col>
          <CustomTable
            data={currentCompanies}
            header={organizationTableColumns}
            numberOfRows={20}
            action={[...actions, 'edit']}
            deleteRowData={(data) => {
              deleteCompany(data.id);
            }}
            updateRowData={(data) => {
              setCompaniesToUpdate([...companiesToUpdate, data]);
            }}
          />
        </Col>
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {renderButtonWithPermissions(
              'Add Organization',
              () => handleOrganizationModalOpen(),
              SCREEN.ORGANIZTION,
              RULES.CREATE,
              permission
            )}
          </div>
          {renderAuthButtonWithPermission(
            handleResults,
            auth,
            currentPersonnel,
            permission,
            SCREEN.ORGANIZTION,
            RULES.CREATE
          )}
        </div>
        <div>
          {/* <PrimaryButton
            clicked={saveCompanies}
            text="Save data"
          ></PrimaryButton> */}
          {renderButtonWithPermissions(
            'Save data',
            () => saveCompanies(),
            SCREEN.ORGANIZTION,
            RULES.SAVE,
            permission
          )}
        </div>
      </div>
      <Modal size="xl" show={showCompanyModal} onHide={() => setShowCompanyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="manage-data-modal">
            <Form.Group>
              <Form.Label>Company</Form.Label>
              <Form.Select
                onChange={handleOrgCompanyChange}
                value={newOrganization.name}
                aria-label="Default select example"
              >
                <option value="">Select company</option>
                {renderOptions(currentOrganizations)}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Division</Form.Label>
              <Form.Select
                onChange={handleOrgDivisionChange}
                value={newOrganization.division}
                aria-label="Default select example"
              >
                <option value="">Select division</option>
                {renderOptions(currentDivisions)}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Unit</Form.Label>
              <Form.Select
                onChange={handleOrgUnitChange}
                value={newOrganization.businessUnit}
                aria-label="Default select example"
              >
                <option value="">Select unit</option>
                {renderOptions(currentUnits)}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Region</Form.Label>
              <Form.Select
                onChange={handleOrgRegionChange}
                value={newOrganization.region}
                aria-label="Default select example"
              >
                <option value="">Select region</option>
                {renderOptions(currentRegions)}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Area</Form.Label>
              <Form.Select
                onChange={handleOrgAreaChange}
                value={newOrganization.area}
                aria-label="Default select example"
              >
                <option value="">Select area</option>
                {renderOptions(currentAreas)}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Laboratory</Form.Label>
              <Form.Select
                onChange={handleOrgLaboratoryChange}
                value={newOrganization.laboratory}
                aria-label="Default select example"
              >
                <option value="">Select laboratory</option>
                {renderOptions(currentLaboratories)}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Department</Form.Label>
              <Form.Select
                onChange={handleOrgDepartmentChange}
                value={newOrganization.department}
                aria-label="Default select example"
              >
                <option value="">Select department</option>
                {renderOptions(currentDepartments)}
              </Form.Select>
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton text="Cancel" clicked={() => setShowCompanyModal(false)} />
          <PrimaryButton text="Save" clicked={() => saveNewOrganization()} />
        </Modal.Footer>
      </Modal>
      {loading && <Loading />}
    </div>
  );
}

export default OrganizationSection;
