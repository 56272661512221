import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';
import { useAuth } from '../util/auth';
import './AuthSocial.scss';

function AuthSocial({ onAuth, onError, providers, buttonAction, showLastUsed }) {
  const auth = useAuth();
  const [pending, setPending] = useState(null);
  const [lastUsed, setLastUsed] = useState(null);

  const providerDisplayNames = {
    google: 'Google',
    facebook: 'Facebook',
    twitter: 'Twitter',
    github: 'GitHub',
    microsoft: 'Azure Active Directory'
  };

  const onSigninWithProvider = (provider) => {
    setPending(provider);
    auth
      .signinWithProvider(provider)
      .then((user) => {
        localStorage.setItem('lastUsedAuthProvider', provider);
        onAuth(user);
      })
      .catch((error) => {
        setPending(null);
        onError(error.message);
      });
  };

  // Get value of last used auth provider
  useEffect(() => {
    if (showLastUsed) {
      const thisLastUsed = window.localStorage.getItem('lastUsedAuthProvider');
      if (thisLastUsed) {
        setLastUsed(thisLastUsed);
      }
    }
  }, [showLastUsed]);

  return (
    <>
      {providers.map((provider) => (
        <Button
          variant="light"
          size="lg"
          block
          onClick={() => {
            onSigninWithProvider(provider);
          }}
          className="position-relative"
          key={provider}
        >
          {pending !== provider && (
            <span>
              {buttonAction} with {providerDisplayNames[provider]}
            </span>
          )}

          {pending === provider && (
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden
              className="align-baseline text-primary"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}

          {provider === lastUsed && (
            <Badge
              variant="warning"
              className="position-absolute font-weight-normal"
              style={{
                top: '-6px',
                right: '-6px',
                opacity: 0.7
              }}
            >
              Last used
            </Badge>
          )}
        </Button>
      ))}
    </>
  );
}

AuthSocial.propTypes = {
  onAuth: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  providers: PropTypes.arrayOf(PropTypes.string).isRequired,
  showLastUsed: PropTypes.bool.isRequired,
  buttonAction: PropTypes.string.isRequired
};

export default AuthSocial;
