import axios from 'axios';
import jsonpAdapter from 'axios-jsonp';

const jsonpCall = async (url) => {
  await axios({
    url,
    adapter: jsonpAdapter
  });
};

export default jsonpCall;
