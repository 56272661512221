import React, { useEffect, useState } from 'react';
import Nav from 'rsuite/Nav';

import './ILCPInternal.scss';
import DashboardScreen from './Screen/Dashboard';
import ReportScreen from './Screen/Report';
import TablesScreen from './Screen/Tables';
import PlotsScreen from './Screen/Plots';
import BiasScreen from './Screen/Bias';
import {
  getInternalData,
  getLocationData,
  getInternalMetadata,
  getInternalRunData,
  updateInternalMetadata,
  updateRun,
  updateMultipleInternalMetadata
} from '../../../util/db';
import { TableAndPlots } from './common/TablesAndPlotsContext';

const ILCPInternal = () => {
  const [active, setActive] = useState('Dashboard');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [runData, setRunData] = useState({});
  const [metaData, setMetaData] = useState({});

  const [locationData, setLocationData] = useState(new Map());

  useEffect(() => {
    (async () => {
      setLoading(true);
      const [locationResult, dataResult, metaDataResult, runDataResult] = await Promise.all([
        getLocationData(),
        getInternalData(),
        getInternalMetadata(),
        getInternalRunData()
      ]);
      setLocationData(locationResult);
      setData(dataResult);
      setRunData(runDataResult);
      setMetaData(metaDataResult);
      setLoading(false);
    })();
  }, []);

  const updateMetaData = (metadata) => {
    updateInternalMetadata(metadata);

    setMetaData({
      ...metaData,
      [metadata.key]: {
        ...metadata
      }
    });
  };

  const updateMultipleMetaData = async (metaDatas) => {
    const newMetaData = {};
    metaDatas.forEach((metadata) => {
      if (!newMetaData[metadata.key]) {
        newMetaData[metadata.key] = metadata;
      }
    });
    setMetaData({
      ...metaData,
      ...newMetaData
    });
    await updateMultipleInternalMetadata(metaDatas);
  };

  const updateRunData = (response, key) => {
    const object = {};
    object[key] = { ...response, runKey: key };

    setRunData({
      ...runData,
      ...object
    });
    updateRun({ ...response }, key);
  };

  return (
    <>
      <div className="contentScreen">
        <div className="contentNav">
          <Nav appearance="subtle" activeKey={active} onSelect={setActive}>
            <Nav.Item eventKey="Dashboard">Dashboard</Nav.Item>
            <Nav.Item eventKey="Report">Report</Nav.Item>
            <Nav.Item eventKey="Tables">Tables</Nav.Item>
            <Nav.Item eventKey="Plots">Plots</Nav.Item>
            <Nav.Item eventKey="Bias">Bias</Nav.Item>
          </Nav>
        </div>
        <div className={`${active !== 'Dashboard' && 'tabHidden'} h `}>
          <DashboardScreen
            data={data}
            loading={loading}
            locationData={locationData}
            runData={runData}
            metaData={metaData}
            updateMetaData={updateMetaData}
          />
        </div>
        <div className={`${active !== 'Report' && 'tabHidden'} `}>
          <ReportScreen
            data={data}
            loading={loading}
            locationData={locationData}
            runData={runData}
            metaData={metaData}
            updateMetaData={updateMetaData}
            updateRunData={updateRunData}
          />
        </div>
        <TableAndPlots data={data} loading={loading} locationData={locationData} runData={runData}>
          <div className={`${active !== 'Tables' && 'tabHidden'} `}>
            <TablesScreen data={data} loading={loading} />
          </div>
          <div className={`${active !== 'Plots' && 'tabHidden'} `}>
            <PlotsScreen />
          </div>
        </TableAndPlots>
        <div className={`${active !== 'Bias' && 'tabHidden'} `}>
          <BiasScreen
            data={data}
            loading={loading}
            locationData={locationData}
            runData={runData}
            metaData={metaData}
            updateMetaData={updateMultipleMetaData}
          />
        </div>
      </div>
    </>
  );
};

export default ILCPInternal;
