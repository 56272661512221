import React from 'react';
import Meta from '../components/Meta';
import { requireAuth } from '../util/auth';
import InstrumentManagement from '../components/InstrumentManagement';

const InstrumentPage = () => {
  return (
    <>
      <Meta title="Instrument Management" />
      <InstrumentManagement />
    </>
  );
};

export default requireAuth(InstrumentPage);
