/* eslint-disable react/no-array-index-key */

import React, { useEffect, useRef, useState } from 'react';
import SelectPicker from 'rsuite/SelectPicker';
import Table from 'rsuite/Table';
import Button from 'rsuite/Button';
import Nav from 'rsuite/Nav';
import CloudReflashIcon from '@rsuite/icons/CloudReflash';
import FunnelIcon from '@rsuite/icons/Funnel';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import { read } from 'xlsx';
import Papa from 'papaparse';
import IconButton from 'rsuite/IconButton';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import Whisper from 'rsuite/Whisper';
import Tooltip from 'rsuite/Tooltip';
import Modal from 'rsuite/Modal';

import './ILCPExternal.scss';
import Input from 'rsuite/Input';
import Tag from 'rsuite/Tag';
import Toggle from 'rsuite/Toggle';
import TableExternalEdit from './TableExternalEdit/TableExternalEdit';
import {
  getAllExternalData,
  getAllProgramsByLabelAndProgramId,
  getCalculations,
  getLocations,
  saveCalculation
} from '../../../util/db';
import getMegaChartData from './TableExternalEdit/charData';
import readILCPFile from './ILCPReader';
import DataSetForm from './DataSetForm';
import getSheetInfo from './functions/getSheetInfo';
import jsonpCall from '../JsonpCall';
import CustomChart from '../CustomChart';
import SampleInfo from './sampleInfo';
import CalculationDetail from './calculationDetail';

import CalculationDetailHeader from './calculationDetailHeader';

const { Column, HeaderCell, Cell } = Table;

const dataSelect = ['0.40'].map((item) => ({
  label: item,
  value: item
}));

const fuelTypes = ['Gasoline', 'ULSD', 'Butane', '500 ppm LM diesel fuel', 'ECA marine fuel'].map(
  (item) => ({
    label: item,
    value: item
  })
);
const user = JSON.parse(localStorage.getItem('authUser'))?.email || '';
const ILCPExternal = () => {
  const [value, setValue] = useState([]);
  const [active, setActive] = useState('Tables');
  const [loadingPrimaryData, setLoadingPrimaryData] = useState(false);
  const [loadingChartsAndTables, setLoadingChartsAndTables] = useState(false);
  const [loadingPrograms, setLoadingPrograms] = useState(false);
  const [primaryData, setPrimaryData] = useState([]);
  const [primaryDataInUse, setPrimaryDataInUse] = useState({});
  const [dataSets, setDataSets] = useState([]);
  const [selectedDataSet, setSelectedDataSet] = useState('');
  const [selectedLab, setSelectedLab] = useState('');
  const [tableData, setTableData] = useState([]);
  const [allCharData, setAllCharData] = useState([]);
  const [firstChart, setFirstChart] = useState([]);
  const [secondChart, setSecondChart] = useState([]);
  const [thirdChart, setThirdChart] = useState([]);
  const [t1Headers, setT1Headers] = useState([]);
  const [t1Body, setT1Body] = useState([]);
  const [adrmsValues, setAdrmsValues] = useState({});
  const [secondBody, setSecondBody] = useState([]);
  const [calculationSelected, setCalculationSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const [storedCalculations, setStoredCalculations] = useState([]);

  const [substances, setSubstances] = useState([
    {
      label: 'Loading...',
      value: ''
    }
  ]);
  const [selectSubstance, setSelectSubstance] = useState('');
  const [labs, setLabs] = useState([]);

  const [showPanel, setShowPanel] = useState(false);
  const [fuelSelected, setFuelSelected] = useState('gasoline');
  const [lambdaSelected, setLambdaSelected] = useState('0.40');
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [comments, setComments] = useState('');
  const [applicableDate, setApplicableDate] = useState('');
  const [userCode, setUserCode] = useState('');
  const [hasPassed, setHasPassed] = useState(false);
  const getILCPData = () => {
    setLoadingPrimaryData(true);
    Promise.all([
      getAllExternalData().then(setPrimaryData),
      getLocations().then((res) => {
        setLocations(
          res.map((l) => ({
            label: l,
            value: l
          }))
        );
      })
    ]).finally(() => setLoadingPrimaryData(false));
  };
  const newDataSet = (newDatSet) => {
    getILCPData();
    setSelectedDataSet(newDatSet.name);
  };

  useEffect(() => {
    getILCPData();
  }, []);

  useEffect(() => {
    const ds = primaryData
      .map((item) => item.name)
      .map((name) => ({
        value: name,
        label: name
      }));

    setDataSets(ds);
  }, [primaryData]);

  useEffect(() => {
    if (selectedDataSet) {
      const found = primaryData.find((item) => item.name === selectedDataSet);
      if (found) {
        setPrimaryDataInUse(found);

        const map = found.header
          .map((h) => h.field)
          .sort()
          .map((field) => ({
            value: field,
            label: field
          }));

        setSubstances(map);

        getSheetInfo({ sheetId: found.sheetId }).then((res) => {
          setAdrmsValues(res);
        });
      }
    } else {
      setPrimaryDataInUse({});
      setSelectSubstance('');
      setSubstances([]);
    }
  }, [primaryData, selectedDataSet]);

  function isNumericVal(n) {
    return !Number.isNaN(parseFloat(n)) && Number.isFinite(n) && n + 0 === n;
  }

  function formatCell(cellValue) {
    return String(cellValue).length > 5 && isNumericVal(cellValue)
      ? cellValue.toFixed(3)
      : cellValue;
  }

  useEffect(() => {
    // Define the callback function

    window.gsheetCallbackIlcp = (dataIn) => {
      const data = dataIn;
      setLoadingChartsAndTables(true);
      const chartData = [];

      // great lakes has a header which we ignore; astm has no header in response
      const startI = 1;

      for (let i = startI; i < data.chartReturn.length; i += 1) {
        // start after header
        if (data.chartReturn[i][0] !== '#N/A') {
          for (let j = 0; j < 16; j += 1) {
            if (data.chartReturn[i][j] === '#N/A') {
              data.chartReturn[i][j] = undefined;
            }
          }
          chartData.push({
            id: data.chartReturn[i][0],
            result: data.chartReturn[i][1],
            average: data.chartReturn[i][2],
            round: data.chartReturn[i][3],
            mr: data.chartReturn[i][4],
            mravg: data.chartReturn[i][5],
            mrucl: data.chartReturn[i][6],
            zval: data.chartReturn[i][7],
            pp: data.chartReturn[i][8],
            ucl: data.chartReturn[i][9],
            lcl: data.chartReturn[i][10],
            iuwl: data.chartReturn[i][11],
            ilwl: data.chartReturn[i][12],
            ewmatrend: data.chartReturn[i][13],
            ewmaucl: data.chartReturn[i][14],
            ewmalcl: data.chartReturn[i][15]
          });
        }
      }

      setAllCharData(chartData);

      const t1head = [];
      const t1body = [];
      const t2bodyJson = [];

      const t1colct = 4;

      for (let i = 0; i < t1colct; i += 1) {
        t1head.push(data.tableReturn[0][i]);
      }

      for (let r = 1; r < data.tableReturn.length; r += 1) {
        const t1row = [];
        if (data.tableReturn[r][0] !== '') {
          for (let i = 0; i < t1colct; i += 1) {
            t1row.push(
              String(data.tableReturn[r][i]).length > 5 && isNumericVal(data.tableReturn[r][i])
                ? data.tableReturn[r][i].toFixed(3)
                : data.tableReturn[r][i]
            );
          }
          t1body.push(t1row);
        }

        if (data.tableReturn[r][t1colct + 1] !== '') {
          const t1skip = t1colct + 1;
          const row = data.tableReturn[r];

          const jsonObject = {
            round: formatCell(row[t1skip]),
            stdev: formatCell(row[t1skip + 1]),
            ftest: formatCell(row[t1skip + 2]),
            value: formatCell(row[t1skip + 3]),
            mean: formatCell(row[t1skip + 4]),
            ptresult: formatCell(row[t1skip + 5]),
            delta1090: formatCell(row[t1skip + 6]),
            criticalValue1090: formatCell(row[t1skip + 7]),
            check1090: formatCell(row[t1skip + 8])
          };

          t2bodyJson.push(jsonObject);
        }
      }

      setT1Headers([...t1head]);
      setT1Body([...t1body]);
      setSecondBody([...t2bodyJson]);
      setLoadingChartsAndTables(false);
    };

    // Clean up the callback function when the component is unmounted
    return () => {
      delete window.gsheetCallbackIlcp;
    };
  }, []);
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setValue(file);
    if (file.name.match(/xlsx/i)) {
      const readerXLSX = new FileReader();
      readerXLSX.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: 'array' });
        readILCPFile({
          type: 'xlsx',
          workbook,
          ilcpData: primaryDataInUse,
          lab: selectedLab
        });
      };
      readerXLSX.readAsArrayBuffer(file);
    } else {
      // CSV
      const readerCSV = new FileReader();
      readerCSV.onload = (e) => {
        const csv = Papa.parse(e.target.result);
        readILCPFile({
          type: 'csv',
          workbook: csv?.data,
          ilcpData: primaryDataInUse,
          round: String(file.name).replace(/"/g, '')
        });
      };
      readerCSV.readAsText(file);
    }
    getILCPData();
  };

  useEffect(() => {
    const dataBlock = [];
    const parameterBlock = [];
    let sitestdev = 1;
    if (tableData.length > 0) {
      setLoadingChartsAndTables(true);
      for (let i = 0; i < tableData.length; i += 1) {
        const dataObj = tableData[i];
        dataBlock.push(dataObj.round);
        dataBlock.push(dataObj.result);
        dataBlock.push(dataObj.mean);
        dataBlock.push(dataObj.count);
        dataBlock.push(dataObj.stdev);
        dataBlock.push(dataObj.rastm);
        dataBlock.push(dataObj.r);
        dataBlock.push(dataObj.z);
        dataBlock.push(dataObj.o);
        dataBlock.push(dataObj.siteStdev);
        if (dataObj.siteStdev > 0) {
          sitestdev = dataObj.siteStdev;
        }
      }

      parameterBlock.push(sitestdev);
      parameterBlock.push(lambdaSelected);
      parameterBlock.push(fuelSelected);
      parameterBlock.push(encodeURIComponent(selectedDataSet));
      parameterBlock.push(encodeURIComponent(selectSubstance));

      const myUrl = `https://script.google.com/macros/s/${primaryDataInUse.scriptId}/exec?sheetId=${
        primaryDataInUse.sheetId
      }&parameterBlock=${parameterBlock.join('|')}&numRows=${
        tableData.length
      }&callback=gsheetCallbackIlcp&dataBlock=${dataBlock.join('|')}`;

      jsonpCall(myUrl);
    } else {
      setT1Headers([]);
      setT1Body([]);
      setSecondBody([]);
      setLoadingChartsAndTables(false);
    }
  }, [tableData, fuelSelected]);

  useEffect(() => {
    if (value.length > 0) handleFileUpload(value);
  }, [value]);

  const isFilterEnabled = () => selectSubstance && primaryDataInUse.programId;
  const handleSubmit = () => {
    if (isFilterEnabled()) {
      setLoadingChartsAndTables(true);
      setLoadingPrograms(true);
      getAllProgramsByLabelAndProgramId(selectSubstance, primaryDataInUse.programId)
        .then((data) =>
          setTableData(data?.filter((d) => !selectedLab || d.labName === selectedLab))
        )
        .finally(() => setLoadingPrograms(false));
    }
  };

  function updateCalculations() {
    getCalculations(primaryDataInUse.programId).then((res) => {
      setStoredCalculations(res);
    });
  }

  useEffect(() => {
    if (primaryDataInUse.programId) {
      updateCalculations();
      setLabs(primaryDataInUse.labs || []);
    }
  }, [primaryDataInUse]);

  const optionsChart = {
    title: 'Company Performance',
    curveType: 'function',
    legend: { position: 'top' },
    series: {
      0: {
        curveType: 'none',
        pointSize: 5
      }
    },
    intervals: {
      style: 'points',
      pointSize: 4
    },
    chartArea: {
      left: '5%',
      width: '90%'
    }
  };

  const table2headers = (isCalculation = false) => [
    {
      label: 'Round',
      key: 'round',
      display: true
    },
    {
      label: 'Std Dev',
      key: 'stdev',
      display: true
    },
    {
      label: 'F test',
      key: 'ftest',
      display: true
    },
    {
      label: 'Value',
      key: 'value',
      display: true
    },
    {
      label: 'Mean',
      key: 'mean',
      display: true
    },
    {
      label: 'PT result',
      key: 'ptresult',
      display: true
    },
    {
      label: '1090 delta',
      key: 'delta1090',
      display: true
    },
    {
      label: '1090 critical value',
      key: 'criticalValue1090',
      display: true
    },
    {
      label: '1090 check',
      key: 'check1090',
      display: true
    },
    {
      label: 'Parameter',
      key: 'parameter',
      display: isCalculation
    },
    {
      label: 'Date',
      key: 'date',
      display: isCalculation
    },
    {
      label: 'Program',
      key: 'program',
      display: isCalculation
    },
    {
      label: 'Fuel Type',
      key: 'fuelType',
      display: isCalculation
    },
    {
      label: 'User',
      key: 'user',
      display: isCalculation
    },
    {
      label: 'Location',
      key: 'location',
      display: isCalculation
    },
    {
      label: 'Comments',
      key: 'comments',
      display: isCalculation
    },
    {
      label: 'Outcome',
      key: 'hasPassed',
      display: isCalculation,
      render: (val) => (val ? 'Pass' : 'Fail')
    }
  ];
  const renderColumns2 = (isCalculation) => {
    const map = table2headers(isCalculation)
      .filter((h) => h.display)
      .map((header) => (
        <Column flexGrow={1} align="center" key={`t2-${header.key}`}>
          <HeaderCell>{header.label}</HeaderCell>
          <Cell dataKey={header.key}>
            {(rowData) => {
              if (header.render) {
                return header.render(rowData[header.key]);
              }
              return rowData[header.key];
            }}
          </Cell>
        </Column>
      ));
    const column = (
      <Column key={2} flexGrow={1} align="center">
        <HeaderCell>Report</HeaderCell>
        <Cell>
          {(rowData) => (
            <Button
              appearance="link"
              onClick={() => {
                setHasPassed(rowData.hasPassed);
                setCalculationSelected(rowData);
                setOpenModal(true);
              }}
              disabled={!rowData.delta1090 || !(rowData.location || selectedLocation)}
            >
              Generate
            </Button>
          )}
        </Cell>
      </Column>
    );
    return [...map, column];
  };
  const renderColumns = (headers) => {
    const map = headers.map((header, index) => (
      <Column key={`i-${index}`} flexGrow={1} align="center">
        <HeaderCell>{header}</HeaderCell>
        <Cell dataKey={`${index}`} />
      </Column>
    ));

    return [...map];
  };

  useEffect(() => {
    if (allCharData.length) {
      const megaChartData = getMegaChartData({
        includeAll: true,
        trendLine: false,
        averageLine: false,
        useLastAverage: false,
        averageLineMultiple: 0,
        xDataField: 'round',
        yDataField: 'mr',
        yDataField2: 'mravg',
        yDataField3: 'mrucl',
        yDataField4: '',
        yDataField5: '',
        yDataField6: '',
        yDataField7: '',
        color1: 'green',
        color2: 'orange',
        color3: 'orange',
        color4: '',
        color5: '',
        color6: '',
        color7: '',
        chartData: allCharData
      });
      setFirstChart(megaChartData);

      const second = getMegaChartData({
        includeAll: true,
        trendLine: false,
        averageLine: false,
        useLastAverage: false,
        averageLineMultiple: 0,
        xDataField: 'round',
        yDataField: 'result',
        yDataField2: 'average',
        yDataField3: 'ewmatrend',
        yDataField4: 'ucl',
        yDataField5: 'lcl',
        yDataField6: 'ewmaucl',
        yDataField7: 'ewmalcl',
        color1: 'green',
        color2: 'orange',
        color3: 'blue',
        color4: 'orange',
        color5: 'orange',
        color6: 'purple',
        color7: 'purple',
        chartData: allCharData
      });

      setSecondChart(second);

      const third = getMegaChartData({
        includeAll: true,
        trendLine: true,
        averageLine: false,
        useLastAverage: false,
        averageLineMultiple: 0,
        xDataField: 'zval',
        yDataField: 'pp',
        yDataField2: '',
        yDataField3: '',
        yDataField4: '',
        yDataField5: '',
        yDataField6: '',
        yDataField7: '',
        color1: 'green',
        color2: '',
        color3: '',
        color4: '',
        color5: '',
        color6: '',
        color7: '',
        chartData: allCharData,
        isDotted: true
      });

      setThirdChart(third);
    }
  }, [allCharData]);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const calculationValue = {
    ...calculationSelected,
    comments,
    hasPassed,
    date: new Date().toLocaleDateString(),
    program: selectedDataSet,
    parameter: selectSubstance,
    fuelType: fuelSelected,
    user,
    location: selectedLocation,
    programId: primaryDataInUse.programId,
    applicableDate,
    userCode
  };
  const printCalculation = () => {
    localStorage.setItem('calculation', JSON.stringify(calculationValue));
    window.open('/print-calculation', '_blank');
  };
  const saveNewCalculation = async () => {
    setLoadingChartsAndTables(true);
    setOpenModal(false);
    await saveCalculation(calculationValue);
    updateCalculations();
    setLoadingChartsAndTables(false);
  };

  return (
    <div
      style={{
        width: '80vw',
        marginBottom: '100px'
      }}
    >
      <Modal
        backdrop
        keyboard={false}
        size="lg"
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Modal.Header>
          <CalculationDetailHeader />
        </Modal.Header>

        <Modal.Body>
          <div
            style={{
              width: '100%',
              display: 'grid',
              gap: '1rem',
              justifyContent: 'center'
            }}
          >
            <SampleInfo
              program={calculationSelected.program || selectedDataSet}
              parameter={calculationSelected.parameter || selectSubstance}
              fuelType={calculationSelected.fuelType || fuelSelected}
              user={calculationSelected.user || user}
              location={calculationSelected.location || selectedLocation}
            />

            <CalculationDetail calculation={calculationSelected} />

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 5fr',
                gap: '0.5rem'
              }}
            >
              <Tag color="blue" size="lg">
                Assessor Comments
              </Tag>
              <Input
                as="textarea"
                placeholder="Assessor Comments"
                value={comments}
                onChange={setComments}
              />

              <Tag color="blue" size="lg">
                Applicable Date
              </Tag>
              <Input
                placeholder="Applicable Date"
                value={applicableDate}
                onChange={setApplicableDate}
              />

              <Tag color="blue" size="lg">
                User Code
              </Tag>
              <Input placeholder="User Code" value={userCode} onChange={setUserCode} />

              <Tag color="blue" size="lg">
                Assessment outcome
              </Tag>

              <Toggle
                size="lg"
                checkedChildren="Pass"
                unCheckedChildren="Fail"
                checked={hasPassed}
                onChange={(val) => setHasPassed(val)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button appearance="primary" onClick={saveNewCalculation}>
            Save
          </Button>
          <Button appearance="primary" onClick={printCalculation}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>
      <DataSetForm
        setShowPanel={setShowPanel}
        showPanel={showPanel}
        handleNewDataSet={newDataSet}
      />
      <div className="contentFilter">
        <Whisper
          trigger="hover"
          placement="top"
          speaker={<Tooltip>Add a new Data Set</Tooltip>}
          onClick={() => setShowPanel(true)}
        >
          <IconButton
            style={{
              height: '44px',
              width: '100px'
            }}
            onClick={() => setShowPanel(true)}
            icon={<AddOutlineIcon />}
          />
        </Whisper>

        <div className="contentSelectView">
          <label>Program</label>
          <SelectPicker
            loading={loadingPrimaryData}
            className="select"
            data={dataSets}
            value={selectedDataSet}
            onClean={() => setSelectedDataSet('')}
            onSelect={(v) => setSelectedDataSet(v)}
          />
        </div>

        <div className="contentSelectView">
          <label>Lab</label>
          <SelectPicker
            loading={loadingPrimaryData}
            className="select"
            data={[
              { value: '', label: 'Default' },
              ...labs.sort().map((l) => ({ value: l, label: l }))
            ]}
            value={selectedLab}
            onClean={() => setSelectedLab('')}
            onSelect={(v) => setSelectedLab(v)}
          />
        </div>

        <div className="contentSelectView">
          <label>Method / Parameter</label>
          <SelectPicker
            loading={loadingPrimaryData}
            className="select"
            data={substances}
            onSelect={(v) => setSelectSubstance(v)}
          />
        </div>

        <div>
          <Button
            className="btnFilter"
            appearance="primary"
            disabled={!isFilterEnabled()}
            onClick={handleSubmit}
            startIcon={<FunnelIcon />}
          >
            Apply filter
          </Button>
        </div>
      </div>
      <div className="contentFile">
        <label className="labelFile">
          {value[0]?.name ? value[0]?.name : 'No file chosen yet'}
        </label>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileUpload}
        />
        <Button
          color="blue"
          className="btnFile"
          appearance="ghost"
          onClick={handleClick}
          disabled={!selectedDataSet}
          startIcon={<CloudReflashIcon />}
        >
          Choose a file
        </Button>
      </div>
      <TableExternalEdit tableData={tableData} loading={loadingPrograms} />
      <div className="contentParamtG">
        <div>
          <label className="title">Parameters</label>
        </div>
      </div>
      <div className="contentDownload">
        <div className="contentAllSelect">
          <div className="contentSelectView">
            <label>EWMA Lambda</label>
            <SelectPicker
              className="select"
              data={dataSelect}
              value={lambdaSelected}
              onClean={() => setSelectedDataSet('0.40')}
              onSelect={(v) => setLambdaSelected(v)}
            />
          </div>

          <div className="contentSelectView">
            <label>Fuel Type</label>
            <SelectPicker
              className="select"
              data={fuelTypes}
              value={fuelSelected}
              onClean={() => setSelectedDataSet('gasoline')}
              onSelect={(v) => setFuelSelected(v)}
            />
          </div>
          <div className="contentSelectView">
            <label>Location</label>
            <SelectPicker
              className="select"
              data={locations}
              value={selectedLocation}
              onClean={() => setSelectedLocation('')}
              onSelect={(v) => setSelectedLocation(v)}
            />
          </div>
        </div>
        <a
          href={`https://spreadsheets.google.com/feeds/download/spreadsheets/Export?key=${primaryDataInUse.sheetId}&exportFormat=xlsx`}
          id="download"
        >
          <Button className="btnDownload" appearance="primary" startIcon={<FileDownloadIcon />}>
            Download
          </Button>
        </a>
      </div>

      <div className="contentNav">
        <Nav appearance="subtle" activeKey={active} onSelect={setActive}>
          <Nav.Item eventKey="Tables">Tables</Nav.Item>
          <Nav.Item eventKey="Plots">Plots</Nav.Item>
          <Nav.Item eventKey="Calculations">Calculations</Nav.Item>
        </Nav>
      </div>

      <div className={`${active === 'Tables' ? 'contentTables' : 'tabHidden'}`}>
        <div className="table">
          <div className="contentTitle">
            <label className="title">Table 1: Study evaluation</label>
          </div>
          <Table
            data={t1Body}
            bordered
            cellBordered
            rowHeight={40}
            headerHeight={35}
            height={360}
            loading={loadingChartsAndTables}
          >
            {renderColumns(t1Headers)}
          </Table>
        </div>
        <div className="table">
          <div className="contentTitle">
            <label className="title">Table 2: Plotting position calculation</label>
          </div>
          <Table
            data={secondBody}
            bordered
            cellBordered
            rowHeight={40}
            headerHeight={35}
            height={360}
            loading={loadingChartsAndTables}
          >
            {renderColumns2(false)}
          </Table>
        </div>
      </div>

      <div className={`${active === 'Plots' ? 'contentPlotsType' : 'tabHidden'}`}>
        <CustomChart
          type="LineChart"
          width="100%"
          height="400px"
          data={firstChart}
          options={{
            ...optionsChart,
            title: 'Moving Range and Control Limits (all data)'
          }}
          loading={loadingChartsAndTables}
        />
        <CustomChart
          type="LineChart"
          width="100%"
          height="400px"
          data={secondChart}
          options={{
            ...optionsChart,
            title: 'I Chart (all data)',
            series: {
              0: { pointSize: 5 },
              2: {
                lineDashStyle: [6, 6]
              },
              5: { color: '#21ded4' },
              6: { color: '#21ded4' }
            }
          }}
          loading={loadingChartsAndTables}
        />
        <CustomChart
          type="ScatterChart"
          width="100%"
          height="400px"
          data={thirdChart}
          loading={loadingChartsAndTables}
          options={{
            trendlines: { 1: {} },
            legend: { position: 'top' },
            chartArea: {
              left: '5%',
              width: '90%'
            },
            series: {
              0: { pointSize: 5 },
              1: { pointSize: 1 }
            },
            title: `Q/Q Chart (all data); Anderson-Darling: ${adrmsValues.rms} (RMS), ${adrmsValues.mr} (MR)`
          }}
        />
      </div>
      <div className={`${active === 'Calculations' ? '' : 'tabHidden'}`}>
        <div className="table">
          <div className="contentTitle">
            <label className="title">Stored Plotting position calculation</label>
          </div>
          <Table
            data={storedCalculations}
            bordered
            cellBordered
            rowHeight={40}
            headerHeight={35}
            height={360}
            loading={loadingChartsAndTables}
          >
            {renderColumns2(true)}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ILCPExternal;
