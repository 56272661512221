import React from 'react';
import Meta from '../components/Meta';
import AuthSection from '../components/AuthSection';
import { useRouter } from '../util/router';

function AuthPage() {
  const router = useRouter();

  const useMicrosoftLogin = String(window.location.hostname).match(/buckeye./);

  const providers = useMicrosoftLogin ? ['microsoft'] : null;

  return (
    <>
      <Meta title="Auth" />
      <AuthSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        type={router.query.type}
        providers={providers}
        afterAuthPath={router.query.next || '/dashboard'}
      />
    </>
  );
}

export default AuthPage;
