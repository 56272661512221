import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PageLoader from './PageLoader';
import { useAuth } from '../util/auth';
import { useRouter } from '../util/router';
import { redirectToBilling } from '../util/stripe';

function SettingsBilling({ onStatus }) {
  const router = useRouter();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (auth.user.planIsActive) {
      // If user has an active plan then
      // take them to Stripe billing
      redirectToBilling().catch((error) => {
        setLoading(false);
        onStatus({
          type: 'error',
          message: error.message
        });
      });
    } else {
      // Otherwise go to pricing so they can
      // purchase a plan
      router.replace('/pricing');
    }
  }, []);

  return (
    <>
      {loading && (
        <PageLoader
          style={{
            height: '50px'
          }}
        />
      )}
    </>
  );
}

SettingsBilling.propTypes = {
  onStatus: PropTypes.func.isRequired
};

export default SettingsBilling;
