import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { saveStdcmpData, updateStdcmpPrograms } from '../../../util/db';

function formatDate(date) {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

const useCsv = (programs) => {
  const [csvResult, setCsvResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  function rowToSample(row) {
    return {
      facility: row[0],
      product: row[1],
      method: row[2],
      date: new Date(row[3]).toISOString(),
      sampleID: row[4],
      owner: row[5],
      batchID: row[6],
      value: row[7],
      provider: row[8],
      provided: row[9],
      high: row[10],
      low: row[11],
      note: row[12],
      omit: row[13].replace(/\s/g, ''),
      editDate: formatDate(new Date()),
      ui: '',
      analyst: ''
    };
  }

  async function readPipelineCSV(csv) {
    if (csv && csv.length > 1) {
      let programsToSave = [];

      const data = [];
      for (let k = 1; k < csv.length; k += 1) {
        const row = csv[k];
        if (Object.keys(row).length >= 12) {
          const myRow = rowToSample(row);
          const { method } = myRow;

          const programFound = [...programs, ...programsToSave].find(
            (p) => p.facility === myRow.facility && p.name === myRow.product
          );

          if (!programFound) {
            const newProgram = {
              facility: myRow.facility,
              name: myRow.product,
              sheetId: '1-QZG4cURuN_E4aQpHTr69KLkmoELm_Fk4--Ea65XY38',
              programId: uuidv4(),
              header: [
                {
                  col: method,
                  parameter: method
                }
              ]
            };
            myRow.programId = newProgram.programId;

            programsToSave.push(newProgram);
          } else {
            const find = programFound.header?.find((h) => h.col === method);

            if (!find) {
              programFound.header = [
                ...programFound.header,
                {
                  col: method,
                  parameter: method
                }
              ];

              if (programsToSave.length === 0) {
                programsToSave.push(programFound);
              }

              programsToSave = programsToSave.map((p) => {
                if (p.programId === programFound.programId) {
                  return programFound;
                }
                return p;
              });
            }
            myRow.programId = programFound.programId;
          }

          data.push(myRow);
        }
      }

      if (programsToSave.length > 0) {
        Promise.all(
          programsToSave.map(async (p) => {
            await updateStdcmpPrograms({
              header: p.header,
              name: p.name,
              programId: p.programId,
              sheetId: p.sheetId,
              facility: p.facility
            });
          })
        ).then(() => {
          setCompleted(true);
        });
      }
      if (data.length > 0) {
        await saveStdcmpData(data);
        setLoading(false);
      }
    }
  }

  const handleCSVChange = async () => {
    setCompleted(false);
    if (!loading) {
      setLoading(true);
      if (csvResult && Array.isArray(csvResult)) {
        // try with another separator if length is 0
        if (csvResult.length === 0) {
          // alert("FuelSQC requires files to be tab-delimited.");
          setLoading(false);
        } else {
          await readPipelineCSV(csvResult);
        }
      }
    }
    setCompleted(true);
  };

  useEffect(() => {
    handleCSVChange(csvResult);
  }, [csvResult]);

  return {
    setCsvResult,
    loading,
    completed
  };
};

export default useCsv;
