import React from 'react';
import Meta from '../components/Meta';
import ILCPSection from '../components/ILCPSection/ILCPSection';
import { requireAuth } from '../util/auth';

const IlcpPage = () => {
  return (
    <>
      <Meta title="ILCP" />
      <ILCPSection />
    </>
  );
};

export default requireAuth(IlcpPage);
