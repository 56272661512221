import Placeholder from 'rsuite/Placeholder';
import Message from 'rsuite/Message';
import { Chart } from 'react-google-charts';
import React from 'react';
import PropTypes from 'prop-types';

const CustomChart = ({ loading, data, options, type, height = '400px' }) => {
  if (loading) return <Placeholder.Graph active={loading} />;

  if (data.length <= 1)
    return (
      <Message type="warning" header="No data" showIcon style={{ width: '100%' }}>
        Looks like we are missing data to render this chart
      </Message>
    );

  return <Chart chartType={type} width="100%" height={height} data={data} options={options} />;
};

CustomChart.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.array).isRequired,
  options: PropTypes.shape({}).isRequired,
  type: PropTypes.string.isRequired,
  height: PropTypes.string
};

CustomChart.defaultProps = {
  height: '400px',
  loading: false
};

export default CustomChart;
