import React, { useContext, useEffect, useState } from 'react';
import './SampleSection.scss';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../../util/auth';
import {
  createSample,
  createSampleProperty,
  deleteSample,
  deleteSampleProperty,
  updateSample,
  updateSampleProperty,
  useGetMethods,
  useGetPersonnel,
  useGetSampleProperties,
  useGetSamples,
  useFindControlStrategyConfigurationByFullKey
} from '../../util/db';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import CustomTable from '../Table/CustomTable';
import { getCurrentFeatureFlags } from '../../util/firebase';
import AppContext from '../AppContext';
import { RULES, SCREEN } from '../../util/Constant';
import {
  checkDelete,
  checkUpdate,
  renderButtonWithPermissions,
  renderAuthButtonWithPermission
} from '../../util/util';

function SampleSection() {
  const auth = useAuth();
  const [currentSamples, setCurrentSamples] = useState([]);
  const { currentFullKey } = useContext(AppContext);
  const [currentSamplesDropdown, setCurrentSamplesDropdown] = useState([]);
  const { data: samples } = useGetSamples();
  const { data: methods } = useGetMethods();
  const [currentMethodsDropdown, setCurrentMethodsDropdown] = useState([]);
  const [currentSampleProperties, setCurrentSampleProperties] = useState([]);
  const [currentControlStrategyConfigurations, setCurrentControlStrategyConfigurations] = useState(
    {}
  );
  const { data: personnel } = useGetPersonnel();
  const { data: sampleProperties } = useGetSampleProperties();
  const [samplesToUpdate, setSamplesToUpdate] = useState([]);
  const [samplePropertiesToUpdate, setSamplePropertiesToUpdate] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showPropertiesModal, setShowPropertiesModal] = useState(false);
  const [permission, setPermission] = useState(null);
  const [currentPersonnel, setCurrentPersonnel] = useState([]);
  const { data: controlStrategyConfigurations } =
    useFindControlStrategyConfigurationByFullKey(currentFullKey);
  const defaultNewSample = {
    name: '',
    standardMatrix: '',
    dateCreated: '',
    dateExpired: '',
    type: '',
    preparation: '',
    storage: '',
    lot: '',
    notes: ''
  };
  const [newSample, setNewSample] = useState(defaultNewSample);
  const [actions, setActions] = useState(['none']);
  const [hasEdit, setHasEdit] = useState(false);
  const [featureFlags] = useState(getCurrentFeatureFlags);

  const handleSampleChange = (propertyName, value) => {
    setNewSample((prevSample) => ({
      ...prevSample,
      [propertyName]: value
    }));
  };

  useEffect(() => {
    if (permission) {
      const value = [];
      if (checkDelete(permission, SCREEN.SAMPLE)) {
        value.push('delete');
      }
      if (checkUpdate(permission, SCREEN.SAMPLE)) {
        setHasEdit(checkUpdate(permission, SCREEN.SAMPLE));
        value.push('edit');
      }
      setActions(value);
    }
  }, [permission]);

  useEffect(() => {
    if (Array.isArray(controlStrategyConfigurations) && controlStrategyConfigurations.length > 0) {
      setCurrentControlStrategyConfigurations(controlStrategyConfigurations[0]);
    }
  }, [controlStrategyConfigurations]);

  const defaultNewSampleProperty = {
    methodVersion: '',
    relatedSampleName: '',
    parameter: '',
    procedure: '',
    units: '',
    arv: '',
    standardDeviation: '',
    n: '',
    uncertainty: '',
    lcl: '',
    ucl: '',
    notes: ''
  };
  const [newSampleProperty, setNewSampleProperty] = useState(defaultNewSampleProperty);

  const handleSamplePropertyChange = (propertyName, value) => {
    setNewSampleProperty((prevSampleProperty) => ({
      ...prevSampleProperty,
      [propertyName]: value
    }));
  };

  const handleCreateSample = () => {
    createSample(newSample);
    setNewSample(defaultNewSample);
    setShowModal(false);
  };

  const handleCreateSampleProperty = () => {
    createSampleProperty(newSampleProperty);
    setNewSampleProperty(defaultNewSampleProperty);
    setShowPropertiesModal(false);
  };

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        setPermission(auth.permissionData[matchingUser.permission]);
      }
      setCurrentPersonnel(personnel);
    }
  }, [personnel]);

  const saveSamples = () => {
    const currentSamplesToUpdate = samplesToUpdate;
    currentSamplesToUpdate.forEach((sample) => {
      updateSample(sample.id, sample);
    });
    setSamplesToUpdate([]);
  };

  const saveSampleProperties = () => {
    const currentSamplePropertiesToUpdate = samplePropertiesToUpdate;
    currentSamplePropertiesToUpdate.forEach((sampleProperty) => {
      updateSampleProperty(sampleProperty.id, sampleProperty);
    });
    setSamplePropertiesToUpdate([]);
  };

  useEffect(() => {
    if (Array.isArray(samples) && samples.length > 0) {
      const samplesDropdown = samples.map((sample) => {
        const sampleToReturn = {
          id: sample.id,
          value: sample.name,
          label: sample.name
        };

        return sampleToReturn;
      });
      setCurrentSamplesDropdown(samplesDropdown);
      setCurrentSamples(samples);
    }
  }, [samples]);

  useEffect(() => {
    if (Array.isArray(methods) && methods.length > 0) {
      const methodsDropdown = [];
      methods.forEach((method) => {
        const methodToReturn = {
          id: method.id,
          value: `${method.method}-${method.version}`,
          label: `${method.method}-${method.version}`
        };
        if (
          !methodsDropdown.find(
            (dropdownItem) => dropdownItem.value === `${method.method}-${method.version}`
          )
        ) {
          methodsDropdown.push(methodToReturn);
        }
      });
      setCurrentMethodsDropdown(methodsDropdown);
    }
  }, [methods]);

  // mapping lcl and ucl to empty string for now, but will update once we decide where to source data.
  const mapSampleProperties = (samplePropertiesToMap) => {
    let samplePropertiesToReturn = [...samplePropertiesToMap];
    if (featureFlags.includes('nonUs')) {
      samplePropertiesToReturn = samplePropertiesToReturn.map((obj) => ({
        ...obj,
        lcl: obj.lcl ? obj.lcl : '',
        ucl: obj.ucl ? obj.ucl : ''
      }));
    }
    return samplePropertiesToReturn;
  };

  useEffect(() => {
    if (Array.isArray(sampleProperties) && sampleProperties.length > 0) {
      setCurrentSampleProperties(mapSampleProperties(sampleProperties));
    }
  }, [sampleProperties, currentControlStrategyConfigurations]);

  const handleResults = (results) => {
    results.forEach((sample, index) => {
      if (index > 0) {
        const sampleToAdd = {
          name: sample[0],
          standardMatrix: sample[1],
          dateCreated: sample[2],
          dateExpired: sample[3],
          type: sample[4],
          preparation: sample[5],
          storage: sample[6],
          lot: sample[7],
          notes: sample[8]
        };
        createSample(sampleToAdd);
      }
    });
  };

  const handleResultsSampleProperties = (results) => {
    results.forEach((sampleProperty, index) => {
      if (index > 0) {
        const samplePropertyToAdd = {
          methodVersion: sampleProperty[0],
          relatedSampleName: sampleProperty[1],
          parameter: sampleProperty[2],
          procedure: sampleProperty[3],
          units: sampleProperty[4],
          arv: sampleProperty[5],
          standardDeviation: sampleProperty[6],
          n: sampleProperty[7],
          uncertainty: sampleProperty[8],
          notes: sampleProperty[9]
        };
        createSampleProperty(samplePropertyToAdd);
      }
    });
  };

  const samplePropertiesTableColumns = [
    {
      field: 'methodVersion',
      headerName: 'Method-Version',
      editable: hasEdit,
      flex: 4,
      sortable: false,
      type: 'singleSelect',
      valueOptions: currentMethodsDropdown
    },
    {
      field: 'relatedSampleName',
      headerName: 'Related Sample Name',
      editable: hasEdit,
      flex: 5,
      sortable: false,
      type: 'singleSelect',
      valueOptions: currentSamplesDropdown
    },
    {
      field: 'parameter',
      editable: hasEdit,
      flex: 2,
      headerName: 'Parameter'
    },
    {
      field: 'procedure',
      editable: hasEdit,
      flex: 3,
      headerName: 'Procedure'
    },
    {
      field: 'units',
      editable: hasEdit,
      flex: 2,
      headerName: 'Units'
    },
    {
      field: 'arv',
      editable: hasEdit,
      flex: 2,
      headerName: 'ARV'
    },
    {
      field: 'standardDeviation',
      editable: hasEdit,
      flex: 3,
      headerName: 'Standard Deviation'
    }
  ];

  const samplePropertiesTableColumnsExtend = [
    {
      field: 'n',
      editable: hasEdit,
      flex: 1,
      headerName: 'n'
    },
    {
      field: 'uncertainty',
      editable: hasEdit,
      flex: 3,
      headerName: 'Uncertainty'
    },
    {
      field: 'notes',
      editable: hasEdit,
      flex: 2,
      headerName: 'Notes'
    }
  ];

  const samplePropertiesTableColumnsNonUsExtend = [
    {
      field: 'lcl',
      headerName: 'LCL',
      editable: hasEdit,
      flex: 1
    },
    {
      field: 'ucl',
      headerName: 'UCL',
      editable: hasEdit,
      flex: 3
    },
    {
      field: 'notes',
      headerName: 'Notes',
      editable: hasEdit,
      flex: 2
    }
  ];

  const dataEntryTableColumns = [
    {
      field: 'name',
      flex: 1,
      editable: hasEdit,
      sortable: false,
      headerName: 'Sample Name'
    },
    {
      field: 'standardMatrix',
      flex: 1,
      editable: hasEdit,
      sortable: false,
      headerName: 'Standard Matrix'
    },
    {
      field: 'dateCreated',
      flex: 1,
      editable: hasEdit,
      sortable: false,
      headerName: 'Date Created'
    },
    {
      field: 'dateExpired',
      flex: 1,
      editable: hasEdit,
      sortable: false,
      headerName: 'Date Expired'
    },
    {
      field: 'type',
      flex: 1,
      editable: hasEdit,
      sortable: false,
      headerName: 'Sample Type'
    },
    {
      field: 'preparation',
      flex: 1,
      editable: hasEdit,
      sortable: false,
      headerName: 'Sample Preparation'
    },
    {
      field: 'storage',
      flex: 1,
      editable: hasEdit,
      sortable: false,
      headerName: 'Sample Storage'
    },
    {
      field: 'lot',
      flex: 1,
      editable: hasEdit,
      sortable: false,
      headerName: 'Sample Lot'
    },
    {
      field: 'notes',
      flex: 1,
      editable: hasEdit,
      sortable: false,
      headerName: 'Notes'
    }
  ];

  // Note: In the future, we want to render a single table and dynamically update columns. However, BootstrapTable is unable to do that so we have to render separate tables for now.
  const renderSamplePropertiesTable = (currentFeatureFlags) => {
    let columns = [];
    if (currentFeatureFlags.includes('nonUs')) {
      columns = [...samplePropertiesTableColumns, ...samplePropertiesTableColumnsNonUsExtend];
    } else {
      columns = [...samplePropertiesTableColumns, ...samplePropertiesTableColumnsExtend];
    }
    return (
      <CustomTable
        numberOfRows={10}
        data={currentSampleProperties}
        header={columns}
        action={actions}
        updateRowData={(data) => {
          setSamplePropertiesToUpdate([...samplePropertiesToUpdate, data]);
        }}
        deleteRowData={(data) => {
          deleteSampleProperty(data.id);
        }}
      />
    );
  };

  const renderOptions = (dropdownOptions) => {
    const rows = [];
    dropdownOptions.forEach((option) => {
      rows.push(
        <option key={uuidv4()} value={option.value}>
          {option.label}
        </option>
      );
    });
    return rows;
  };

  return (
    <div>
      <div className="header">
        <h2>Samples</h2>
      </div>
      <div className="underline-header">
        <hr />
      </div>
      <div className="personnel-information-container">
        <h3>Sample Information</h3>
        <CustomTable
          numberOfRows={10}
          data={currentSamples}
          header={dataEntryTableColumns}
          action={actions}
          updateRowData={(data) => {
            setSamplesToUpdate([...samplesToUpdate, data]);
          }}
          deleteRowData={(data) => {
            deleteSample(data.id);
          }}
        />
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {renderButtonWithPermissions(
              'Add Sample',
              () => setShowModal(true),
              SCREEN.SAMPLE,
              RULES.CREATE,
              permission
            )}
          </div>
          {renderAuthButtonWithPermission(
            handleResults,
            auth,
            currentPersonnel,
            permission,
            SCREEN.SAMPLE,
            RULES.CREATE
          )}
        </div>
        <div>
          {renderButtonWithPermissions(
            'Save data',
            () => saveSamples(),
            SCREEN.SAMPLE,
            RULES.SAVE,
            permission
          )}
        </div>
      </div>
      <Modal size="xl" show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sample</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="manage-data-modal">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={newSample.name}
                onChange={(e) => handleSampleChange('name', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Standard Matrix</Form.Label>
              <Form.Control
                type="text"
                value={newSample.standardMatrix}
                onChange={(e) => handleSampleChange('standardMatrix', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Date Created</Form.Label>
              <Form.Control
                type="text"
                value={newSample.dateCreated}
                onChange={(e) => handleSampleChange('dateCreated', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>Date Expired</Form.Label>
              <Form.Control
                type="text"
                value={newSample.dateExpired}
                onChange={(e) => handleSampleChange('dateExpired', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label>Type</Form.Label>
              <Form.Control
                type="text"
                value={newSample.type}
                onChange={(e) => handleSampleChange('type', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
              <Form.Label>Preparation</Form.Label>
              <Form.Control
                type="text"
                value={newSample.preparation}
                onChange={(e) => handleSampleChange('preparation', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
              <Form.Label>Storage</Form.Label>
              <Form.Control
                type="text"
                value={newSample.storage}
                onChange={(e) => handleSampleChange('storage', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput8">
              <Form.Label>Lot</Form.Label>
              <Form.Control
                type="text"
                value={newSample.lot}
                onChange={(e) => handleSampleChange('lot', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput9">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                value={newSample.notes}
                onChange={(e) => handleSampleChange('notes', e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton text="Cancel" clicked={() => setShowModal(false)} />
          <PrimaryButton text="Save" clicked={() => handleCreateSample()} />
        </Modal.Footer>
      </Modal>
      <div className="personnel-information-container sample-properties">
        <h3>Sample Properties</h3>
        {renderSamplePropertiesTable(featureFlags)}
      </div>
      <div className="data-button-container">
        <div className="left-buttons">
          <div>
            {renderButtonWithPermissions(
              'Add Property',
              () => setShowPropertiesModal(true),
              SCREEN.SAMPLE,
              RULES.CREATE,
              permission
            )}
          </div>
          {renderAuthButtonWithPermission(
            handleResultsSampleProperties,
            auth,
            currentPersonnel,
            permission,
            SCREEN.SAMPLE,
            RULES.CREATE
          )}
        </div>
        <div>
          {renderButtonWithPermissions(
            'Save data',
            () => saveSampleProperties(),
            SCREEN.SAMPLE,
            RULES.SAVE,
            permission
          )}
        </div>
      </div>
      <Modal size="xl" show={showPropertiesModal} onHide={() => setShowPropertiesModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Sample</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="manage-data-modal">
            <Form.Group>
              <Form.Label>Method-Version</Form.Label>
              <Form.Select
                aria-label="Method-Version"
                value={newSampleProperty.methodVersion}
                onChange={(e) => handleSamplePropertyChange('methodVersion', e.target.value)}
              >
                <option>Select method-version</option>
                {renderOptions(currentMethodsDropdown)}
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Related Sample Name</Form.Label>
              <Form.Select
                aria-label="Related Sample Name"
                value={newSampleProperty.relatedSampleName}
                onChange={(e) => handleSamplePropertyChange('relatedSampleName', e.target.value)}
              >
                <option>Select related sample name</option>
                {renderOptions(currentSamplesDropdown)}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Parameter</Form.Label>
              <Form.Control
                type="text"
                value={newSampleProperty.parameter}
                onChange={(e) => handleSamplePropertyChange('parameter', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Procedure</Form.Label>
              <Form.Control
                type="text"
                value={newSampleProperty.procedure}
                onChange={(e) => handleSamplePropertyChange('procedure', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label>Units</Form.Label>
              <Form.Control
                type="text"
                value={newSampleProperty.units}
                onChange={(e) => handleSamplePropertyChange('units', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
              <Form.Label>ARV</Form.Label>
              <Form.Control
                type="text"
                value={newSampleProperty.arv}
                onChange={(e) => handleSamplePropertyChange('arv', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput5">
              <Form.Label>Standard Deviation</Form.Label>
              <Form.Control
                type="text"
                value={newSampleProperty.standardDeviation}
                onChange={(e) => handleSamplePropertyChange('standardDeviation', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput6">
              <Form.Label>n</Form.Label>
              <Form.Control
                type="text"
                value={newSampleProperty.n}
                onChange={(e) => handleSamplePropertyChange('n', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput7">
              <Form.Label>Uncertainty</Form.Label>
              <Form.Control
                type="text"
                value={newSampleProperty.uncertainty}
                onChange={(e) => handleSamplePropertyChange('uncertainty', e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput8">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                value={newSampleProperty.notes}
                onChange={(e) => handleSamplePropertyChange('notes', e.target.value)}
              />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <PrimaryButton text="Cancel" clicked={() => setShowPropertiesModal(false)} />
          <PrimaryButton text="Save" clicked={() => handleCreateSampleProperty()} />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SampleSection;
