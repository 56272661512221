import React, { useEffect, useRef, useState } from 'react';
import SelectPicker from 'rsuite/SelectPicker';

import DatePicker from 'rsuite/DatePicker';
import InputGroup from 'rsuite/InputGroup';
import Button from 'rsuite/Button';
import Nav from 'rsuite/Nav';
import CloudReflashIcon from '@rsuite/icons/CloudReflash';
import FunnelIcon from '@rsuite/icons/Funnel';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import Input from 'rsuite/Input';
import TableProductEdit from './TableProductEdit/TableProductEdit';

import './ILCPProduct.scss';
import usePrograms from './usePrograms';
import useProductData from './useData';
import useLoadData from './useLoadData';
import useCharts from './useCharts';
import useCsv from './useCsv';
import ProductCharts from './ProductCharts';
import ProductReportTable from './ProductReportTable';
import { requireAuth } from '../../../util/auth';
import { getFacilityLabelByValue } from '../../../util/util';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useAuth } from '../../../util/auth.js';
import { ROLES } from '../../../util/Constant.js';

const ILCPProduct = () => {
  const { personalSession } = useAuth();
  const [
    loadingPrograms,
    reloadPrograms,
    dataSets,
    getSubstances,
    getProgramId,
    getSheetId,
    setProgram,
    facilities,
    selectedFacility,
    setSelectedFacility,
    programs,
    newPrograms,
    locations
  ] = usePrograms();
  const [
    loadingdata,
    tableData,
    refreshData,
    getOwners,
    getProviders,
    setValues,
    updateProductComp
  ] = useProductData();
  const [selectedDataSet, setSelectedDataSet] = useState('');
  const [selectedSubstance, setSelectedSubstance] = useState('');
  const [owner, setOwner] = useState('any');
  const [provider, setProvider] = useState('any');
  const [minDate, setMinDate] = useState(new Date(2015, 0, 1));
  const [maxDate, setMaxDate] = useState(new Date(2025, 0, 1));
  const [uniqueID, setUniqueID] = useState('');
  const [batchID, setBatchID] = useState('');
  const { loading: csvLoading, setCsvResult, completed: csvCompleted } = useCsv(programs);
  const [allowRun, setAllowRun] = useState(true);
  const [actions, setActions] = useState(['none']);

  useEffect(() => {
    setProgram(selectedDataSet);
  }, [selectedDataSet]);

  useEffect(() => {
    if (personalSession) {
      if (personalSession.permission === ROLES.CONTRIBUTION) {
        setActions(['add-new']);
      } else if (personalSession.permission === ROLES.ADMIN) {
        setActions(['edit']);
      }
    }
  }, [personalSession]);

  const {
    body,
    chartData,
    gsheetCallbackStdCmp,
    headers,
    updateChartData,
    loading: loadingChartData
  } = useLoadData();

  const { fromData, iChartAllDataStdCmp, mrChartAllData, scChartAllData } = useCharts();

  const [value, setValue] = useState([]);
  const [active, setActive] = useState('Tables');

  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    fromData(chartData);
  }, [chartData]);

  const filterData = tableData
    .filter((item) => !selectedFacility || item.facility === selectedFacility)
    .filter((item) => owner === 'any' || item.owner === owner)
    .filter((item) => provider === 'any' || item.provider === provider)
    .filter((item) => !uniqueID || item.uniqueID === uniqueID)
    .filter((item) => !batchID || item.batchID === batchID)
    .filter((item) => !minDate || new Date(item.date) >= minDate)
    .filter((item) => !maxDate || new Date(item.date) <= maxDate);

  useEffect(() => {
    if (csvCompleted) {
      reloadPrograms();
    }
  }, [csvCompleted]);

  useEffect(() => {
    window.gsheetCallbackStdCmp = gsheetCallbackStdCmp;

    return () => {
      delete window.gsheetCallbackStdCmp;
    };
  }, []);

  function callUpdateCharts() {
    if (filterData?.length > 0) {
      const filters = {
        minDate,
        maxDate,
        testSubstanceParameter: selectedSubstance,
        owner,
        provider,
        batchID,
        uniqueID,
        testSubstance: { parameter: selectedSubstance }
      };

      updateChartData({
        viewableData: filterData,
        sheetId: getSheetId(selectedDataSet),
        facilityId: filterData?.[0]?.facility,
        filters
      });
    }
  }

  const addRowProductComp = () => {
    const editDate = moment().format('MM/DD/yyyy');
    const date = moment().toISOString();
    const formattedDate = moment().format('MM-DD-yyyy');
    const quarter = Math.floor((moment().month() + 3) / 3);
    const label = `${moment().year()}Q${quarter}`;
    const programId = getProgramId(selectedDataSet);
    const newProductComp = {
      isNew: true,
      id: uuidv4(),
      batchID: '',
      high: '',
      programId,
      omit: '',
      label,
      low: '',
      sampleID: '',
      provided: '',
      note: '',
      facility: selectedFacility,
      editDate,
      value: '',
      equipment: '',
      provider: '',
      analyst: '',
      owner: '',
      method: selectedSubstance,
      date,
      formattedDate
    };
    setValues([newProductComp, ...tableData]);
  };

  const saveProductComp = async () => {
    const dataUpdate = tableData
      .filter((item) => item.isUpdate)
      .map((value) => {
        delete value.isUpdate;
        if (value.isNew) {
          delete value.isNew;
          delete value.id;
        }
        return value;
      });
    await updateProductComp(dataUpdate);
    setAllowRun(true);
  };

  const updateRowData = (data) => {
    Object.keys(data).forEach((k) => {
      if (data[k] && typeof data[k] === 'string') {
        data[k] = data[k]?.trim();
      }
    });
    const checkExist = tableData.findIndex((item) => item.id === data.id);
    if (checkExist !== -1) {
      tableData[checkExist] = { ...data, isUpdate: true };
    }
  };

  useEffect(() => {
    if (allowRun && tableData.length > 0) {
      callUpdateCharts();
      setAllowRun(false);
    }
  }, [tableData, allowRun]);

  async function handleFilter() {
    const programId = getProgramId(selectedDataSet);
    setAllowRun(true);
    await refreshData({
      programId,
      method: selectedSubstance
    });
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setValue(file);
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const csvData = e.target.result;

        const rows = csvData.split('\n');
        const data = [];

        for (let i = 0; i < rows.length; i += 1) {
          const row = rows[i].split(',');
          data.push(row);
        }
        setCsvResult(data);
      };

      reader.readAsText(file);
      setValue(null);
    }
  };

  const printReport = () => {
    const data = {
      body,
      headers,
      iChartAllDataStdCmp,
      mrChartAllData,
      scChartAllData,
      minDate,
      maxDate,
      owner,
      product: selectedDataSet,
      method: selectedSubstance,
      facility: filterData?.[0]?.facility
    };

    // Store the data in local storage
    localStorage.setItem('data', JSON.stringify(data));

    // Open the "data-page" in a new tab
    window.open('/stdcmp/report', '_blank');
  };

  return (
    <div
      style={{
        maxWidth: '80vw',
        margin: 'auto',
        marginBottom: '1rem'
      }}
    >
      <div className="contentExternal">
        <div>
          <label className="title">Product Comp</label>
        </div>
        <div className="contentFilter">
          <div className="contentSelectView">
            <label>Facility</label>
            <SelectPicker
              className="select"
              data={facilities
                .filter((item) => item !== undefined)
                .map((f) => ({ label: getFacilityLabelByValue(locations, f), value: f }))}
              loading={loadingPrograms}
              value={selectedFacility}
              onChange={(valueChanged) => setSelectedFacility(valueChanged)}
              onClean={() => setSelectedFacility('')}
            />
          </div>
          <div className="contentSelectView">
            <label>Product</label>
            <SelectPicker
              className="select"
              data={dataSets}
              loading={loadingPrograms}
              value={selectedDataSet}
              onChange={(valueChanged) => setSelectedDataSet(valueChanged)}
              onClean={() => setSelectedDataSet('')}
            />
          </div>

          <div className="contentSelectView">
            <label>Method/Parameter</label>
            <SelectPicker
              className="select"
              data={getSubstances(selectedDataSet)}
              loading={loadingPrograms}
              value={selectedSubstance}
              onChange={(valueChanged) => setSelectedSubstance(valueChanged)}
              onClean={() => setSelectedSubstance('')}
            />
          </div>
          <div>
            <Button
              className="btnFilter"
              appearance="primary"
              startIcon={<FunnelIcon />}
              onClick={() => {
                handleFilter();
              }}
            >
              Apply filter
            </Button>
          </div>
        </div>
      </div>
      {personalSession?.permission !== ROLES.VIEW && (
        <div className="contentFile">
          <label className="labelFile">{value?.name ?? 'No file chosen yet'}</label>
          <div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileUpload}
              disabled={csvLoading}
            />
            <Button
              color="blue"
              className="btnFile"
              appearance="ghost"
              onClick={handleClick}
              startIcon={<CloudReflashIcon />}
              loading={csvLoading}
            >
              Choose a file
            </Button>
            <Button
              className="btnFile"
              appearance="primary"
              disabled={!filterData?.length}
              onClick={() => addRowProductComp()}
            >
              Add Row
            </Button>
            <Button
              className="btnFile"
              appearance="primary"
              disabled={!filterData?.length}
              onClick={() => {
                saveProductComp();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
      <TableProductEdit
        tableData={filterData}
        loading={loadingdata}
        locations={locations}
        updateRowData={updateRowData}
        actions={actions}
      />
      <div className="contentFilterG">
        <div>
          <label className="title">Filters</label>
        </div>
        <div
          style={{
            display: 'flex',
            gap: '1rem'
          }}
        >
          <Button
            className="btnPrint"
            appearance="primary"
            startIcon={<FunnelIcon />}
            disabled={!filterData?.length || loadingChartData}
            loading={loadingChartData}
            onClick={printReport}
          >
            Print Report
          </Button>
          <Button
            className="btnPrint"
            appearance="primary"
            startIcon={<FunnelIcon />}
            disabled={!filterData?.length || loadingChartData}
            loading={loadingChartData}
            onClick={() => {
              callUpdateCharts();
            }}
          >
            Run
          </Button>
        </div>
      </div>
      <div className="contentSelectFilterG">
        <div className="contentSelectView">
          <label>Owner</label>
          <SelectPicker
            value={owner}
            onSelect={(v) => setOwner(v)}
            className="select"
            data={getOwners()}
            onClean={() => setProvider('any')}
          />
        </div>
        <div className="contentSelectView">
          <label>Provider</label>
          <SelectPicker
            className="select"
            data={getProviders()}
            value={provider}
            onSelect={(v) => setProvider(v)}
            onClean={() => setProvider('any')}
          />
        </div>
        <div className="contentSelectView">
          <label>Bat/Vessel ID</label>
          <Input
            className="select"
            placeholder="Bat/Vessel ID"
            value={batchID}
            onChange={(e) => setBatchID(e)}
          />
        </div>
        <div className="contentSelectView">
          <label>Unique ID</label>
          <Input
            className="select"
            placeholder="Unique ID"
            value={uniqueID}
            onChange={(e) => setUniqueID(e)}
          />
        </div>
        <div className="contentSelectView">
          <label>Date Range</label>
          <InputGroup
            style={{
              width: '100%',
              height: 44
            }}
          >
            <DatePicker
              format="yyyy-MM-dd"
              block
              appearance="subtle"
              style={{
                height: 44,
                width: '50%'
              }}
              value={minDate}
              onChange={(e) => setMinDate(e)}
            />
            <DatePicker
              format="yyyy-MM-dd"
              block
              appearance="subtle"
              style={{
                height: 44,
                width: '50%'
              }}
              value={maxDate}
              onChange={(e) => setMaxDate(e)}
            />
          </InputGroup>
        </div>
      </div>
      <div className="contentParamtG">
        <div>
          <label className="title">Parameters</label>
        </div>
      </div>
      <div className="contentDonwload">
        <div className="contentAllSelect">
          <div className="contentSelectView">
            <label>EWMA Lambda</label>
            <SelectPicker
              className="select"
              value="0.40"
              data={[
                {
                  label: '0.40',
                  value: '0.40'
                }
              ]}
            />
          </div>
        </div>
        <div>
          <a
            href={`https://spreadsheets.google.com/feeds/download/spreadsheets/Export?key=${getSheetId(
              selectedDataSet
            )}&exportFormat=xlsx`}
            download
            disabled={!getSheetId(selectedDataSet)}
          >
            <Button
              className="btnDownload"
              appearance="primary"
              disabled={!getSheetId(selectedDataSet)}
              startIcon={<FileDownloadIcon />}
            >
              Download XLSX
            </Button>
          </a>
        </div>
      </div>

      <div className="contentNav">
        <Nav
          appearance="subtle"
          activeKey={active}
          onSelect={setActive}
          style={{ marginBottom: 50 }}
        >
          <Nav.Item eventKey="Tables">Tables</Nav.Item>
          <Nav.Item eventKey="Plots">Plots</Nav.Item>
        </Nav>
      </div>

      <div className={`${active === 'Tables' ? 'contentTablesType' : 'tabHidden'}`}>
        <ProductReportTable
          body={body}
          loadingChartData={loadingChartData}
          headers={headers}
          title="Table 1: Comparison and plotting position calculations"
        />
      </div>

      <div className={`${active === 'Plots' ? 'contentPlotsType' : 'tabHidden'}`}>
        <ProductCharts
          loadingChartData={loadingChartData}
          scChartAllData={scChartAllData}
          mrChartAllData={mrChartAllData}
          iChartAllDataStdCmp={iChartAllDataStdCmp}
        />
      </div>
    </div>
  );
};

export default requireAuth(ILCPProduct);
