import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Meta from '../components/Meta';
import { requireAuth } from '../util/auth';
import DataEntrySection from '../components/DataEntrySection/DataEntrySection';

function DataEntryPage() {
  return (
    <>
      <Meta title="Chart" />
      <Tabs defaultActiveKey="dataEntry" id="uncontrolled-tab-example" className="tri-tabs">
        <Tab eventKey="overview" title="Overview" />
        <Tab eventKey="comparison" title="Comparison" />
        <Tab eventKey="configuration" title="Configuration" />
        <Tab eventKey="dataEntry" title="Data Entry">
          <DataEntrySection />
        </Tab>
      </Tabs>
    </>
  );
}

export default requireAuth(DataEntryPage);
