import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Section from './Section';
import SectionHeader from './SectionHeader';

function HeroSection2({ bg, textColor, size, bgImage, bgImageOpacity, title, subtitle }) {
  return (
    <Section
      bg={bg}
      textColor={textColor}
      size={size}
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      <Container>
        <SectionHeader title={title} subtitle={subtitle} size={1} spaced className="text-center" />
      </Container>
    </Section>
  );
}

HeroSection2.propTypes = {
  bg: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageOpacity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default HeroSection2;
