import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../util/router';
import { requireAuth } from '../util/auth';
import './AuthFooter.scss';

function AuthFooter({
  type,
  signinPath,
  signinAction,
  signinText,
  signupPath,
  signupAction,
  forgotPassPath,
  forgotPassAction,
  termsPath,
  privacyPolicyPath,
  showAgreement
}) {
  return (
    <div className="AuthFooter text-center text-secondary mt-3 px-2">
      {type === 'signup' && (
        <>
          {showAgreement && (
            <div className="mb-4">
              By signing up, you are agreeing to our <Link to={termsPath}>Terms of Service</Link>{' '}
              and <Link to={privacyPolicyPath}>Privacy Policy</Link>.
            </div>
          )}

          {signinText}
          <Link to={signinPath} className="ml-2">
            {signinAction}
          </Link>
        </>
      )}

      {type === 'signin' && (
        <div className="create-account-container">
          {requireAuth(<CreateAccountLink signupPath={signupPath} signupAction={signupAction} />)}

          {forgotPassAction && (
            <Link to={forgotPassPath} className="ml-3">
              {forgotPassAction}
            </Link>
          )}
        </div>
      )}

      {type === 'forgotpass' && (
        <>
          {signinText}
          <Link to={signinPath} className="ml-2">
            {signinAction}
          </Link>
        </>
      )}
    </div>
  );
}

AuthFooter.propTypes = {
  type: PropTypes.oneOf(['signup', 'signin', 'forgotpass']).isRequired,
  signinPath: PropTypes.string.isRequired,
  signinAction: PropTypes.string.isRequired,
  signinText: PropTypes.string.isRequired,
  signupPath: PropTypes.string.isRequired,
  signupAction: PropTypes.string.isRequired,
  forgotPassPath: PropTypes.string.isRequired,
  forgotPassAction: PropTypes.string.isRequired,
  termsPath: PropTypes.string.isRequired,
  privacyPolicyPath: PropTypes.string.isRequired,
  showAgreement: PropTypes.bool.isRequired
};

function CreateAccountLink(signupPath, signupAction) {
  return <Link to={signupPath}>{signupAction}</Link>;
}

export default AuthFooter;
