import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButton from 'react-bootstrap/ToggleButton';
import CustomTable from '../Table/CustomTable';
import Form from 'react-bootstrap/Form';
import ButtonLoading from '../Common/ui/ButtonLoading';

const ChartAssessment = ({
  showModal,
  setShowModal,
  currentLogo,
  resultsDataTableData,
  currentParameter,
  unit,
  summaryStatisticsAssessmentData,
  summaryStatisticsAssessmentColumns,
  modalAssessment,
  handleAssessmentChange,
  saveChartAssessment,
  currentMethod,
  currentInstrument
}) => {
  return (
    <>
      <Modal
        size="xl"
        show={showModal}
        onHide={() => setShowModal(false)}
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Chart Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className="col-center">
              <img alt="logo" src={currentLogo} width="200" />
            </Col>
            <Col className="col-center">
              <h3>ASTM SQC Insights</h3>
            </Col>
          </Row>
          <Row className="report-header">
            <h4>Initial Assessment Report</h4>
          </Row>
          <Row className="ia-table">
            <Col className="col-center">Method:</Col>
            <Col className="col-center">{currentMethod}</Col>
            <Col className="col-center">Date:</Col>
            <Col className="col-center">
              {resultsDataTableData?.length > 0 ? resultsDataTableData[0].date : ''}
            </Col>
          </Row>
          <Row className="ia-table">
            <Col className="col-center">Instrument:</Col>
            <Col className="col-center">{currentInstrument}</Col>
            <Col className="col-center">Assessment by:</Col>
            <Col className="col-center">{modalAssessment?.createdBy}</Col>
          </Row>
          <Row className="report-header">
            <h4>
              Summary Statistics: {currentParameter}, {unit}
            </h4>
          </Row>
          <Row className="statistics-table">
            <CustomTable
              hideFooter={true}
              numberOfRows={50}
              data={summaryStatisticsAssessmentData}
              header={summaryStatisticsAssessmentColumns}
            />
          </Row>
          <Row className="report-header">
            <h4>Assessment</h4>
          </Row>
          <Row className="ia-table">
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                1. Is any trending in an upward or downward direction evident?
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioTrending-pass"
                id="radioTrending-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                value="Pass"
                style={
                  modalAssessment?.trendingDirectionEvident === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                checked={modalAssessment?.trendingDirectionEvident === 'Pass'}
                onChange={(e) => handleAssessmentChange(e, 'trendingDirectionEvident')}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioTrending-fail"
                id="radioTrending-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                value="Fail"
                style={
                  modalAssessment?.trendingDirectionEvident === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                checked={modalAssessment?.trendingDirectionEvident === 'Fail'}
                onChange={(e) => handleAssessmentChange(e, 'trendingDirectionEvident')}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                2. Is any clustering evident?
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioClustering-pass"
                id="radioClustering-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.clusteringEvident === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment?.clusteringEvident === 'Pass'}
                onChange={(e) => handleAssessmentChange(e, 'clusteringEvident')}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioClustering-fail"
                id="radioClustering-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.clusteringEvident === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment?.clusteringEvident === 'Fail'}
                onChange={(e) => handleAssessmentChange(e, 'clusteringEvident')}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                3. Are any cyclical patterns evident?
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioCyclical-pass"
                id="radioCyclical-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.cyclicalPatterns === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment?.cyclicalPatterns === 'Pass'}
                onChange={(e) => handleAssessmentChange(e, 'cyclicalPatterns')}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioCyclical-fail"
                id="radioCyclical-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.cyclicalPatterns === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment?.cyclicalPatterns === 'Fail'}
                onChange={(e) => handleAssessmentChange(e, 'cyclicalPatterns')}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                4. Is the data normal or approximately normal in distribution?
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioDataNormal-pass"
                id="radioDataNormal-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.normalDistribution === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment?.normalDistribution === 'Pass'}
                onChange={(e) => handleAssessmentChange(e, 'normalDistribution')}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioDataNormal-fail"
                id="radioDataNormal-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.normalDistribution === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment?.normalDistribution === 'Fail'}
                onChange={(e) => handleAssessmentChange(e, 'normalDistribution')}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                5. TPI Assessment
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioTpi-pass"
                id="radioTpi-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.tpiAssessment === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment?.tpiAssessment === 'Pass'}
                onChange={(e) => handleAssessmentChange(e, 'tpiAssessment')}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioTpi-fail"
                id="radioTpi-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.tpiAssessment === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment?.tpiAssessment === 'Fail'}
                onChange={(e) => handleAssessmentChange(e, 'tpiAssessment')}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                6. Assessor comments
              </h6>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '50px' }} lg={8}>
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    className="comments"
                    as="textarea"
                    rows={2}
                    onChange={(e) => handleAssessmentChange(e, 'assessorComments')}
                    aria-label="Basic example"
                    value={modalAssessment?.assessorComments}
                  />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col lg={7}>
              <h6 className="ia-table" style={{ marginLeft: '50px' }}>
                7. Assessment outcome
              </h6>
            </Col>
            <Col>
              <ToggleButton
                key="radioOutcome-pass"
                id="radioOutcome-pass"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.outcome === 'Pass'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Pass"
                checked={modalAssessment?.outcome === 'Pass'}
                onChange={(e) => handleAssessmentChange(e, 'outcome')}
              >
                Pass
              </ToggleButton>
              <ToggleButton
                key="radioOutcome-fail"
                id="radioOutcome-fail"
                type="radio"
                variant="outline-primary"
                name="radio"
                style={
                  modalAssessment?.outcome === 'Fail'
                    ? { backgroundColor: 'navy', color: 'white' }
                    : {}
                }
                value="Fail"
                checked={modalAssessment?.outcome === 'Fail'}
                onChange={(e) => handleAssessmentChange(e, 'outcome')}
              >
                Fail
              </ToggleButton>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonLoading text="Cancel" onClick={() => setShowModal(false)} />
          <ButtonLoading
            text="Save"
            onClick={() => {
              saveChartAssessment();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChartAssessment;
