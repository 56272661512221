import React from 'react';
import Tag from 'rsuite/Tag';
import PropTypes from 'prop-types';

const CalculationDetail = ({ calculation }) => {
  const tagStyle = { margin: 0, fontSize: '1rem', color: 'black' };

  const cyanStyle = { ...tagStyle, backgroundColor: '#97eeff' };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gap: '1rem'
      }}
    >
      <Tag style={cyanStyle}>Paremeter</Tag>
      <Tag style={tagStyle}>Value</Tag>
      <Tag style={cyanStyle}>Round</Tag>
      <Tag style={tagStyle}>{calculation.round}</Tag>
      <Tag style={cyanStyle}>Value</Tag>
      <Tag style={tagStyle}>{calculation.value}</Tag>
      <Tag style={cyanStyle}>Mean</Tag>
      <Tag style={tagStyle}>{calculation.mean}</Tag>
      <Tag style={cyanStyle}>Standard Deviation</Tag>
      <Tag style={tagStyle}>{calculation.stdev}</Tag>
      <Tag style={cyanStyle}>Pre-treated result</Tag>
      <Tag style={tagStyle}>{calculation.ptresult}</Tag>
      <Tag style={cyanStyle}>1090 Delta</Tag>
      <Tag style={tagStyle}>{calculation.delta1090}</Tag>
      <Tag style={cyanStyle}>1090 Critical value</Tag>
      <Tag style={tagStyle}>{calculation.criticalValue1090}</Tag>
      <Tag style={cyanStyle}>1090 evaluation</Tag>
      <Tag style={tagStyle}>{calculation.check1090}</Tag>
    </div>
  );
};

CalculationDetail.propTypes = {
  calculation: PropTypes.shape({
    round: PropTypes.number,
    value: PropTypes.number,
    mean: PropTypes.number,
    stdev: PropTypes.number,
    ptresult: PropTypes.number,
    delta1090: PropTypes.number,
    criticalValue1090: PropTypes.number,
    check1090: PropTypes.string
  }).isRequired
};

export default CalculationDetail;
