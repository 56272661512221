import React, { useState } from 'react';
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector
} from '@mui/x-data-grid';
import './style.scss';
import { tableStyle } from './Style';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { randomId } from '@mui/x-data-grid-generator';
import { ArticleOutlined, Delete, Edit } from '@mui/icons-material';
import MuiPagination from '@mui/material/Pagination';

function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}

function CustomPagination(props) {
  return <GridPagination ActionsComponent={Pagination} {...props} />;
}

const CustomTable = ({
  header,
  data,
  setData,
  numberOfRows = 50,
  sort,
  updateRowData,
  deleteRowData,
  viewRowData,
  hideFooter = false,
  action = ['none'],
  actionPosition = 'latest',
  hideHeader = false,
  rowHeight = 52,
  loading = false,
  clickToEdit = true,
  onCellClickWhenEditing = () => {},
  sx,
  ...props
}) => {
  const actions = [
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      width: action.length === 2 || action.includes('edit') || action.includes('add-new') ? 80 : 40,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const listAction = [];

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ];
        } else {
          if (action.includes('view')) {
            listAction.push(
              <GridActionsCellItem
                icon={<ArticleOutlined />}
                label="View"
                className="textPrimary"
                onClick={handleViewClick(row)}
                color="inherit"
              />
            );
          }
          if (action.includes('edit')) {
            listAction.push(
              <GridActionsCellItem
                icon={<Edit />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />
            );
          }
          if (action.includes('delete')) {
            listAction.push(
              <GridActionsCellItem
                icon={<Delete />}
                label="Delete"
                className="textPrimary"
                onClick={handleDeleteClick(id, row)}
                color="inherit"
              />
            );
          }
          if (action.includes('add-new') && row.isNew) {
            listAction.push(
              <GridActionsCellItem
                icon={<Edit />}
                label="Edit"
                className="textPrimary"
                onClick={handleEditClick(id)}
                color="inherit"
              />
            );
          }
        }
        return listAction;
      }
    }
  ];
  const columns = action.includes('none')
    ? header
    : actionPosition === 'first'
    ? [...actions, ...header]
    : [...header, ...actions];
  const [rowModesModel, setRowModesModel] = useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id, row) => () => {
    if (deleteRowData) {
      deleteRowData(row);
    }
    if (setData) {
      setData(data.filter((row) => row.id !== id));
    }
  };

  const handleViewClick = (row) => () => {
    if (viewRowData) {
      viewRowData(row);
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    });

    const editedRow = data.find((row) => row.id === id);
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow };
    if (setData) {
      setData(data.map((row) => (row.id === newRow.id ? updatedRow : row)));
    }
    if (updateRowData) {
      updateRowData(updatedRow);
    }
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleOnCellClick = (params, event) => {
    const isInEditMode = rowModesModel[params.row.id]?.mode === GridRowModes.Edit;
    if (isInEditMode) {
      onCellClickWhenEditing(params, event);
    }
  };

  function getRowId(row) {
    if (row.id) {
      return row.id;
    }
    return randomId();
  }

  return (
    <div className="custom-table">
      <DataGrid
        sx={{
          ...tableStyle,
          '.MuiDataGrid-overlayWrapper': {
            height: !data.length ? 100 : 0
          },
          '.MuiDataGrid-overlayWrapperInner': {
            height: !data.length ? '100% !important' : 0
          },
          '.MuiDataGrid-virtualScrollerContent': {
            display: data.length > 0 ? 'block' : 'none'
          },
          ...sx
        }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: numberOfRows
            }
          },
          ...sort
        }}
        pagination
        slots={{
          pagination: CustomPagination
        }}
        autoHeight={true}
        getRowHeight={() => rowHeight}
        getRowId={getRowId}
        columnHeaderHeight={hideHeader ? 0 : 56}
        showCellVerticalBorder={true}
        showColumnVerticalBorder={true}
        pageSizeOptions={[numberOfRows]}
        disableRowSelectionOnClick
        hideFooter={hideFooter}
        disableColumnMenu={true}
        editMode="row"
        loading={loading}
        rowModesModel={rowModesModel}
        onRowModesModelChange={clickToEdit ? handleRowModesModelChange : null}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onCellClick={handleOnCellClick}
        onProcessRowUpdateError={(error) => {
          console.error(error);
        }}
        {...props}
      />
    </div>
  );
};

export default CustomTable;
