import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useAuth, requireAuth } from '../util/auth';
import { useForm } from 'react-hook-form';
import AppTextField from '../components/Common/AppTextField';

function CreateAccountForm({ onAuth, type, onFormAlert, buttonAction }) {
  const auth = useAuth();

  const [pending, setPending] = useState(false);
  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      email: '',
      pass: '',
      confirmPass: ''
    }
  });

  const submitHandlersByType = {
    signin: ({ email, pass }) => {
      return auth.signin(email, pass).then((user) => {
        // Call auth complete handler
        onAuth(user);
      });
    },
    signup: ({ email, pass }) => {
      return auth.signup(email, pass).then((user) => {
        // Call auth complete handler
        onAuth(user);
      });
    }
  };

  // Handle form submission
  const onSubmit = ({ email, pass }) => {
    // Show pending indicator
    setPending(true);

    // Call submit handler for auth type
    submitHandlersByType[type]({
      email,
      pass
    }).catch((error) => {
      setPending(false);
      // Show error alert message
      onFormAlert({
        type: 'error',
        message: error.message
      });
    });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {['signin', 'signup'].includes(type) && (
        <Form.Group style={{ marginBottom: '8px' }} controlId="formEmail">
          <AppTextField
            name="email"
            control={control}
            rules={{ required: 'Please enter an email' }}
            placeholder="Email"
            type="email"
          />
        </Form.Group>
      )}

      {['signin', 'signup'].includes(type) && (
        <Form.Group style={{ marginBottom: '8px' }} controlId="formPassword">
          <AppTextField
            name="pass"
            control={control}
            rules={{ required: 'Please enter a password' }}
            placeholder="Password"
            type="password"
          />
        </Form.Group>
      )}

      {['signup', 'changepass'].includes(type) && (
        <Form.Group controlId="formConfirmPass">
          <AppTextField
            name="confirmPass"
            control={control}
            rules={{
              required: 'Please enter your password again',
              validate: (value) => {
                const { pass } = getValues();
                return pass === value || 'Confirm password does not match';
              }
            }}
            placeholder="Confirm Password"
            type="password"
          />
        </Form.Group>
      )}
      <div className="signin-button-container">
        <Button variant="primary" block size="lg" type="submit" disabled={pending}>
          {!pending && <span>{buttonAction}</span>}

          {pending && (
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden
              className="align-baseline"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
        </Button>
      </div>
    </Form>
  );
}

CreateAccountForm.propTypes = {
  onAuth: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['signin']).isRequired,
  onFormAlert: PropTypes.func.isRequired,
  buttonAction: PropTypes.string.isRequired
};

export default requireAuth(CreateAccountForm);
