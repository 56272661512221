import React from 'react';
import PropTypes from 'prop-types';
import './LongContent.scss';

function LongContent({ children }) {
  return <div className="LongContent">{children}</div>;
}

LongContent.propTypes = {
  children: PropTypes.node
};

LongContent.defaultProps = {
  children: null
};

export default LongContent;
