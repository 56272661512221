import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import newsletter from '../util/newsletter';

function Newsletter({ onSubscribed, subscribedMessage }) {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit } = useForm();

  const onSubmit = ({ email }) => {
    setSubscribed(true);
    // Parent component can optionally
    // find out when subscribed.
    if (onSubscribed) {
      onSubscribed();
    }
    // Subscribe them
    newsletter.subscribe({ email });
  };

  return (
    <>
      {subscribed === false && <Form onSubmit={handleSubmit(onSubmit)}>{}</Form>}

      {subscribed === true && <div>{subscribedMessage}</div>}
    </>
  );
}

Newsletter.propTypes = {
  onSubscribed: PropTypes.func,
  subscribedMessage: PropTypes.string
};

Newsletter.defaultProps = {
  onSubscribed: () => {},
  subscribedMessage: 'Thanks for subscribing!'
};

export default Newsletter;
