import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import { useCSVReader } from 'react-papaparse';
import { Spinner } from 'react-bootstrap';

const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center'
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex'
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10
  },
  zoneHover: {
    borderColor: GREY_DIM
  },
  default: {
    borderColor: GREY
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23
  }
};

export default function CSVReaderComponent({ handleResults, loading = false }) {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);

  return (
    <CSVReader
      config={{ encoding: 'ISO-8859-1' }}
      onUploadAccepted={(results) => {
        handleResults(results.data);
        setZoneHover(false);
      }}
      onDragOver={(event) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setZoneHover(false);
      }}
      noDrag
    >
      {({ getRootProps }) => (
        <>
          <div {...getRootProps()} style={{ ...styles.zone, ...(zoneHover && styles.zoneHover) }}>
            <Button>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                ></Spinner>
              ) : (
                <span>Add From File</span>
              )}
            </Button>
          </div>
        </>
      )}
    </CSVReader>
  );
}

CSVReaderComponent.propTypes = {
  handleResults: PropTypes.func.isRequired,
  loading: PropTypes.bool
};
