import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Section from './Section';
import SectionHeader from './SectionHeader';
import Contact from './Contact';

function ContactSection({
  bg,
  textColor,
  size,
  bgImage,
  bgImageOpacity,
  title,
  subtitle,
  showNameField,
  buttonText,
  buttonColor
}) {
  return (
    <Section
      bg={bg}
      textColor={textColor}
      size={size}
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      <Container
        style={{
          maxWidth: '850px'
        }}
      >
        <SectionHeader title={title} subtitle={subtitle} size={2} spaced className="text-center" />
        <Contact showNameField={showNameField} buttonText={buttonText} buttonColor={buttonColor} />
      </Container>
    </Section>
  );
}

ContactSection.propTypes = {
  bg: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageOpacity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  showNameField: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default ContactSection;
