import { ROLES } from '../../util/Constant';
import { checkHasPermission } from '../../util/util';

export const hasPermission = (permissionData, table, rule) => {
  const isAdmin = permissionData && permissionData.permissionType === ROLES.ADMIN;
  const hasPerMission =
    permissionData && permissionData[table] && checkHasPermission(permissionData[table], rule);

  return isAdmin || hasPerMission;
};
