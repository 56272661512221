import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import CustomTable from '../../Table/CustomTable';
import { settingDocumentColumns } from './Columns';
import {
  deleteInstrumentDocuments,
  setInstrumentDocuments,
  setInstrumentEquipments,
  useGetPersonnel,
  useInstrumentDocuments,
  useInstrumentEquipments,
  useInstrumentSettings
} from '../../../util/db';
import { SETTING_TYPE } from './DefineCategories';
import { Button } from '@mui/material';
import ModalFormDocument from '../Modal/ModalFormDocument';
import { uploadFileAsync } from '../../../util/storage';
import { alphaNumericSorter, renderMUIButtonWithPermissions } from '../../../util/util';
import { useAuth } from '../../../util/auth';
import { hasPermission } from '../utils';
import { RULES, SCREEN } from '../../../util/Constant';

const DocumentList = () => {
  const auth = useAuth();
  const [filterModel, setFilterModel] = useState({});
  const { data: instruments = [] } = useInstrumentEquipments();
  const { data: documents = [] } = useInstrumentDocuments(filterModel);
  const { data: instrumentSettingsDocumentType = [] } = useInstrumentSettings(
    SETTING_TYPE.DOCUMENT_TYPE
  );
  const { data: personnel = [] } = useGetPersonnel();
  const { data: allLocations = [] } = useInstrumentSettings(SETTING_TYPE.INSTRUMENT_LOCATION);
  const [editingDocument, setEditingDocument] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [locationFilter, setLocationFilter] = useState();
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  const showFormDocument = (data) => {
    setEditingDocument(data);
    setOpen(true);
  };

  const handleChange = (field, event) => {
    if (event.target.value) {
      setFilterModel({
        ...filterModel,
        [field]: event.target.value
      });
    } else {
      setFilterModel({
        ...filterModel,
        [field]: ''
      });
    }
  };

  const handleDelete = async (data) => {
    if (confirm('Are you sure to delete this document?')) {
      await deleteInstrumentDocuments(data);
    }
  };

  const handleSave = async (data) => {
    const { attachment, ...document } = data;

    await setInstrumentDocuments(document);

    if (attachment && attachment.length > 0) {
      document.attachment = await uploadFileAsync(
        attachment[0],
        `instrument-management/${document.instrumentId}/${document.id}/${attachment[0].name}`,
        document
      );
    }
    await setInstrumentDocuments(document);

    setOpen(false);
  };

  return (
    <>
      {hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.VIEW) ? (
        <>
          <div className="filter">
            <p>
              <strong>Filter documents by:</strong>
            </p>
            <div>
              <Row>
                <Form.Group as={Col} controlId="location">
                  <Form.Label>Location</Form.Label>
                  <Form.Select onChange={(event) => setLocationFilter(event.target.value)}>
                    <option value="">All locations</option>
                    {allLocations.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="documentType">
                  <Form.Label>Document Type</Form.Label>
                  <Form.Select onChange={(event) => handleChange('documentType', event)}>
                    <option value="">All types</option>
                    {instrumentSettingsDocumentType.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} controlId="instrument">
                  <Form.Label>Instrument</Form.Label>
                  <Form.Select onChange={(event) => handleChange('instrumentId', event)}>
                    <option value="">All Instruments</option>
                    {instruments.sort(alphaNumericSorter).map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Row>
            </div>
          </div>
          <div className="grid-view">
            <div className="inner-actions">
              <h2>Documents</h2>
              {renderMUIButtonWithPermissions(
                'Add new',
                () => showFormDocument(null),
                SCREEN.INSTRUMENT_SETTINGS,
                RULES.CREATE,
                permissionData
              )}
              <ModalFormDocument
                show={isOpen}
                handleClose={() => setOpen(false)}
                handleSave={handleSave}
                data={editingDocument}
                instrumentSettingsDocumentType={instrumentSettingsDocumentType}
                instruments={instruments}
                disabled={!hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.UPDATE)}
              />
            </div>
            <CustomTable
              numberOfRows={20}
              data={documents
                .map((row) => {
                  const dataInstrument = instruments.find((item) => item.id === row.instrumentId);
                  const location = allLocations.find(
                    (item) => item.id === dataInstrument?.location
                  );
                  return {
                    ...row,
                    location: location?.name,
                    instrumentName: dataInstrument?.name
                  };
                })
                .filter((d) => {
                  if (!locationFilter) {
                    return true;
                  } else {
                    const instrumentIds = instruments
                      .filter((i) => i.location === locationFilter)
                      .map((i) => i.id);
                    return instrumentIds.includes(d.instrumentId);
                  }
                })}
              header={settingDocumentColumns({
                documentTypes: instrumentSettingsDocumentType,
                actionEditDocument: showFormDocument
              })}
              deleteRowData={handleDelete}
              action={
                hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.DELETE)
                  ? ['delete']
                  : ['none']
              }
              sort={{ sorting: { sortModel: [{ field: 'documentName', sort: 'asc' }] } }}
              rowHeight="auto"
              sx={{
                '.MuiDataGrid-cell': {
                  minHeight: '52px !important'
                },
                '& .MuiDataGrid-iconButtonContainer[aria-label*="filter"]': {
                  display: 'none'
                }
              }}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default DocumentList;
