import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalConfirm = ({ show, setShow, onAccept, primary }) => {
  const handleClose = () => setShow(false);
  const handleAccept = () => {
    setShow(false);
    onAccept(false);
  };

  return (
    <>
      <Modal size="md" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>{/* <Modal.Title>Combine Z Score</Modal.Title> */}</Modal.Header>

        <Modal.Body>
          <p>
            Are you sure you want to change the parameters? This action will affect to combined
            Z-score.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleAccept}>
            {primary}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalConfirm;
