import React from 'react';
import Tag from 'rsuite/Tag';
import ProductCharts from './ProductCharts';
import ProductReportTable from './ProductReportTable';

const getData = () => {
  const storedData = localStorage.getItem('data');
  return storedData ? JSON.parse(storedData) : {};
};

const formatDate = (date) => {
  if (!date) return '';

  return new Date(date).toDateString();
};

const ProductReport = () => {
  const data = getData();

  const {
    body,
    headers,
    iChartAllDataStdCmp,
    mrChartAllData,
    scChartAllData,
    minDate,
    maxDate,
    owner,
    product,
    method,
    facility
  } = data;

  const chartData = iChartAllDataStdCmp[1] ?? [];

  const average = chartData[3];
  const ucl = chartData[4];
  const lcl = chartData[5];

  const reportHeaders = [
    {
      index: 0,
      name: 'Report Date',
      value: new Date().toDateString()
    },
    {
      index: 1,
      name: 'Date range (low)',
      value: formatDate(minDate)
    },
    {
      index: 2,
      name: 'Facility Location',
      value: facility
    },
    {
      index: 3,
      name: 'Date range (high)',
      value: formatDate(maxDate)
    },
    {
      index: 4,
      name: 'Product / Grade',
      value: product
    },
    {
      index: 5,
      name: 'Average',
      value: average
    },
    {
      index: 6,
      name: 'Method / Parameter',
      value: method
    },
    {
      index: 7,
      name: 'LCL',
      value: lcl
    },
    {
      index: 8,
      name: 'Owner',
      value: owner
    },
    {
      index: 9,
      name: 'UCL',
      value: ucl
    }
  ];

  return (
    <div style={{ width: 1280 }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2,1fr)',
          width: '90%',
          margin: 'auto',
          gap: '.5rem'
        }}
      >
        <img src="/fuelsqc-report.png" alt="Fuel SQC Report Logo" />
        <h2 style={{ alignSelf: 'end' }}>FuelSQC Standard Comparison Report</h2>
        {reportHeaders.map((x) => {
          return (
            <div
              key={x.index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '0.5rem 0'
              }}
            >
              <Tag style={{ width: '30%' }} color="blue">
                {x.name}
              </Tag>
              <Tag style={{ width: '70%' }}> {x.value}</Tag>
            </div>
          );
        })}
      </div>

      <ProductCharts
        loadingChartData={false}
        scChartAllData={scChartAllData ?? []}
        mrChartAllData={mrChartAllData ?? []}
        iChartAllDataStdCmp={iChartAllDataStdCmp ?? []}
      />
      <div
        style={{
          maxWidth: '90%',
          margin: '2rem auto'
        }}
      >
        <ProductReportTable
          body={body ?? []}
          loadingChartData={false}
          headers={headers ?? []}
          title="Data and Plotting Position Calculations"
        />
      </div>
    </div>
  );
};

export default ProductReport;
