import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from './Section';
import SectionHeader from './SectionHeader';
import Newsletter from './Newsletter';

function NewsletterSection({
  bg,
  textColor,
  size,
  bgImage,
  bgImageOpacity,
  title,
  subtitle,
  buttonColor,
  buttonText,
  inputPlaceholder,
  subscribedMessage
}) {
  return (
    <Section
      bg={bg}
      textColor={textColor}
      size={size}
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} lg={6}>
            <SectionHeader title={title} subtitle={subtitle} size={2} spaced={false} />
          </Col>
          <Col xs={12} lg={6} className="mt-4 mt-lg-0">
            <Newsletter
              buttonText={buttonText}
              buttonColor={buttonColor}
              inputPlaceholder={inputPlaceholder}
              subscribedMessage={subscribedMessage}
            />
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

NewsletterSection.propTypes = {
  bg: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  inputPlaceholder: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  subscribedMessage: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageOpacity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default NewsletterSection;
