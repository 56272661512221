import React from 'react';
import PropTypes from 'prop-types';

import './TableProductEdit.scss';
import { getFacilityLabelByValue, dateTimeComparator } from '../../../../util/util';
import CustomTable from '../../../Table/CustomTable';
import { yesNoOptions } from '../../../Columns';

const TableProductEdit = ({ tableData, loading, locations, updateRowData, actions }) => {
  const productTable = [
    {
      field: 'facility',
      headerName: 'Facility',
      flex: 5,
      sortable: false,
      minWidth: 250,
      valueGetter: (params) => {
        if (params.value) {
          return getFacilityLabelByValue(locations, params.value);
        }
        return '';
      }
    },
    {
      field: 'formattedDate',
      headerName: 'Date',
      flex: 2,
      minWidth: 100,
      editable: true,
      sortingOrder: ['desc', 'asc'],
      sortComparator: dateTimeComparator
    },
    {
      field: 'sampleID',
      headerName: 'Sample ID',
      flex: 2,
      minWidth: 100,
      editable: true
    },
    { field: 'owner', headerName: 'Owner', flex: 5, minWidth: 250, editable: true },
    {
      field: 'batchID',
      headerName: 'Batch',
      flex: 2,
      minWidth: 100,
      sortable: false,
      editable: true
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 1.5,
      minWidth: 75,
      editable: true
    },
    { field: 'provider', headerName: 'Provider', flex: 2, minWidth: 100, editable: true },
    { field: 'high', headerName: 'High', flex: 1.5, minWidth: 75, editable: true },
    { field: 'low', headerName: 'Low', flex: 1.5, minWidth: 75, editable: true },
    { field: 'note', headerName: 'Note', flex: 4, minWidth: 200, sortable: false, editable: true },
    {
      field: 'omit',
      headerName: 'Omit',
      flex: 1.5,
      minWidth: 75,
      type: 'singleSelect',
      editable: true,
      valueOptions: yesNoOptions
    }
  ];

  return (
    <>
      <div
        style={{
          marginBottom: '100px'
        }}
      >
        <CustomTable
          header={productTable}
          data={tableData}
          numberOfRows={10}
          action={actions}
          clickToEdit={false}
          updateRowData={(data) => {
            updateRowData(data);
          }}
          loading={loading}
        />
      </div>
    </>
  );
};

TableProductEdit.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

TableProductEdit.defaultProps = {
  tableData: [],
  loading: false
};

export default TableProductEdit;
