import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import React from 'react';

function PrimaryButton({ buttonStyle, text, clicked }) {
  return (
    <Button className={buttonStyle} onClick={clicked}>
      {text}
    </Button>
  );
}

PrimaryButton.propTypes = {
  buttonStyle: PropTypes.string,
  text: PropTypes.string,
  clicked: PropTypes.func
};

PrimaryButton.defaultProps = {
  buttonStyle: 'btn btn-primary',
  text: 'Submit',
  clicked: () => {}
};

export default PrimaryButton;
