import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Section from './Section';
import LegalTerms from './LegalTerms';
import LegalPrivacy from './LegalPrivacy';

function LegalSection({ bg, textColor, size, bgImage, bgImageOpacity, section }) {
  const validSections = {
    'terms-of-service': true,
    'privacy-policy': true
  };

  const thisSection = validSections[section] ? section : 'terms-of-service';

  const data = {
    domain: 'company.com',
    companyName: 'Company'
  };

  return (
    <Section
      bg={bg}
      textColor={textColor}
      size={size}
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      <Nav variant="pills" activeKey={thisSection} className="justify-content-center">
        <Nav.Item>
          <LinkContainer to="/legal/terms-of-service">
            <Nav.Link eventKey="terms-of-service">Terms of Service</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item>
          <LinkContainer to="/legal/privacy-policy">
            <Nav.Link eventKey="privacy-policy">Privacy Policy</Nav.Link>
          </LinkContainer>
        </Nav.Item>
      </Nav>
      <Container className="mt-5">
        {section === 'terms-of-service' && <LegalTerms {...data} />}

        {section === 'privacy-policy' && <LegalPrivacy {...data} />}
      </Container>
    </Section>
  );
}

LegalSection.propTypes = {
  bg: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  bgImage: PropTypes.string,
  bgImageOpacity: PropTypes.number,
  section: PropTypes.oneOf(['terms-of-service', 'privacy-policy'])
};

LegalSection.defaultProps = {
  bg: 'white',
  textColor: 'dark',
  size: 'md',
  bgImage: '',
  bgImageOpacity: 0.8,
  section: 'terms-of-service'
};

export default LegalSection;
