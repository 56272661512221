export const notes = [
  {
    key: '1',
    value: 'Test result outside +/- 3 sigma range for These Data'
  },
  {
    key: '2',
    value: 'Test result outside +/- 3 sigma range for ASTM Repository'
  },
  {
    key: '3',
    value: 'Z-Score outside range of -2 to 2'
  },
  {
    key: '#',
    value: 'Z-Score > 90'
  },
  {
    key: '$',
    value: 'Z-Score not calculated'
  },
  {
    key: 'O',
    value: 'Omitted'
  },
  {
    key: 'R',
    value: 'Rejected by GESD'
  }
];

export const summary = [
  {
    label: 'Conforming Results',
    key: 'conformingResults'
  },
  {
    label: 'Results Used',
    key: 'resultsUsed'
  },
  {
    label: 'Average',
    key: 'average'
  },
  {
    label: 'StdDev',
    key: 'stDev'
  },
  {
    label: 'ASTM R',
    key: 'astmR'
  },
  {
    label: 'These Data R',
    key: 'theseDataR'
  },
  {
    label: 'TPI',
    key: 'tpi'
  },
  {
    label: 'ADrs Statistic',
    key: 'andersonDarling'
  }
];
