import React from 'react';
import PropTypes from 'prop-types';
import Table from 'rsuite/Table';
import LowerTable from '../LowerTable';

const { Column, HeaderCell, Cell } = Table;

const ProductReportTable = ({ body, loadingChartData, headers, title }) => {
  const getWidth = (i) => {
    if (i === 0) return 2; // date
    if (i === 2) return 2; // sampleId
    if (i === 3) return 3; // provider

    return 1;
  };

  return (
    <LowerTable body={body} title={title} fullHeight loadingChartData={loadingChartData}>
      {headers.map((item, index) => (
        <Column flexGrow={getWidth(index)} align="center" key={`${item}`} fullText>
          <HeaderCell>{item}</HeaderCell>
          <Cell dataKey={`${index}`} />
        </Column>
      ))}
    </LowerTable>
  );
};

ProductReportTable.propTypes = {
  body: PropTypes.arrayOf(PropTypes.array).isRequired,
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  loadingChartData: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

export default ProductReportTable;
