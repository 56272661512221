import { linearRegression } from '../../../util/util';

function validDate(d) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    // it is a date
    if (!Number.isNaN(d.getTime())) {
      // d.valueOf() could also work
      return true;
    }
  }
  return false;
}

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
};

function isNumeric(n) {
  return (!Number.isNaN(parseFloat(n)) && Number.isFinite(n)) || validDate(n);
}

function fixName(incoming) {
  const fixed = [];
  fixed.i15sig = '+1.5 Sigma';
  fixed.i15signeg = '-1.5 Sigma';
  fixed.trendRpd = 'Trend';
  fixed.iAvg = 'Average';
  fixed.rpd = 'Data';
  fixed.mrRpd = 'Data';
  fixed.mrAvg = 'Average';
  fixed.mrUcl = 'UCL';
  fixed.result = 'Result';
  fixed.refHigh = 'Criterion';
  fixed.refLow = 'Criterion';
  fixed.reference = 'Reference';
  fixed.iLcl = 'LCL';
  fixed.iUcl = 'UCL';
  if (fixed[incoming]) {
    return fixed[incoming];
  }
  return incoming;
}

function mapToChart(megaChartData) {
  const headers = ['Round', ...megaChartData.map((item) => item.key)];

  const xAxis = (i) => {
    const xFinder = ['Result', 'Data'];

    const x = megaChartData.find((item) => xFinder.includes(item.key))?.values?.[i]?.x;

    return formatDate(`${x}`) || i;
  };

  const newArray = [...Array(megaChartData?.[0]?.values?.length || 0).keys()].map((index) => {
    return [xAxis(index), ...megaChartData.map((item) => item.values[index]?.y)];
  });

  return [headers, ...newArray];
}

// eslint-disable-next-line
export default function getMegaChartData(
  includeAll,
  trendLine,
  averageLine,
  useLastAverage,
  averageLineMultiple,
  startDate,
  endDate,
  xDataField,
  yDataField,
  yDataField2,
  yDataField3,
  yDataField4,
  yDataField5,
  yDataField6,
  yDataField7,
  color1,
  color2,
  color3,
  color4,
  color5,
  color6,
  color7,
  theSize,
  theShape,
  chartData
) {
  const thedata = [];
  const thedatamore = [];
  const thedatamorenames = [];
  const thedatamorecolors = [];
  const maxFieldCount = 7;
  for (let idx = 0; idx <= maxFieldCount; idx += 1) {
    thedatamore.push([]);
  }
  thedatamorenames[2] = yDataField2;
  thedatamorenames[3] = yDataField3;
  thedatamorenames[4] = yDataField4;
  thedatamorenames[5] = yDataField5;
  thedatamorenames[6] = yDataField6;
  thedatamorenames[7] = yDataField7;
  thedatamorecolors[2] = color2;
  thedatamorecolors[3] = color3;
  thedatamorecolors[4] = color4;
  thedatamorecolors[5] = color5;
  thedatamorecolors[6] = color6;
  thedatamorecolors[7] = color7;

  const activeArray = chartData;
  const allX = [];
  const allY = [];
  let minX = 9999;
  let maxX = -99999;
  for (let i = 0; i < activeArray.length; i += 1) {
    const dataObj = activeArray[i];
    if (includeAll || dataObj.omit === 'N') {
      let xVal = dataObj[xDataField];
      const yVal = dataObj[yDataField];
      const yValArr = [];
      for (let idx = 2; idx <= maxFieldCount; idx += 1) {
        const fieldName = thedatamorenames[idx];
        if (dataObj[fieldName] !== '') {
          yValArr[idx] = dataObj[fieldName];
        }
      }

      if (xDataField === 'date' && !isNumeric(xVal) && xVal.match(/Z$/)) {
        xVal = new Date(xVal);
      }

      if (isNumeric(yVal) && isNumeric(xVal) && xVal !== '' && yVal !== '') {
        // if (xDataField.match(/date/i) && $scope.equalDateSpacing === 1) {
        //     //if we want equal date spacing, we need to store the index instead of the date
        //     xVal = i;
        // }
        thedata.push({
          y: yVal,
          x: xVal,
          shape: theShape
        });
        for (let idx = 2; idx <= maxFieldCount; idx += 1) {
          if (typeof yValArr[idx] !== 'undefined' && isNumeric(yValArr[idx])) {
            thedatamore[idx].push({
              y: yValArr[idx],
              x: xVal,
              shape: theShape
            });
          }
        }
        allY.push(yVal);
        allX.push(xVal);
        if (xVal > maxX) maxX = xVal;
        if (xVal < minX) minX = xVal;
      }
    }
  }

  let barBool = false;
  if (yDataField === 'result') {
    barBool = true;
  }

  const returnArray = [
    {
      values: thedata,
      type: 'scatter',
      bar: barBool,
      // color: d3.rgb(color1),
      key: fixName(yDataField)
    }
  ];

  // if we have additional y values:
  for (let idx = 2; idx <= maxFieldCount; idx += 1) {
    if (typeof thedatamore[idx] !== 'undefined' && thedatamore[idx].length > 0) {
      const displayName = fixName(thedatamorenames[idx]);
      returnArray.push({
        values: thedatamore[idx], // values - represents the array of {x,y} data points
        key: displayName, // key  - the name of the series.
        // color: d3.rgb(thedatamorecolors[idx]),
        type: 'line'
      });
    } // thedatamore[idx].length > 0
  }

  if (trendLine) {
    // run regression and create trend line:
    const lr = linearRegression(allY, allX);
    const numPts = 100;
    const trendData = new Array(numPts);
    for (let idx = 0; idx < numPts; idx += 1) {
      trendData[idx] = {
        x: minX + (maxX - minX) * (idx / numPts),
        y: lr.slope * (minX + ((maxX - minX) * idx) / numPts) + lr.intercept
      };
    }
    returnArray.push({
      values: trendData,
      type: 'line',
      key: 'Trend',
      color: '#ff7f0e'
    });
  } // if trendLine

  return mapToChart(returnArray);
} // megaChartData
