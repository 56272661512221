import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Modal from 'react-bootstrap/Modal';
import { deleteFilter } from '../../../util/db';

const ModalListInstrumentFilter = ({
  title,
  show,
  handleClose,
  data = [],
  allLocations = [],
  instrumentSettingsInstrumentType = [],
  instrumentSettingsInstrumentStatus = [],
  instrumentSettingsInstrumentParameter = [],
  handleApplyFilter
}) => {
  const [rows, setRows] = useState(data);
  const [search, setSearch] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState(null);

  useEffect(() => {
    setRows(data);
  }, [data]);

  const handleDelete = async () => {
    if (deleteRowId !== null) {
      await deleteFilter({ id: deleteRowId });
      setRows((prev) => prev.filter((row) => row.id !== deleteRowId));
      setDeleteRowId(null);
    }
    setIsDeleteModalOpen(false);
  };

  const confirmDelete = (id) => {
    setDeleteRowId(id);
    setIsDeleteModalOpen(true);
  };

  const handleApply = (id) => {
    const selectedRow = rows.find((row) => row.id === id);
    handleApplyFilter(selectedRow);
  };

  const filteredRows = useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();
    return rows.filter((row) => row.name.toLowerCase().includes(lowerCaseSearch));
  }, [rows, search]);

  const getLocationName = (locationId) =>
    allLocations.find((item) => item.id === locationId)?.name || 'All';

  const getStatusName = (statusId) =>
    instrumentSettingsInstrumentStatus.find((item) => item.id === statusId)?.name || 'All';

  const getInstrumentTypeName = (eventTypeId) =>
    instrumentSettingsInstrumentType.find((item) => item.id === eventTypeId)?.name || 'All';

  const getParameterName = (parameterId) =>
    instrumentSettingsInstrumentParameter.find((item) => item.id === parameterId)?.name || 'All';

  return (
    <>
      {/* Main Modal */}
      <Modal size="xl" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Box mb={2}>
            <TextField
              label="Search by name"
              variant="outlined"
              size="small"
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>

          <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {['Name', 'Location', 'Status', 'Type', 'Parameter', 'Action'].map((header) => (
                    <TableCell key={header} sx={{ whiteSpace: 'nowrap' }}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.length > 0 ? (
                  filteredRows.map(({ id, name, location, status, type, parameter }) => (
                    <TableRow key={id}>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>{name}</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {getLocationName(location)}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>{getStatusName(status)}</TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {getInstrumentTypeName(type)}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        {getParameterName(parameter)}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: 'nowrap' }}>
                        <IconButton color="success" onClick={() => handleApply(id)}>
                          <CheckCircleIcon />
                        </IconButton>
                        <IconButton color="error" onClick={() => confirmDelete(id)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="text" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        size="md"
        show={isDeleteModalOpen}
        onHide={() => setIsDeleteModalOpen(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="text" onClick={() => setIsDeleteModalOpen(false)}>
            No
          </Button>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalListInstrumentFilter;
