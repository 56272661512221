import { Button } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { recurringEventOptions, recurringIntervalOptions } from '../TabEvents/Columns';
import { renderMUILoadingButton } from '../../../util/util';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../../../util/firebase';
import { DOCUMENT_LOG_TYPE } from '../TabSettings/DefineCategories';
import { setInstrumentStatusLog } from '../../../util/db';

const ModalFormEvent = ({
  show,
  handleClose,
  handleSave,
  data,
  personnel = [],
  instrumentSettingsEventStatus,
  instrumentSettingsEventType,
  instruments,
  allLocations,
  instrumentName = '',
  isReportInstrumentIssue,
  responsiblePartyOfInstrument = ''
}) => {
  const [loading, setLoading] = useState(false);
  const [reportInstrumentIssue, setReportInstrumentIssue] = useState();

  const checkReportInstrumentIssue = (eventTypeId, instrumentSettingsEventType) => {
    const eventType = instrumentSettingsEventType.find((event) => event.id === eventTypeId);
    return eventType?.name === 'Instrument Issue Report';
  };

  let eventTypeReportInstrumentIssue = '';
  let instrumentSettingEventStatusId = '';
  if (isReportInstrumentIssue) {
    eventTypeReportInstrumentIssue = instrumentSettingsEventType.find(
      (event) => event.name === 'Instrument Issue Report'
    );
    instrumentSettingsEventType = eventTypeReportInstrumentIssue
      ? [eventTypeReportInstrumentIssue]
      : [];
    instrumentSettingEventStatusId =
      instrumentSettingsEventStatus.find((e) => e.name === 'In Progress')?.id || '';
  }
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    formState: { value },
    setValue
  } = useForm({
    defaultValues: {
      id: data?.id || '',
      eventNumber: data?.eventNumber || '',
      location: data?.location || '',
      instrumentName: data?.instrumentName || instrumentName || '',
      dateDue: data?.dateDue ? moment(data?.dateDue.seconds * 1000).toDate() : new Date(),
      status: data?.status || '',
      recurringEvent: data?.recurringEvent || 'No',
      recurringIn: data?.recurringIn || '',
      recurringInterval: data?.recurringInterval?.includes('day')
        ? '|day'
        : data?.recurringInterval || '',
      customRecurringDays: data?.recurringInterval?.includes('day')
        ? String(data?.recurringInterval).split('|')[0]
        : '',
      responsibleParty: data?.responsibleParty || '',
      cost: data?.cost || '',
      notificationLeadTime: data?.notificationLeadTime || '',
      notes: data?.notes || '',
      description: data?.description || '',
      eventType: data?.eventType || '',
      completeDate: data?.completeDate ? moment(data?.completeDate.seconds * 1000).toDate() : null
    }
  });

  useEffect(() => {
    reset({
      id: data?.id || '',
      eventNumber: data?.eventNumber || '',
      location: data?.location || '',
      instrumentName: data?.instrumentName || instrumentName || '',
      dateDue: data?.dateDue ? moment(data?.dateDue.seconds * 1000).toDate() : new Date(),
      status: isReportInstrumentIssue ? instrumentSettingEventStatusId : data?.status || '',
      recurringEvent: data?.recurringEvent || 'No',
      recurringIn: data?.recurringIn || '',
      recurringInterval: data?.recurringInterval?.includes('day')
        ? '|day'
        : data?.recurringInterval || '',
      customRecurringDays: data?.recurringInterval?.includes('day')
        ? String(data?.recurringInterval).split('|')[0]
        : '',
      responsibleParty: isReportInstrumentIssue
        ? responsiblePartyOfInstrument
        : data?.responsibleParty || '',
      cost: data?.cost || '',
      notificationLeadTime: data?.notificationLeadTime || '',
      notes: data?.notes || '',
      description: data?.description || '',
      eventType: isReportInstrumentIssue
        ? eventTypeReportInstrumentIssue?.id || ''
        : data?.eventType || '',
      completeDate: data?.completeDate ? moment(data?.completeDate.seconds * 1000).toDate() : null
    });
    if (show && !isReportInstrumentIssue) {
      const isIssueReport = checkReportInstrumentIssue(
        data?.eventType,
        instrumentSettingsEventType
      );
      setReportInstrumentIssue(isIssueReport);
    }
  }, [show]);

  const formState = watch();
  const functions = getFunctions(firebaseApp);
  const sendInstantEmailNotificationFunction = httpsCallable(
    functions,
    'sendEventInstantEmailNotification'
  );

  const onSubmit = async (formData) => {
    setLoading(true);

    if (formData.recurringInterval?.includes('day')) {
      formData.recurringInterval = `${formData.customRecurringDays}${formData.recurringInterval}`;
    }

    delete formData.customRecurringDays;

    if (data?.id && data?.status !== formData.status) {
      await logEventStatus(data.id, data?.status, formData.status);
      await sendInstantEmailNotificationWhenStatusChanged(data?.status, formData);
    }

    if (data?.id && data?.responsibleParty !== formData.responsibleParty) {
      await sendInstantEmailNotificationWhenResponsiblePartyChanged(
        formData,
        data?.responsibleParty,
        formData.responsibleParty
      );
    }

    const response = await handleSave(formData);

    if (!data?.id) {
      await sendInstantEmailNotificationWhenCreateNewEvent('', response);
    }

    setLoading(false);
  };

  const logEventStatus = async (eventId, prevStatusId, currentStatusId) => {
    const prevStatus = instrumentSettingsEventStatus.find((s) => s.id === prevStatusId);
    const currentStatus = instrumentSettingsEventStatus.find((s) => s.id === currentStatusId);
    const instrumentStatusLog = {
      type: DOCUMENT_LOG_TYPE.EVENT,
      documentId: eventId,
      prevStatus: prevStatus?.id || '',
      prevStatusName: prevStatus?.name || '',
      currentStatus: currentStatus?.id || '',
      currentStatusName: currentStatus?.name || ''
    };

    await setInstrumentStatusLog(instrumentStatusLog);
  };

  const sendInstantEmailNotificationWhenStatusChanged = async (prevStatusId, event) => {
    const responsibleParty = personnel.find((item) => item.id === event.responsibleParty);
    const prevStatus = instrumentSettingsEventStatus.find((s) => s.id === prevStatusId);
    const status = instrumentSettingsEventStatus.find((item) => item.id === event.status);
    const eventType = instrumentSettingsEventType.find((item) => item.id === event?.eventType);
    const instrument = instruments.find((item) => item.id === event?.instrumentName);
    const location = allLocations.find((item) => item.id === instrument?.location);

    const sendInstantEmailRequest = {
      sendToPersons: [
        {
          email: responsibleParty.email,
          name: responsibleParty.name
        }
      ],
      event: {
        id: event.id,
        description: event.description,
        eventNumber: event.eventNumber,
        eventType: eventType.name,
        location: location?.name,
        instrumentName: instrument.name,
        responsibleParty: responsibleParty.name,
        prevStatus: prevStatus?.name,
        status: status.name,
        statusChanged: true,
        dateDue: moment(event.dateDue).format('MM/DD/YYYY'),
        detailLink: `instrument/events/${event.id}`
      }
    };

    await sendInstantEmailNotificationFunction(sendInstantEmailRequest);
  };
  const sendInstantEmailNotificationWhenResponsiblePartyChanged = async (
    event,
    prevResponsiblePartyId,
    currentResponsiblePartyId
  ) => {
    const prevResponsibleParty = personnel.find((item) => item.id === prevResponsiblePartyId);
    const currentResponsibleParty = personnel.find((item) => item.id === currentResponsiblePartyId);
    const status = instrumentSettingsEventStatus.find((item) => item.id === event.status);
    const eventType = instrumentSettingsEventType.find((item) => item.id === event?.eventType);
    const instrument = instruments.find((item) => item.id === event?.instrumentName);
    const location = allLocations.find((item) => item.id === instrument?.location);
    const sendInstantEmailRequest = {
      sendToPersons: [
        {
          email: prevResponsibleParty.email,
          name: prevResponsibleParty.name
        },
        {
          email: currentResponsibleParty.email,
          name: currentResponsibleParty.name
        }
      ],
      event: {
        id: event.id,
        description: event.description,
        eventNumber: event.eventNumber,
        eventType: eventType.name,
        location: location?.name,
        instrumentName: instrument.name,
        responsibleParty: currentResponsibleParty.name,
        prevResponsibleParty: prevResponsibleParty.name,
        responsiblePartyChanged: true,
        status: status.name,
        dateDue: moment(event.dateDue).format('MM/DD/YYYY'),
        detailLink: `instrument/events/${event.id}`
      }
    };

    await sendInstantEmailNotificationFunction(sendInstantEmailRequest);
  };

  const handleChangeInstrumentName = (event) => {
    if (!isReportInstrumentIssue) return;
    const selectedValue = event.target.value;
    const instrumentSelected = instruments.find((item) => item.id === selectedValue);
    setValue('responsibleParty', instrumentSelected?.responsibleParty || '');
  };

  const handleOnchangeEventType = (event) => {
    const selectedValue = event.target.value;
    const isIssueReport = checkReportInstrumentIssue(selectedValue, instrumentSettingsEventType);
    setReportInstrumentIssue(isIssueReport);
  };

  const sendInstantEmailNotificationWhenCreateNewEvent = async (prevStatusId, event) => {
    const responsibleParty = personnel.find((item) => item.id === event.responsibleParty);
    const prevStatus = instrumentSettingsEventStatus.find((s) => s.id === prevStatusId);
    const status = instrumentSettingsEventStatus.find((item) => item.id === event.status);
    const eventType = instrumentSettingsEventType.find((item) => item.id === event?.eventType);
    const instrument = instruments.find((item) => item.id === event?.instrumentName);
    const location = allLocations.find((item) => item.id === instrument?.location);

    const sendInstantEmailRequest = {
      sendToPersons: [
        {
          email: responsibleParty.email,
          name: responsibleParty.name
        }
      ],
      event: {
        id: event.id,
        description: event.description,
        eventNumber: event.eventNumber,
        eventType: eventType.name,
        location: location?.name,
        instrumentName: instrument.name,
        responsibleParty: responsibleParty.name,
        prevStatus: prevStatus?.name,
        status: status.name,
        isNew: true,
        dateDue: moment(event.dateDue).format('MM/DD/YYYY'),
        detailLink: `instrument/events/${event.id}`
      }
    };

    await sendInstantEmailNotificationFunction(sendInstantEmailRequest);
  };
  const notReportInstrumentIssue = !isReportInstrumentIssue && !reportInstrumentIssue;
  const title = notReportInstrumentIssue ? 'Event' : 'Report Instrument';

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)} onReset={handleClose}>
          <Modal.Header closeButton>{data?.id ? `Edit ${title}` : `Add ${title}`}</Modal.Header>

          <Modal.Body>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Instrument Name</Form.Label>
                <Form.Select
                  name="instrumentName"
                  {...register('instrumentName', { required: true })}
                  onChange={handleChangeInstrumentName}
                >
                  <option value="">-- Select --</option>
                  {instruments.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Event Type</Form.Label>
                <Form.Select
                  name="eventType"
                  {...register('eventType', { required: true })}
                  onChange={handleOnchangeEventType}
                >
                  <option value="">-- Select --</option>
                  {instrumentSettingsEventType.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  name="description"
                  {...register('description', { required: false })}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Responsible Party</Form.Label>
                <Form.Select
                  name="responsibleParty"
                  {...register('responsibleParty', { required: true })}
                  disabled={isReportInstrumentIssue}
                >
                  <option value="">-- Select --</option>
                  {personnel.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Status</Form.Label>
                <Form.Select name="status" {...register('status', { required: true })}>
                  <option value="">-- Select --</option>
                  {instrumentSettingsEventStatus.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Date Due</Form.Label>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="dateDue"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      selected={field.value}
                      ref={(ref) => {
                        field.ref({
                          focus: ref?.setFocus
                        });
                      }}
                    />
                  )}
                />
              </Form.Group>
              {notReportInstrumentIssue ? (
                <Form.Group as={Col}>
                  <Form.Label>Complete Date</Form.Label>
                  <Controller
                    control={control}
                    rules={{ required: false }}
                    name="completeDate"
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        selected={field.value}
                        ref={(ref) => {
                          field.ref({
                            focus: ref?.setFocus
                          });
                        }}
                      />
                    )}
                  />
                </Form.Group>
              ) : (
                <Form.Group as={Col}>
                  <Form.Label>Cost</Form.Label>
                  <Form.Control
                    name="cost"
                    type="number"
                    {...register('cost', { valueAsNumber: true })}
                  />
                </Form.Group>
              )}
            </Row>
            {notReportInstrumentIssue ? (
              <Row style={{ marginTop: 10 }}>
                <Form.Group as={Col}>
                  <Form.Label>Cost</Form.Label>
                  <Form.Control
                    name="cost"
                    type="number"
                    {...register('cost', { valueAsNumber: true })}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Notification Lead Time (days)</Form.Label>
                  <Form.Control
                    name="notificationLeadTime"
                    type="number"
                    {...register('notificationLeadTime', { valueAsNumber: true })}
                  />
                </Form.Group>
              </Row>
            ) : (
              <></>
            )}
            {notReportInstrumentIssue ? (
              <Row style={{ marginTop: 10 }}>
                <Form.Group as={Col}>
                  <Form.Label>Recurring Event</Form.Label>
                  <Form.Select
                    name="recurringEvent"
                    {...register('recurringEvent', {
                      required: true
                    })}
                  >
                    {recurringEventOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                {formState.recurringEvent === 'Yes' ? (
                  <>
                    <Form.Group as={Col}>
                      <Form.Label>Recurring Interval</Form.Label>
                      <Form.Select
                        name="recurringInterval"
                        {...register('recurringInterval', {
                          required: true
                        })}
                      >
                        {recurringIntervalOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </>
                ) : (
                  <Form.Group as={Col}></Form.Group>
                )}
              </Row>
            ) : (
              <></>
            )}
            <Row style={{ marginTop: 10 }}>
              {formState.recurringEvent === 'Yes' &&
              formState.recurringInterval?.includes('day') ? (
                <>
                  <Form.Group as={Col}>
                    <Form.Label>Custom Recurring (days)</Form.Label>
                    <Form.Control
                      name="Custom Recurring"
                      type="number"
                      {...register('customRecurringDays', { valueAsNumber: true, required: true })}
                    />
                  </Form.Group>
                </>
              ) : (
                <Form.Group as={Col}></Form.Group>
              )}
              <Form.Group as={Col}></Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="notes"
                  {...register('notes', { required: false })}
                />
              </Form.Group>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="text" type="reset">
              Cancel
            </Button>
            &nbsp; &nbsp;
            {renderMUILoadingButton({
              buttonText: 'Save',
              isLoading: loading,
              variant: 'contained',
              type: 'submit'
            })}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalFormEvent;
