import React, { useContext } from 'react';
import Filters from '../filters';
import CustomChart from '../../../CustomChart';
import { TableAndPlotContext } from '../../common/TablesAndPlotsContext';

const PlotsScreen = () => {
  const { names, qqChartAllData, iChartAllDataILCP, mrChartAllData, loading, ADRMS, ADMR } =
    useContext(TableAndPlotContext);

  const optionsChart = {
    title: 'Company Performance',
    curveType: 'function',
    legend: { position: 'top' },
    series: {
      0: {
        curveType: 'none',
        pointSize: 5
      }
    },
    intervals: {
      style: 'points',
      pointSize: 4
    },
    chartArea: {
      left: '5%',
      width: '90%'
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
      }}
    >
      <Filters names={names} />
      <CustomChart
        data={mrChartAllData}
        type="LineChart"
        options={{
          ...optionsChart,
          title: 'Moving Range and Control Limits (all data)'
        }}
        loading={loading}
      />
      <CustomChart
        data={iChartAllDataILCP}
        type="LineChart"
        options={{
          ...optionsChart,
          title: ' I Chart (all data)'
        }}
        loading={loading}
      />
      <CustomChart
        data={qqChartAllData}
        type="ScatterChart"
        options={{
          trendlines: {
            0: {
              color: 'orange'
            }
          },
          legend: { position: 'top' },
          chartArea: {
            left: '5%',
            width: '90%'
          },
          title: `Q/Q Chart (all data); Anderson-Darling: ${
            Math.round(ADRMS * 1000) / 1000
          } (RMS), ${Math.round(ADMR * 1000) / 1000} (MR)`
        }}
        loading={loading}
      />
    </div>
  );
};

export default PlotsScreen;
