import React from 'react';
import Meta from '../components/Meta';
import HeroSection from '../components/HeroSection';
import FeaturesSection from '../components/FeaturesSection';
import NewsletterSection from '../components/NewsletterSection';

function IndexPage() {
  return (
    <>
      <Meta />
      <HeroSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Introducing ASTM Statistical Quality Control Tools"
        subtitle="ANNOUNCING best in class SQC tools from the name you trust! ASTM SQC is an online statistical package built specifically for the fuel and bio-fuel community, providing comprehensive quality control analytics, and control charting."
        image="https://i.ibb.co/1XXT8v4/ASTMSQC.png"
        buttonText="Get Started"
        buttonColor="primary"
        buttonPath="/auth/signin"
      />
      <FeaturesSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Features"
        subtitle=""
      />
      <NewsletterSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Sign up for a demo"
        subtitle="Find out how ASTM SQC can save you time and money today"
        buttonText="Sign Up"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="We will get back to you within 2 business days!"
      />
    </>
  );
}

export default IndexPage;
