import Tag from 'rsuite/Tag';
import React from 'react';
import PropTypes from 'prop-types';
import { notes, summary } from './constants';

const PerformanceSummary = ({ runData }) => {
  function CustomCell(dataKey) {
    switch (dataKey) {
      case 'average':
      case 'tpi':
      case 'stDev':
      case 'theseDataR':
      case 'andersonDarling':
        return (
          <Tag
            style={{
              width: '50%',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}
          >
            {Math.round((runData?.summary?.[dataKey] ?? 0) * 1000) / 1000}
          </Tag>
        );
      default:
        return (
          <Tag
            style={{
              width: '50%',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}
          >
            {runData?.summary?.[dataKey] ?? ''}
          </Tag>
        );
    }
  }

  return (
    <div className="topTable">
      <div className="contentPerformance">
        <div>
          <label className="title">Performance Summary</label>
        </div>
        <div
          style={{
            marginTop: '8px',
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            gap: '1rem'
          }}
        >
          {summary.map((item) => (
            <div style={{ display: 'flex', height: '32px' }} key={`summary-key-${item.key}`}>
              <Tag
                color="blue"
                style={{
                  width: '50%',
                  fontSize: '14px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {item.label}
              </Tag>
              {CustomCell(item.key)}
            </div>
          ))}
        </div>
      </div>
      <div className="noteContent">
        <label className="title">Notes</label>
        <ul>
          {notes.map((item) => (
            <ol key={`notes-old-${item.key}`}>
              <Tag color="blue">{item.key}</Tag> {item.value}
            </ol>
          ))}
        </ul>
      </div>
    </div>
  );
};

PerformanceSummary.propTypes = {
  runData: PropTypes.objectOf(PropTypes.any)
};

PerformanceSummary.defaultProps = {
  runData: {}
};

export default PerformanceSummary;
