import { useEffect, useState } from 'react';
import jsonpCall from '../../../JsonpCall';

function isNumericVal(n) {
  return !Number.isNaN(parseFloat(n)) && Number.isFinite(n) && n + 0 === n;
}

const useSheetCallback = () => {
  const [t1Head, setT1Head] = useState([]);
  const [t1Body, setT1Body] = useState([]);
  const [t2Head, setT2Head] = useState([]);
  const [t2Body, setT2Body] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [ADRMS, setADRMS] = useState('');
  const [ADMR, setADMR] = useState('');
  const gsheetCallbackIlcpOriginal = (returnDataIn) => {
    const returnData = returnDataIn;
    const chartDataRows = [];

    for (let i = 0; i < returnData.chartReturn.length; i += 1) {
      // start after header
      if (returnData.chartReturn[i][0] !== '#N/A') {
        for (let j = 0; j < 16; j += 1) {
          if (returnData.chartReturn[i][j] === '#N/A') {
            returnData.chartReturn[i][j] = undefined;
          }
        }
        chartDataRows.push({
          id: returnData.chartReturn[i][0],
          result: returnData.chartReturn[i][1],
          average: returnData.chartReturn[i][2],
          round: returnData.chartReturn[i][3],
          mr: returnData.chartReturn[i][4],
          mravg: returnData.chartReturn[i][5],
          mrucl: returnData.chartReturn[i][6],
          zval: returnData.chartReturn[i][7],
          pp: returnData.chartReturn[i][8],
          ucl: returnData.chartReturn[i][9],
          lcl: returnData.chartReturn[i][10],
          iuwl: returnData.chartReturn[i][11],
          ilwl: returnData.chartReturn[i][12],
          ewmatrend: returnData.chartReturn[i][13],
          ewmaucl: returnData.chartReturn[i][14],
          ewmalcl: returnData.chartReturn[i][15]
        });
      }
    }
    setADRMS(returnData.globalReturn[0][0]);
    setADMR(returnData.globalReturn[1][0]);
    setChartData(chartDataRows);

    let i;
    const t1head = [];
    const t1body = [];
    const t2head = [];
    const t2body = [];

    const t1colct = 4;
    const t2colct = 9;

    for (let x = 0; x < t1colct; x += 1) {
      t1head.push(returnData.tableReturn[0][x]);
    }
    for (let x = t1colct + 1; x < t1colct + t2colct + 1; x += 1) {
      t2head.push(returnData.tableReturn[0][x]);
    }

    for (let r = 1; r < returnData.tableReturn.length; r += 1) {
      const t1row = [];
      const t2row = [];
      if (returnData.tableReturn[r][0] !== '') {
        for (i = 0; i < t1colct; i += 1) {
          t1row.push(
            String(returnData.tableReturn[r][i]).length > 5 &&
              isNumericVal(returnData.tableReturn[r][i])
              ? returnData.tableReturn[r][i].toFixed(3)
              : returnData.tableReturn[r][i]
          );
        }
        t1body.push(t1row);
      }
      if (returnData.tableReturn[r][t1colct + 1] !== '') {
        for (i = t1colct + 1; i < t1colct + t2colct + 1; i += 1) {
          t2row.push(
            String(returnData.tableReturn[r][i]).length > 5 &&
              isNumericVal(returnData.tableReturn[r][i])
              ? returnData.tableReturn[r][i].toFixed(3)
              : returnData.tableReturn[r][i]
          );
        }
        t2body.push(t2row);
      }
    }
    setT1Head(t1head);
    setT1Body(t1body);
    setT2Head(t2head);
    setT2Body(t2body);
    setLoading(false);
  };

  useEffect(() => {
    window.gsheetCallbackIlcpOriginal = gsheetCallbackIlcpOriginal;

    return () => {
      delete window.gsheetCallbackIlcpOriginal;
    };
  }, []);

  const callBack = async (cbData) => {
    const newData = Object.values(cbData).flatMap((item) => item);

    if (!loading && newData.length > 0) {
      setLoading(true);

      const parameterBlock = [1, 0.4];

      const values = newData
        .filter((item) => !item.omit)
        .map((item) => [
          item.round,
          item.result,
          item.runData?.summary?.average,
          item.runData?.summary?.resultsUsed,
          item.runData?.summary?.stDev,
          item.runData?.summary?.astmR,
          item.runData?.summary?.theseDataR,
          item.z,
          ''
        ]);

      const dataBlock = values.flatMap((item) => item).join('|');

      const myUrl = `${
        'https://script.google.com/macros/s/AKfycbxw325XlMC-1rCs45bPMs9yJiaQ1d3gl18BWN5lVjhQwLWYV7Ap-hH2Q3kSaZFScEBThg/exec?' +
        'sheetId=' +
        '1E6q9fpKQDmjIT8bWWOmy8xTAWYWIQvFzkwsDhxtof54' + // this is a dedicated magellan sheet right now
        '&parameterBlock='
      }${parameterBlock.join('|')}&numRows=${
        values.length
      }&callback=gsheetCallbackIlcpOriginal&dataBlock=${dataBlock}`;

      await jsonpCall(myUrl);
      setLoading(false);
    }
  };

  useEffect(() => {
    callBack(data).finally(() => setLoading(false));
  }, [data]);

  return {
    t1Head,
    t1Body,
    t2Head,
    t2Body,
    updateData: setData,
    loading,
    chartData,
    ADRMS,
    ADMR,
    setT1Body,
    setT2Body
  };
};

export default useSheetCallback;
