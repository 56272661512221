import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import FormField from './FormField';
import { useAuth } from '../util/auth';
import { useForm } from 'react-hook-form';
import AppTextField from '../components/Common/AppTextField';
function SettingsGeneral({ onStatus }) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      name: auth.user.name,
      email: auth.user.email
    }
  });

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    return auth
      .updateProfile(data)
      .then(() => {
        // Set success status
        onStatus({
          type: 'success',
          message: 'Your profile has been updated'
        });
      })
      .catch((error) => {
        if (error.code === 'auth/requires-recent-login') {
          onStatus({
            type: 'requires-recent-login',
            // Resubmit after reauth flow
            callback: () => onSubmit(data)
          });
        } else {
          // Set error status
          onStatus({
            type: 'error',
            message: error.message
          });
        }
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId="formName">
        <AppTextField
          name="name"
          control={control}
          rules={{ required: 'Please enter your name' }}
          placeholder="Name"
        />
      </Form.Group>
      <Form.Group controlId="formEmail">
        <AppTextField
          name="email"
          control={control}
          rules={{ required: 'Please enter your email' }}
          placeholder="Email"
          type="email"
        />
      </Form.Group>
      <Button type="submit" size="lg" disabled={pending}>
        <span>Save</span>

        {pending && (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden
            className="ml-2 align-baseline"
          >
            <span className="sr-only">Sending...</span>
          </Spinner>
        )}
      </Button>
    </Form>
  );
}

SettingsGeneral.propTypes = {
  onStatus: PropTypes.func.isRequired
};

export default SettingsGeneral;
