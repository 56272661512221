import React from 'react';
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';

function Avatar({ size, ...otherProps }) {
  return (
    <Image
      {...otherProps}
      roundedCircle
      style={{
        width: size,
        height: size
      }}
    />
  );
}

Avatar.propTypes = {
  size: PropTypes.number.isRequired
};

export default Avatar;
