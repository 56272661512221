import React, { useContext, useEffect, useState } from 'react';
import {
  setInstrumentEvents,
  useGetPersonnel,
  useInstrumentEquipments,
  useInstrumentSettings
} from '../../util/db';
import { useForm } from 'react-hook-form';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { SETTING_TYPE } from '../InstrumentManagement/TabSettings/DefineCategories';
import { alphaNumericSorter, renderLoadingButton, renderMUILoadingButton } from '../../util/util';
import ChartContext from '../DataEntrySection/DataEntryProvider';
import firebaseApp from '../../util/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import moment from 'moment';

const defaultFormValue = {
  id: '',
  eventNumber: '',
  location: '',
  instrumentName: '',
  dateDue: new Date(),
  status: '',
  recurringEvent: 'No',
  recurringIn: '',
  recurringInterval: '',
  responsibleParty: '',
  cost: '',
  notificationLeadTime: '',
  notes: '',
  description: '',
  eventType: '',
  completeDate: new Date()
};

function ReportIssue() {
  const { data: instruments = [] } = useInstrumentEquipments();
  const { data: instrumentSettingsEventStatus = [] } = useInstrumentSettings(
    SETTING_TYPE.EVENT_STATUS
  );
  const { data: instrumentSettingsEventType = [] } = useInstrumentSettings(SETTING_TYPE.EVENT_TYPE);
  const { data: allLocations = [] } = useInstrumentSettings(SETTING_TYPE.INSTRUMENT_LOCATION);
  const { data: personnel } = useGetPersonnel();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const { currentLocation, currentInstrument } = useContext(ChartContext);
  const functions = getFunctions(firebaseApp);
  const sendInstantEmailNotificationFunction = httpsCallable(
    functions,
    'sendEventInstantEmailNotification'
  );
  const [instrumentData, setInstrumentData] = useState([]);
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    resetField,
    formState: { value }
  } = useForm({
    defaultValues: defaultFormValue
  });

  useEffect(() => {
    resetField('instrumentName', '');
    setInstrumentData(
      instruments
        .map((i) => ({
          ...i,
          location: allLocations.find((l) => l.id === i.location)?.name
        }))
        .filter((i) => !currentLocation || i.location === currentLocation)
        .sort(alphaNumericSorter)
    );
  }, [currentLocation, instruments]);

  const onSubmit = async (formData) => {
    setLoading(true);

    const instrumentReportIssueType = instrumentSettingsEventType.find(
      (t) => t.name === 'Instrument Issue Report'
    );
    const inProgressStatus = instrumentSettingsEventStatus.find((t) => t.name === 'In Progress');
    const instrument = instruments.find((t) => t.id === formData.instrumentName);

    const data = {
      ...formData,
      location: instrument?.location,
      dateDue: new Date(),
      status: inProgressStatus?.id,
      recurringEvent: 'No',
      recurringIn: '',
      recurringInterval: '',
      responsibleParty: instrument?.responsibleParty,
      cost: '',
      notificationLeadTime: '',
      eventType: instrumentReportIssueType?.id,
      completeDate: '',
      statusName: inProgressStatus?.name || '',
      vendors: instrument?.vendors || [],
      groups: instrument?.groups || []
    };

    await setInstrumentEvents(data);

    reset(defaultFormValue);

    await sendInstantEmailNotificationWhenCreateNewEvent('', data);
    setShow(true);
    setLoading(false);
  };

  const sendInstantEmailNotificationWhenCreateNewEvent = async (prevStatusId, event) => {
    const responsibleParty = personnel.find((item) => item.id === event.responsibleParty);
    const prevStatus = instrumentSettingsEventStatus.find((s) => s.id === prevStatusId);
    const status = instrumentSettingsEventStatus.find((item) => item.id === event.status);
    const eventType = instrumentSettingsEventType.find((item) => item.id === event?.eventType);
    const instrument = instruments.find((item) => item.id === event?.instrumentName);
    const location = allLocations.find((item) => item.id === instrument?.location);

    const sendInstantEmailRequest = {
      sendToPersons: [
        {
          email: responsibleParty.email,
          name: responsibleParty.name
        }
      ],
      event: {
        id: event.id,
        description: event.description,
        eventNumber: event.eventNumber,
        eventType: eventType.name,
        location: location?.name,
        instrumentName: instrument.name,
        responsibleParty: responsibleParty.name,
        prevStatus: prevStatus?.name,
        status: status.name,
        isNew: true,
        dateDue: moment(event.dateDue).format('MM/DD/YYYY'),
        detailLink: `instrument/events/${event.id}`
      }
    };

    await sendInstantEmailNotificationFunction(sendInstantEmailRequest);
  };
  return (
    <>
      <Alert key="success" show={show} onClose={() => setShow(false)} dismissible variant="success">
        Successfully created event
      </Alert>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Form.Group as={Col}>
            <Form.Label>Instrument Name</Form.Label>
            <Form.Select name="instrumentName" {...register('instrumentName', { required: true })}>
              <option value="">-- Select --</option>
              {instrumentData.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Form.Group as={Col}>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              {...register('description', { required: true, minLength: 5 })}
            />
          </Form.Group>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Form.Group as={Col}>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="notes"
              {...register('notes', { required: false })}
            />
          </Form.Group>
        </Row>
        <div className="edit-button">
          {renderLoadingButton({
            buttonText: 'Save',
            hasLoading: true,
            isLoading: loading,
            type: 'submit',
            disabled: !watch('instrumentName')
          })}
        </div>
      </form>
    </>
  );
}

export default ReportIssue;
