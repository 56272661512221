import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import ChartContext from './DataEntrySection/DataEntryProvider';
import { getSigDigitsUtil } from '../util/util';

function SQCScatterPlot({ collections, xMin, xMax, yMin, yMax, chartSigDigits }) {
  const { currentControlStrategyConfigurations } = useContext(ChartContext);

  const renderSigDigits = (stringValue) => {
    return getSigDigitsUtil(
      stringValue,
      currentControlStrategyConfigurations?.chartSigDigits || chartSigDigits
    );
  };

  const formatAxis = (tickItem) => {
    return renderSigDigits(tickItem);
  };

  const xSpan = xMax - xMin;
  const ySpan = yMax - yMin;
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <ScatterChart
          margin={{
            top: 5,
            right: 20,
            bottom: 15,
            left: 20
          }}
        >
          {collections.map((collection) => (
            <Scatter
              name={collection.name}
              data={collection.data}
              key={collection.name}
              fill={collection.color}
              line={collection.line}
            />
          ))}

          <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
          <XAxis
            type="number"
            dataKey="x"
            name="Quartiles"
            domain={[xMin - 0.1 * xSpan, xMax + 0.1 * xSpan]}
            tickFormatter={formatAxis}
          />
          <YAxis
            type="number"
            dataKey="y"
            domain={[yMin - 0.1 * ySpan, yMax + 0.1 * ySpan]}
            tickFormatter={formatAxis}
          />
          <Legend verticalAlign="top" height={36} />
          <Tooltip formatter={(value) => `${renderSigDigits(value)}`} />
        </ScatterChart>
      </ResponsiveContainer>
    </>
  );
}

SQCScatterPlot.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.object).isRequired,
  xMin: PropTypes.number.isRequired,
  xMax: PropTypes.number.isRequired,
  yMin: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired
};

export default SQCScatterPlot;
