import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

function FormField({ error, type, inputRef, label, ...inputProps }) {
  return (
    <>
      {label && <Form.Label>{label}</Form.Label>}

      <Form.Control
        as={type === 'textarea' ? 'textarea' : 'input'}
        type={type === 'textarea' ? undefined : type}
        isInvalid={error ? true : undefined}
        ref={inputRef}
        {...inputProps}
      />

      {error && (
        <Form.Control.Feedback type="invalid" className="text-left">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  );
}

FormField.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string
  }),
  type: PropTypes.string,
  inputRef: PropTypes.func,
  label: PropTypes.string
};

FormField.defaultProps = {
  error: undefined,
  type: 'text',
  inputRef: undefined,
  label: undefined
};

export default FormField;
