import React from 'react';
import PropTypes from 'prop-types';
import Meta from '../components/Meta';

function NotFoundPage({ location }) {
  return (
    <>
      <Meta title="404" />
      <div
        style={{
          padding: '50px',
          width: '100%',
          textAlign: 'center'
        }}
      >
        The page <code>{location.pathname}</code> could not be found
      </div>
    </>
  );
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default NotFoundPage;
