import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'rsuite/Button';
import Notification from 'rsuite/Notification';
import Form from 'rsuite/Form';
import Drawer from 'rsuite/Drawer';
import FormGroup from 'rsuite/FormGroup';
import FormControl from 'rsuite/FormControl';
import { saveDataSet } from '../../../util/db';

const DataSetForm = ({ showPanel, setShowPanel, handleNewDataSet }) => {
  const [name, setName] = useState('');
  const [scriptId, setScriptId] = useState('');
  const [sheetId, setSheetId] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleClose = () => {
    setShowPanel(false);
    setName('');
    setScriptId('');
    setSheetId('');
    setLoading(false);
    setHasSubmitted(false);
  };

  const handleFormSubmit = async () => {
    setHasSubmitted(true);
    if (name && scriptId && sheetId) {
      setLoading(true);
      try {
        const newDataSet = await saveDataSet({
          name,
          scriptId,
          sheetId,
          header: []
        });

        handleNewDataSet(newDataSet);
        setSuccess(true);
      } catch (e) {
        setError('Looks like we could not load the data set. Please try again later.');
      } finally {
        handleClose();
      }
    }
  };

  if (success) {
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return (
      <Notification
        style={{
          position: 'absolute',
          top: '1rem',
          right: '1rem',
          zIndex: 9999
        }}
        closable
        type="success"
        header="Success"
        onClose={() => {
          handleClose();
          setSuccess(false);
        }}
      >
        the new Data Set has been created
      </Notification>
    );
  }

  if (!showPanel) return <></>;

  return (
    <Drawer open={showPanel} onClose={handleClose}>
      <Drawer.Header>
        <Drawer.Title>New Data Set</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body
        style={{
          display: 'flex',
          gap: '2rem',
          flexDirection: 'column'
        }}
      >
        <Form onSubmit={handleFormSubmit}>
          <FormGroup>
            <Form.ControlLabel>Name</Form.ControlLabel>
            <FormControl
              name="name"
              value={name}
              onChange={(value) => setName(value)}
              errorMessage={hasSubmitted && !name ? 'Name is required' : null}
            />
            <Form.HelpText>Name is required</Form.HelpText>
          </FormGroup>
          <FormGroup>
            <Form.ControlLabel>Script ID</Form.ControlLabel>
            <FormControl
              name="scriptId"
              value={scriptId}
              onChange={(value) => setScriptId(value.trim())}
              errorMessage={hasSubmitted && !scriptId ? 'Script ID is required' : null}
            />
            <Form.HelpText>Script ID is required</Form.HelpText>
          </FormGroup>
          <FormGroup>
            <Form.ControlLabel>Sheet ID</Form.ControlLabel>
            <FormControl
              name="sheetId"
              value={sheetId}
              onChange={(value) => setSheetId(value.trim())}
              errorMessage={hasSubmitted && !sheetId ? 'Sheet ID is required' : null}
            />
            <Form.HelpText>Sheet ID is required</Form.HelpText>
          </FormGroup>
          <Button type="submit" appearance="primary" loading={loading}>
            Submit
          </Button>
        </Form>

        {error ? (
          <Notification closable type="error" header="Error">
            {error}
          </Notification>
        ) : null}
      </Drawer.Body>
    </Drawer>
  );
};

DataSetForm.propTypes = {
  showPanel: PropTypes.bool.isRequired,
  setShowPanel: PropTypes.func.isRequired,
  handleNewDataSet: PropTypes.func.isRequired
};

export default DataSetForm;
