import moment from 'moment';
import { Link } from '../../../util/router';
import { Button, Rating } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { GridActionsCellItem } from '@mui/x-data-grid';

export const dayTimeComparator = (v1, v2) => {
  const d1 = new Date(v1).getTime();
  const d2 = new Date(v2).getTime();
  return d1 - d2;
};

export const VendorStatusOptions = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' }
];

export const VendorStatus = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const AssiciatedEventStatus = {
  PAST_DUE: 'Past Due',
  IN_PROGRESS: 'In Progress'
};

export const DocumentType = {
  WORK_ORDER: 'Work Order',
  MAINTENANCE_RECORD: 'Maintenance Record',
  CALIBRATION_RECORD: 'Calibration Record'
};

export const listVendorColumns = [
  {
    field: 'vendorId',
    headerName: 'ID',
    width: 160,
    renderCell: (params) => {
      return (
        <Link to={`/instrument/settings/vendors/${params.row.id}`}>
          {params.value} {!params.row.vendorId ? <Edit fontSize="small" /> : <></>}
        </Link>
      );
    }
  },
  {
    field: 'companyName',
    headerName: 'Company',
    flex: 1,
    minWidth: 150
  },
  {
    field: 'department',
    headerName: 'Department',
    flex: 1,
    minWidth: 180
  },
  {
    field: 'status',
    headerName: 'Vendor Status',
    flex: 1,
    minWidth: 120,
    valueGetter: (params) => {
      return VendorStatus[params.value];
    }
  },
  {
    field: 'contactName',
    headerName: 'Primary Contact Name',
    flex: 1,
    minWidth: 180
  },
  {
    field: 'contactEmail',
    headerName: 'Primary Email',
    flex: 1,
    minWidth: 200
  },
  {
    field: 'contactPhone',
    headerName: 'Primary Phone',
    flex: 1,
    minWidth: 150
  },
  {
    field: 'rating',
    headerName: 'Vendor Rating',
    flex: 1,
    maxWidth: 150,
    minWidth: 100
  }
];

export const associatedInstrumentColumns = ({
  instrumentStatus,
  handleDeleteAssociatedInstrument,
  hasPermission
}) => {
  const defaultColumns = [
    {
      field: 'name',
      headerName: 'Instrument Name',
      flex: 2,
      renderCell: (params) => {
        return <Link to={`/instrument/detail/${params.row.id}`}>{params.value}</Link>;
      }
    },
    {
      field: 'serialNumber',
      headerName: 'Serial Number',
      flex: 2
    },
    {
      field: 'model',
      headerName: 'Model',
      flex: 2
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2,
      renderCell: (params) => {
        const status = instrumentStatus.find((item) => item.id === params.value);
        if (status) {
          return status.name;
        } else {
          return 'Undefined';
        }
      }
    },
    {
      field: 'dateStarted',
      headerName: 'Next event date',
      sortComparator: dayTimeComparator,
      flex: 2,
      valueGetter: (params) => {
        return params.value ? moment(params.value.seconds * 1000).format('MM/DD/YYYY') : '';
      }
    }
  ];
  return hasPermission
    ? [
        ...defaultColumns,
        {
          field: 'actions',
          type: 'actions',
          headerName: '',
          width: 80,
          cellClassName: 'actions',
          renderCell: (params) => {
            return (
              <>
                <GridActionsCellItem
                  icon={<Delete />}
                  disabled={!params.row.fromInstrument}
                  label="Delete"
                  className="textPrimary"
                  onClick={() => handleDeleteAssociatedInstrument(params.row)}
                  color="inherit"
                />
              </>
            );
          }
        }
      ]
    : defaultColumns;
};

export const associatedEventColumns = ({ instruments, eventStatus }) => [
  {
    field: 'eventNumber',
    headerName: 'ID',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/instrument/events/${params.row.id}`}>{params.value}</Link>;
    }
  },
  {
    field: 'instrumentName',
    headerName: 'Instrument Name',
    flex: 2,
    renderCell: (params) => {
      const data = instruments.find((item) => item.id === params.value);
      return data ? <Link to={`/instrument/detail/${data.id}`}>{data.name}</Link> : '';
    }
  },
  {
    field: 'dateDue',
    headerName: 'Date Due',
    sortComparator: dayTimeComparator,
    flex: 2,
    valueGetter: (params) => {
      return params.value ? moment(params.value.seconds * 1000).format('MM/DD/YYYY') : '';
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 2
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 2,
    renderCell: (params) => {
      const status = eventStatus.find((item) => item.id === params.value);
      if (status) {
        return status.name;
      } else {
        return 'Undefined';
      }
    }
  }
];

export const settingDocumentColumns = ({ documentTypes, actionEditDocument = () => {} }) => [
  {
    field: 'documentName',
    headerName: 'Document Name',
    width: 180,
    renderCell: (params) => {
      return (
        <Button
          sx={{ textAlign: 'left', textTransform: 'none', minWidth: 0 }}
          onClick={() => actionEditDocument({ ...params.row })}
        >
          {params.value}
        </Button>
      );
    }
  },
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 300,
    flex: 1
  },
  {
    field: 'instrumentName',
    headerName: 'Instrument Name',
    minWidth: 180,
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/instrument/detail/${params.row.instrumentId}`}>{params.value}</Link>;
    }
  },
  {
    field: 'createdAt',
    headerName: 'Date Created',
    sortComparator: dayTimeComparator,
    minWidth: 150,
    flex: 1,
    valueGetter: (params) => {
      return params.value ? moment(params.value.seconds * 1000).format('MM/DD/YYYY') : '';
    }
  },
  {
    field: 'documentType',
    headerName: 'Document Type',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => {
      const dType = documentTypes.find((item) => item.id === params.value);
      if (dType) {
        return dType.name;
      } else {
        return 'Undefined';
      }
    }
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 300,
    flex: 1
  }
];

export const instrumentStatusColumns = [
  {
    field: 'name',
    headerName: 'Instrument Status',
    minWidth: 150,
    flex: 1
  }
];

export const instrumentTypeColumns = [
  {
    field: 'name',
    headerName: 'Instrument Type',
    minWidth: 150,
    flex: 1
  }
];

export const instrumentParameterColumns = [
  {
    field: 'name',
    headerName: 'Instrument Parameter',
    minWidth: 150,
    flex: 1
  }
];

export const eventStatusColumns = [
  {
    field: 'name',
    headerName: 'Event Status',
    minWidth: 150,
    flex: 1
  }
];

export const eventTypeColumns = [
  {
    field: 'name',
    headerName: 'Event Type',
    minWidth: 150,
    flex: 1
  }
];

export const documentTypeColumns = [
  {
    field: 'name',
    headerName: 'Document Type',
    minWidth: 150,
    flex: 1
  }
];

export const vendorTypeColumns = [
  {
    field: 'name',
    headerName: 'Vendor Type',
    minWidth: 150,
    flex: 1
  }
];

export const instrumentLocationColumns = [
  {
    field: 'name',
    headerName: 'Location',
    minWidth: 150,
    flex: 1
  }
];

export const instrumentManufacturerColumns = [
  {
    field: 'name',
    headerName: 'Manufacturer',
    minWidth: 150,
    flex: 1
  }
];

export const listNotificationGroupsColumns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 150
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 150
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone Number',
    flex: 1,
    minWidth: 100
  }
];

export const reassignInstrumentColumns = [
  {
    field: 'location',
    headerName: 'Location',
    minWidth: 250,
    flex: 1
  },
  {
    field: 'name',
    headerName: 'Instrument Name',
    minWidth: 180,
    flex: 1,
    renderCell: (params) => {
      return <Link to={`/instrument/detail/${params.row.id}`}>{params.value}</Link>;
    }
  },
  {
    field: 'type',
    headerName: 'Instrument Type',
    minWidth: 180,
    flex: 1
  },
  {
    field: 'parameter',
    headerName: 'Parameter',
    minWidth: 150,
    flex: 1,
    sortable: true
  },
  {
    field: 'serialNumber',
    headerName: 'Serial Number',
    minWidth: 150,
    flex: 1,
    sortable: false
  },
  {
    field: 'manufacturer',
    headerName: 'Manufacturer',
    minWidth: 130,
    flex: 1
  },
  {
    field: 'responsibleParty',
    headerName: 'Responsible Party',
    minWidth: 120,
    flex: 1
  },
  {
    field: 'model',
    headerName: 'Model',
    minWidth: 150,
    flex: 1
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    flex: 1
  }
];
