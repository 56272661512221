import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Meta from '../components/Meta';
import { requireAuth } from '../util/auth';
import OrganizationSection from '../components/OrganizationSection/OrganizationSection';
import PersonnelSection from '../components/PersonnelSection/PersonnelSection';
import MethodSection from '../components/MethodSection/MethodSection';
import SampleSection from '../components/SampleSection/SampleSection';
import InstrumentSection from '../components/InstrumentSection/InstrumentSection';
import Section from '../components/Section';
import ParameterSection from '../components/ParameterSection/ParameterSection';

function PersonnelPage() {
  const [activeTab, setActiveTab] = useState('organization');
  const [loading, setLoading] = useState(false);

  const handleSelect = (e) => {
    setLoading(true);
    setActiveTab(e);
  };

  function renderBottomSection(section) {
    switch (section) {
      case 'personnel':
        return <PersonnelSection />;
      case 'method':
        return <MethodSection />;
      case 'sample':
        return <SampleSection />;
      case 'instrument':
        return <InstrumentSection />;
      case 'parameter':
        return <ParameterSection />;
      default:
        return <OrganizationSection />;
    }
  }

  return (
    <>
      <Meta title="Personnel" />
      <Tabs
        defaultActiveKey="organization"
        id="uncontrolled-tab-example"
        className="tri-tabs"
        onSelect={handleSelect}
      >
        <Tab eventKey="organization" title="Organization" />
        <Tab eventKey="personnel" title="Personnel" />
        <Tab eventKey="method" title="Method" />
        <Tab eventKey="sample" title="Sample" />
        <Tab eventKey="instrument" title="Instrument" />
        <Tab eventKey="parameter" title="Parameter" />
      </Tabs>
      <Section bg="white" textColor="dark" size="lg" bgImage="" bgImageOpacity={1}>
        <div>{renderBottomSection(activeTab)}</div>
      </Section>
    </>
  );
}

export default requireAuth(PersonnelPage);
