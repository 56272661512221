import SearchIcon from '@rsuite/icons/Search';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Input, InputGroup } from 'rsuite';
import Button from 'rsuite/Button';
import SelectPicker from 'rsuite/SelectPicker';
import firebaseApp from '../../../../../util/firebase';
import { getEarlyZ, standardDeviation } from '../../common/utils';
import Round from '../../round';
import PerformanceSummary from './performanceSummary';
import './report.scss';
import ReportGraphics from './reportGraphics';
import { ReportTable } from './reportTable';

const functions = getFunctions(firebaseApp);
const updateILCPData = httpsCallable(functions, 'updateILCPData');

const ReportScreen = ({
  data,
  loading,
  locationData,
  metaData,
  runData,
  updateMetaData,
  updateRunData
}) => {
  const [ilcpData, setIlcpData] = useState({});
  const [round, setRound] = useState('');
  const [method, setMethod] = useState('');
  const [parameter, setParameter] = useState('');
  const [currentRundata, setCurrentRunData] = useState({});
  const [quarterEarly, setQuarterEarly] = useState([]);
  const [halfEarly, setHalfEarly] = useState([]);
  const [threeQuarterEarly, setThreeQuarterEarly] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [runLoading, setRunLoading] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const rows = currentRundata?.rows;
    if (rows && rows.length > 0) {
      const temp = rows
        .map((row) => {
          if (row.omit) return row;

          const metaDataKey =
            `${method}-${parameter}-${round}-${row.facility}-${row.equipment}`.toUpperCase();
          const metadata = metaData[metaDataKey];
          const priorRoundZ = getEarlyZ(row, quarterEarly);
          const priorRound2Z = getEarlyZ(row, halfEarly);
          const priorRound3Z = getEarlyZ(row, threeQuarterEarly);
          let rowData = { ...row };
          if (metadata && metadata.omit) {
            rowData = {
              ...rowData,
              z: ''
            };
          }
          const array = [rowData.z, priorRoundZ, priorRound2Z, priorRound3Z]
            .filter((item) => !!item)
            .map((item) => Number(item));

          const avgZ = array.reduce((a, b) => a + b, 0) / array.length;
          const stdZ = standardDeviation(array, avgZ);
          const facilityName = locationData.get(rowData.facility)?.locationName || rowData.facility;

          const currentData = ilcpData?.[round]?.[method]?.[parameter]?.filter(
            (item) => item.facility === rowData.facility && item.equipment === rowData.equipment
          );

          if (currentData && currentData.length > 0) {
            return {
              ...rowData,
              ...metadata,
              priorRoundZ,
              priorRound2Z,
              priorRound3Z,
              avgZ,
              stdZ,
              facilityName,
              batchId: currentData[0]?.batchId,
              dateSample: currentData[0]?.dateSample,
              dateAnalyzed: currentData[0]?.dateAnalyzed,
              remarks: currentData[0]?.remarks,
              product: currentData[0]?.product
            };
          }
          return {
            ...rowData,
            ...metadata,
            priorRoundZ,
            priorRound2Z,
            priorRound3Z,
            avgZ,
            stdZ,
            facilityName
          };
        })
        .filter((item) => item != null);
      setTableData(temp);
    }
  }, [currentRundata, metaData]);

  useEffect(() => {
    const result = {};
    data.forEach((item) => {
      const { round: itemRound, method: itemMethod, parameter: itemParameter } = item;

      if (!result[itemRound]) {
        result[itemRound] = {};
      }

      if (!result[itemRound][itemMethod]) {
        result[itemRound][itemMethod] = {};
      }

      if (!result[itemRound][itemMethod][itemParameter]) {
        result[itemRound][itemMethod][itemParameter] = [];
      }

      result[itemRound][itemMethod][itemParameter].push(item);
    });
    setIlcpData(result);
    // init default round, method, parameter
    const roundKey = Object.keys(result)[0];
    if (roundKey) {
      setRound(roundKey);
      const methodKey = Object.keys(result?.[roundKey])[0];
      if (methodKey) {
        setMethod(methodKey);
        const parameterKey = Object.keys(result?.[roundKey]?.[methodKey])[0];
        if (parameterKey) {
          setParameter(parameterKey);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (parameter) {
      const runKey = `${method}-${parameter}-${round}`;
      const currentRound = new Round(round);
      setQuarterEarly(runData[`${method}-${parameter}-${currentRound.quarterEarlier()}`]);
      setHalfEarly(runData[`${method}-${parameter}-${currentRound.halfYearEarlier()}`]);
      setThreeQuarterEarly(runData[`${method}-${parameter}-${currentRound.threeQuarterEarlier()}`]);
      setCurrentRunData(runData[runKey]);
    }
  }, [parameter, runData]);

  const firstChartData = () => {
    const headers = ['Plot', 'Lab Results'];
    if (tableData.length === 0) {
      return [headers];
    }
    return tableData
      .filter((item) => !item.omit)
      .filter((item) => !!Number(item.qPlot))
      .filter((item) => !!Number(item.result))
      .sort((a, b) => Number(a.qPlot) - Number(b.qPlot))
      .map((item) => [Number(item.qPlot), Number(item.result)])
      .reduce((acc, val) => [...acc, val], [headers]);
  };

  const runRoundData = async (input, r, m, p) => {
    const dataBlock = [];
    const activeArray = input.slice(0, 1000);

    for (let i = 0; i < activeArray.length; i += 1) {
      const dataObj = activeArray[i];
      if (dataObj.result || dataObj.result === 0) {
        dataBlock.push(dataObj.facility);
        dataBlock.push(r);
        dataBlock.push(dataObj.batchId);
        dataBlock.push(dataObj.result);
        dataBlock.push(dataObj.dateSample);
        dataBlock.push(dataObj.dateAnalyzed);
        dataBlock.push(dataObj.remarks);
        dataBlock.push(dataObj.product);
        dataBlock.push(dataObj.equipment);
        dataBlock.push(m);
        dataBlock.push(p);
        dataBlock.push(dataObj.omit ? 'Y' : 'N');
      }
    }

    if (dataBlock.length > 0) {
      const request = {
        scriptId: 'AKfycbw9IymrhUArPGptdzO1ZyZPHFF6ZURipnil0uxCLiJOlXomXerLOB79ZrNTGXuKywIFBQ',
        dataBlock,
        sheetId: '18vA4407ZqgBIyGu-sP_ixJK6fAO96hqQ3PvYcD43Ks4',
        parameterBlock: [],
        metaDataBlock: [],
        numCols: 12,
        minDate: '',
        maxDate: ''
      };
      const res = await updateILCPData(request);
      if (res) {
        if (res.data && res.data.status === 200) {
          const { body } = res.data;
          const { dataReturn, perf, hist } = body;
          const response = {
            rows: dataReturn.map((dr) => {
              return {
                facility: dr[0],
                equipment: dr[1],
                result: dr[2],
                deviation: dr[3],
                z: dr[4],
                notes: dr[5],
                qPlot: dr[6]
              };
            }),
            summary: {
              conformingResults: perf[0][0],
              resultsUsed: perf[1][0],
              average: perf[2][0],
              stDev: perf[3][0],
              astmR: perf[4][0],
              theseDataR: perf[5][0],
              tpi: perf[6][0],
              andersonDarling: perf[7][0]
            },
            histogram: hist.map((h) => {
              return {
                bin: h[0],
                percentile: h[1],
                high: h[2],
                count: h[3],
                binCount: h[4],
                zScore: h[5],
                normalCurve: h[6],
                hPDF: h[7]
              };
            })
          };
          const key = `${m}-${p}-${r}`.toUpperCase();
          updateRunData(response, key);
        }
      }
    }
  };

  const runOneRound = async (input) => {
    setRunLoading(true);
    await runRoundData(input, round, method, parameter);
    setRunLoading(false);
  };

  const mapDataToRun = (value) => {
    const result = value
      .map((row) => {
        if (row.omit) return row;

        const metaDataKey =
          `${method}-${parameter}-${round}-${row.facility}-${row.equipment}`.toUpperCase();
        const metadata = metaData[metaDataKey];
        let rowData = { ...row };
        if (metadata && metadata.omit) {
          rowData = {
            ...rowData,
            z: ''
          };
        }
        const currentData = ilcpData?.[round]?.[method]?.[parameter]?.filter(
          (item) => item.facility === rowData.facility && item.equipment === rowData.equipment
        );

        if (currentData && currentData.length > 0) {
          return {
            ...rowData,
            ...metadata,
            batchId: currentData[0]?.batchId,
            dateSample: currentData[0]?.dateSample,
            dateAnalyzed: currentData[0]?.dateAnalyzed,
            remarks: currentData[0]?.remarks,
            product: currentData[0]?.product
          };
        }
        return {
          ...rowData,
          ...metadata
        };
      })
      .filter((item) => item != null);
    return result;
  };

  const runThreeRound = async () => {
    setRunLoading(true);
    const currentRound = new Round(round);

    const halfEarlyInput = mapDataToRun(halfEarly.rows || []);
    await runRoundData(halfEarlyInput, currentRound.halfYearEarlier(), method, parameter);

    const quarterEarlyInput = mapDataToRun(quarterEarly.rows || []);
    await runRoundData(quarterEarlyInput, currentRound.quarterEarlier(), method, parameter);

    await runRoundData(tableData, round, method, parameter);
    setRunLoading(false);
  };

  const updateRow = (rowData) => {
    const updateTable = tableData.map((item) => {
      if (item.facility === rowData.facility && item.equipment === rowData.equipment) {
        return rowData;
      }
      return item;
    });
    setTableData(updateTable);

    runOneRound(updateTable);

    const key =
      `${method}-${parameter}-${round}-${rowData.facility}-${rowData.equipment}`.toUpperCase();
    const metadata = {
      ...metaData[key],
      key,
      omit: rowData.omit || false
    };

    updateMetaData(metadata);
  };

  const updateExplanation = (rowData) => {
    const key =
      `${method}-${parameter}-${round}-${rowData.facility}-${rowData.equipment}`.toUpperCase();
    const metadata = {
      ...metaData[key],
      key,
      explanation: rowData.explanation || ''
    };

    updateMetaData(metadata);
  };

  const onChangeExplanation = (rowData) => {
    setTableData(
      tableData.map((item) => {
        if (item.facility === rowData.facility && item.equipment === rowData.equipment) {
          return rowData;
        }
        return item;
      })
    );
  };

  const getDataTable = () => {
    return tableData.filter(
      (item) => !search || item.facilityName.toLowerCase().includes(search.toLocaleLowerCase())
    );
  };

  return (
    <div className="contentRepost">
      <div className="contentSelect">
        <div className="contentSelectView">
          <label>Round</label>
          <SelectPicker
            loading={loading}
            className="select"
            data={Object.keys(ilcpData).map((item) => ({
              label: item,
              value: item
            }))}
            value={round}
            onChange={(v) => {
              setRound(v);
              setMethod('');
              setParameter('');
            }}
          />
        </div>
        <div className="contentSelectView">
          <label>Method</label>
          <SelectPicker
            loading={loading}
            disabled={!round}
            className="select"
            data={Object.keys(ilcpData?.[round] ?? {}).map((item) => ({
              label: item,
              value: item
            }))}
            value={method}
            onChange={(v) => {
              setMethod(v);
              setParameter('');
            }}
          />
        </div>
        <div className="contentSelectView">
          <label>Parameter</label>
          <SelectPicker
            loading={loading}
            disabled={!method}
            className="select"
            data={Object.keys(ilcpData?.[round]?.[method] ?? {}).map((item) => ({
              label: item,
              value: item
            }))}
            value={parameter}
            onChange={(v) => setParameter(v)}
          />
        </div>
        <Button
          size="md"
          appearance="primary"
          style={{
            minWidth: '60px',
            alignSelf: 'end'
          }}
          onClick={() => {
            runOneRound(tableData);
          }}
        >
          Run
        </Button>
        <Button
          size="md"
          appearance="primary"
          style={{
            minWidth: '120px',
            alignSelf: 'end'
          }}
          onClick={() => {
            runThreeRound();
          }}
        >
          Run 3 Rounds
        </Button>
      </div>

      <div className="contentParamtG">
        <div>
          <label className="title" style={{ marginBottom: '16px' }}>
            Data Report
          </label>
        </div>
      </div>
      <PerformanceSummary runData={currentRundata} />
      <InputGroup inside style={{ width: '400px', marginTop: '10px', marginBottom: '10px' }}>
        <Input
          placeholder="Facility"
          value={search}
          onChange={(value) => {
            setSearch(value);
          }}
        />
        <InputGroup.Button>
          <SearchIcon onClick={() => {}} />
        </InputGroup.Button>
      </InputGroup>
      <ReportTable
        tableData={getDataTable()}
        round={round}
        updateRow={updateRow}
        updateExplanation={updateExplanation}
        onChangeExplanation={onChangeExplanation}
        loading={runLoading}
      />

      <ReportGraphics firstChartData={firstChartData} histogram={currentRundata?.histogram ?? []} />
    </div>
  );
};

ReportScreen.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  locationData: PropTypes.instanceOf(Map),
  runData: PropTypes.object,
  metaData: PropTypes.object,
  updateMetaData: PropTypes.func,
  updateRunData: PropTypes.func
};

ReportScreen.defaultProps = {
  data: [],
  loading: false,
  locationData: new Map(),
  runData: {},
  metaData: {},
  updateMetaData: () => {},
  updateRunData: () => {}
};

export default ReportScreen;
