import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { renderMUILoadingButton } from '../../../util/util';

const ModalAddFilter = ({ title, show, handleClose, handleSave, data, disabled }) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {
      id: data?.id || '',
      name: data?.name || ''
    }
  });

  useEffect(() => {
    reset({ id: data?.id || '', name: data?.name || '' });
  }, [show]);

  const onSubmit = async (data) => {
    setLoading(true);
    await handleSave(data);
    setLoading(false);
  };
  return (
    <Modal key={title} size="md" show={show} onHide={handleClose} backdrop="static">
      <form onSubmit={handleSubmit(onSubmit)} onReset={handleClose}>
        <Modal.Header closeButton>{title}</Modal.Header>

        <Modal.Body>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control name="name" {...register('name', { required: true })} />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="text" type="reset">
            Cancel
          </Button>
          &nbsp; &nbsp;
          {renderMUILoadingButton({
            buttonText: 'Save',
            isLoading: loading,
            disabled: disabled,
            variant: 'contained',
            type: 'submit'
          })}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ModalAddFilter;
