import Table from 'rsuite/Table';
import React from 'react';
import PropTypes from 'prop-types';
import './LowerTable.scss';

const LowerTable = ({ body, loadingChartData, title, fullHeight = false, children }) => {
  const rowHeight = 50;

  const rowsCounter = fullHeight ? Math.max(body.length, 1) : 10;

  const headerHeight = 40;
  const tableHeight = rowHeight * rowsCounter + headerHeight;

  return (
    <div className="contentTableOne">
      {title && (
        <div className="contentTitle">
          <label className="title">{title}</label>
        </div>
      )}
      <Table
        data={body}
        bordered
        cellBordered
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        loading={loadingChartData}
        height={tableHeight}
      >
        {children}
      </Table>
    </div>
  );
};

LowerTable.propTypes = {
  body: PropTypes.arrayOf(PropTypes.shape({})),
  loadingChartData: PropTypes.bool,
  title: PropTypes.string,
  fullHeight: PropTypes.bool,
  children: PropTypes.node
};

LowerTable.defaultProps = {
  fullHeight: false,
  body: [],
  loadingChartData: false,
  title: '',
  children: null
};

export default LowerTable;
