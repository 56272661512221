/* eslint-disable no-alert */
import { savePrograms, updateIlcpData } from '../../../util/db';

const rowsToRunThrough = 600;

function getRow(expression, worksheet, column, startAt) {
  let theRow = -1;
  const regex = new RegExp(expression, 'i');
  for (let iRow = startAt; iRow <= rowsToRunThrough; iRow += 1) {
    const cellName = column + iRow;
    if (worksheet[cellName] && worksheet[cellName].v) {
      const tempStr = String(worksheet[cellName].v);
      if (tempStr.match(regex)) {
        theRow = iRow;
        break;
      }
    }
  }
  return theRow;
}

function setCellVal(worksheet, currCol, currRow, dataObjIn, elt) {
  const dataObj = dataObjIn;
  const cellName = String(currCol) + String(currRow);
  if (worksheet[cellName] && worksheet[cellName].v) {
    const value = worksheet[cellName].v;
    dataObj[elt] = typeof value === 'string' ? value.trim() : value;
  }
  return dataObj;
}

function getNextCol(iColIn) {
  let iCol = iColIn;
  if (String(iCol).length === 1) {
    if (iCol === 'Z') {
      iCol = 'AA';
    } else {
      iCol = String.fromCharCode(String(iCol).charCodeAt(0) + 1);
    }
  } else {
    const lastChar = String(iCol).substr(String(iCol).length - 1);
    if (lastChar === 'Z') {
      iCol = `${String.fromCharCode(String(iCol).charCodeAt(0) + 1)}A`;
    } else {
      iCol =
        String.fromCharCode(String(iCol).charCodeAt(0)) +
        String.fromCharCode(String(iCol).charCodeAt(1) + 1);
    }
  }
  return iCol;
}

function getLastCol(worksheet, startCol, testRow) {
  let iCol = startCol;
  while (iCol !== 'BZ') {
    const cellName = iCol + testRow;
    if (worksheet[cellName] && worksheet[cellName].v) {
      const tempStr = String(worksheet[cellName].v);
      if (tempStr === '') {
        break;
      }
    } else {
      break;
    }
    iCol = getNextCol(iCol);
  }
  return iCol;
}

function mergeLabs(ilcpData, labs) {
  const storedLabs = ilcpData.labs || [];

  const labNames = labs.map((lab) => lab.name);

  const allLabs = new Set([...storedLabs, ...labNames]);

  return { ...ilcpData, labs: [...allLabs] };
}

function parseCodeLabs(workbook) {
  const codeSheets = workbook.Sheets.CodeLabs;
  const maxValue = Number(codeSheets['!ref'].split(':')[1].replace(/\D/g, ''));

  const labs = [];
  for (let currRow = 2; currRow <= maxValue; currRow += 1) {
    const codeCell = `A${currRow}`;
    const nameCell = `B${currRow}`;
    const name = codeSheets[nameCell].v;
    if (!name) break;
    const code = Number(codeSheets[codeCell].v);
    labs.push({ name, code });
  }
  return labs;
}

function saveDataToFirebase(dataId, dataObj, ilcpData, isASTM) {
  const programData = Object.keys(dataObj).flatMap((label) => {
    if (Array.isArray(dataObj[label])) {
      return dataObj[label].map((result) => ({
        ...result,
        label,
        programId: ilcpData.programId,
        round: dataId
      }));
    }

    return {
      ...dataObj[label],
      label,
      programId: ilcpData.programId,
      round: dataId
    };
  });
  if (isASTM) {
    // update headers

    const dataKeys = Object.keys(dataObj);

    const missingHeaders = dataKeys.filter(
      (key) => !ilcpData.header.find((h) => h.col === key && h.field === key)
    );

    if (missingHeaders.length > 0) {
      const newHeaders = missingHeaders.map((key) => ({
        col: key,
        field: key,
        method: ' ',
        parameter: ' '
      }));

      const updatedIlcpData = {
        ...ilcpData,
        header: [...ilcpData.header, ...newHeaders]
      };

      updateIlcpData(updatedIlcpData);
    }
  }
  savePrograms(programData);
} // saveDataToFirebase

function getFacilitiesId(workbook, lab, ilcdpData) {
  if (workbook.Sheets.CodeLabs) return parseCodeLabs(workbook, ilcdpData);
  // eslint-disable-next-line no-alert
  let facilityId = prompt('Please enter your facility id for this workbook', '');
  while (facilityId === '') {
    // eslint-disable-next-line no-alert
    alert('You must specify a facility id.');
    // eslint-disable-next-line no-alert
    facilityId = prompt('Please enter your facility id for this workbook', '');
  }
  return [{ code: parseInt(facilityId.trim(), 10), name: lab }];
}

function parseXLSXASTM(workbook, ilcdpData, lab) {
  const dataObj = {};
  let dataId;

  const facilities = getFacilitiesId(workbook, lab, ilcdpData);
  // eslint-disable-next-line no-alert

  let totalTestCount = 0;
  const totalFoundCount = {};

  // iterate through the index links to populate the round; going to $scope.rowsToRunThrough as a swag
  const indexSheet = workbook.Sheets.Index;
  for (let currRow = 1; currRow <= rowsToRunThrough; currRow += 1) {
    const linkCell = `D${currRow}`;
    const methodCell = `B${currRow}`;
    const fieldCell = `C${currRow}`;
    let testId = '';
    if (indexSheet[methodCell] && indexSheet[fieldCell]) {
      testId = `${String(indexSheet[methodCell].v)} ${String(indexSheet[fieldCell].v)}`;
    }
    testId = testId
      .replace('/', ' ')
      .replace(/[.#$/[\]]/g, '')
      .replace(/[^\x20-\x7E]+/g, '')
      .trim();
    if (indexSheet[linkCell] && indexSheet[linkCell].l && indexSheet[linkCell].l.Target) {
      const target = indexSheet[linkCell].l.Target.replace(/^#/, '')
        .replace(/^'/, '')
        .replace(/^([0-9_\-A-Za-z()]+)!.*$/, '$1')
        .replace('!A1', '')
        .replace(/'$/, '')
        .trim();
      if (workbook.Sheets[target]) {
        // this is a valid pointer to a valid worksheet
        totalTestCount += 1;
        const theSheet = workbook.Sheets[target];

        // set data id if it's blank and we have a B6
        if (!dataId && theSheet.B6 && theSheet.B6.v) {
          let did = theSheet.B6.v;
          did = did.replace(/^.*\D+([0-9]+)$/, '$1');
          if (did !== '') {
            did = parseInt(did, 10);
            if (did > 0) {
              dataId = did;
            }
          }
        }

        //  start by looking for the facility id, from B25 down to the "Legend" cell
        const legendRow = getRow('Legend', theSheet, 'B', 25);
        const performanceSummaryRow = getRow('Performance Summary', theSheet, 'B', 9);
        if (!dataObj[testId]) {
          dataObj[testId] = [];
        }
        for (let i = 25; i < legendRow; i += 1) {
          const cellName = `B${i}`;
          if (theSheet[cellName]) {
            const cellContent = `${theSheet[cellName].v}`.trim();
            const fNum = parseInt(cellContent, 10);
            const facility = facilities.find((f) => f.code === fNum);
            if (facility) {
              totalFoundCount[fNum] = totalFoundCount[fNum] + 1 || 0;

              // assemble record:
              const zResult = {};
              setCellVal(theSheet, 'C', i, zResult, 'result');
              setCellVal(theSheet, 'E', i, zResult, 'z');
              setCellVal(theSheet, 'F', i, zResult, 'o');
              zResult.labCode = fNum;
              zResult.labName = facility.name;
              if (zResult.o === 'R') {
                zResult.o = 'O';
              } else {
                zResult.o = '';
              }
              setCellVal(theSheet, 'D', performanceSummaryRow + 4, zResult, 'mean');
              setCellVal(theSheet, 'D', performanceSummaryRow + 5, zResult, 'stdev');
              setCellVal(theSheet, 'D', performanceSummaryRow + 3, zResult, 'count');
              setCellVal(theSheet, 'D', performanceSummaryRow + 7, zResult, 'r');
              setCellVal(theSheet, 'D', performanceSummaryRow + 6, zResult, 'rastm');
              dataObj[testId].push(zResult);
            }
          } // if this cell exists
        } // iterate through result rows
      } // if the pointed-to sheet exists
    } // if there is a link in the cell in column D
  } // for 1 to $scope.rowsToRunThrough on the rows in the index sheet
  if (dataId) {
    // eslint-disable-next-line no-alert
    alert(
      `Found ${Object.keys(totalFoundCount)
        .map((k) => `Lab ${k} has ${totalFoundCount[k]} tests;`)
        .reduce((a, b) => a + b, '')} for round ${dataId} (of ${totalTestCount} total)`
    );

    saveDataToFirebase(dataId, dataObj, mergeLabs(ilcdpData, facilities), true);
  }
}

function parseXLSXGL(workbook, ilcpData) {
  const dataObj = {};
  let dataId;

  // find Husky and values in Data sheet:
  const dataSheet = workbook.Sheets.Data;
  if (dataSheet.B4) {
    dataId = dataSheet.B4.v;
    dataId = dataId.replace(/[^0-9]/g, '');
  }
  const zSheet = workbook.Sheets['Z-scores'];
  const myIdRow = getRow('D[0-9]', dataSheet, 'C', -1);
  const resultRow = getRow('husky(.*)lima', dataSheet, 'B', -1);
  const meanRow = getRow('mean', dataSheet, 'B', -1);
  const stdDevRow = getRow('std', dataSheet, 'B', -1);
  const countRow = getRow('of results', dataSheet, 'B', -1);
  const rRow = countRow + 1;
  const rastmRow = rRow + 1;
  const lastCol = getLastCol(dataSheet, 'C', myIdRow);

  let currCol = 'C';
  while (currCol !== lastCol) {
    const testIdCell = String(currCol) + String(myIdRow);
    if (dataSheet[testIdCell]) {
      const testId = String(dataSheet[testIdCell].v);
      dataObj[testId] = {};
      setCellVal(dataSheet, currCol, resultRow, dataObj[testId], 'result');
      setCellVal(zSheet, currCol, resultRow, dataObj[testId], 'z');
      if (dataObj[testId].z && (dataObj[testId].z < -2 || dataObj[testId].z > 2)) {
        dataObj[testId].o = 'O';
      }
      setCellVal(dataSheet, currCol, meanRow, dataObj[testId], 'mean');
      setCellVal(dataSheet, currCol, stdDevRow, dataObj[testId], 'stdev');
      setCellVal(dataSheet, currCol, countRow, dataObj[testId], 'count');
      setCellVal(dataSheet, currCol, rRow, dataObj[testId], 'r');
      setCellVal(dataSheet, currCol, rastmRow, dataObj[testId], 'rastm');
    }
    currCol = getNextCol(currCol);
  }
  if (dataId) {
    saveDataToFirebase(dataId, dataObj, ilcpData, false);
  }
}

function parseCSVInnotec(dataIn, ilcdpData, round) {
  // data can come in wrapped in ="", so strip that off
  const data = dataIn.map((row) =>
    row.map((cell) => {
      if (cell.match(/^="(.*)"$/)) {
        return cell.replace(/^="(.*)"$/, '$1');
      }
      return cell;
    })
  );
  const dataObj = {};
  let facilityId = '';
  // eslint-disable-next-line no-alert
  facilityId = prompt('Please enter your facility id for this workbook', '');

  while (facilityId === '') {
    // eslint-disable-next-line no-alert
    alert('You must specify a facility id.');
    // eslint-disable-next-line no-alert
    facilityId = prompt('Please enter your facility id for this workbook', '');
  }
  // clean facilityId
  facilityId = parseInt(facilityId.trim(), 10);
  const dataId = String(round).replace(/[^0-9]/g, ''); // round

  let totalFoundCount = 0;
  // each column is a test, each row is a result
  const row2 = data[1];
  const row3 = data[2];
  const rowFacility = data.find((row) => String(row[0]) === String(facilityId));
  const rowMean = data.find((row) => ['mean', 'average'].includes(String(row[0]).toLowerCase()));
  const rowCount = data.find((row) => ['count'].includes(String(row[0]).toLowerCase()));
  const rowSD = data.find((row) => ['standarddeviation'].includes(String(row[0]).toLowerCase()));
  const rowRASTM = data.find((row) => ['rpub'].includes(String(row[0]).toLowerCase()));
  const rowRData = data.find((row) => ['rdat'].includes(String(row[0]).toLowerCase()));

  if (!rowFacility) {
    alert(`could not find facility ${facilityId}`);
    return;
  }

  if (!rowMean) {
    alert(`could not find mean row`);
    return;
  }

  if (!rowCount) {
    alert(`could not find count row`);
    return;
  }

  if (!rowSD) {
    alert(`could not find standard deviation row`);
    return;
  }

  if (!rowRASTM) {
    alert(`could not find rASTM (rpub) row`);
    return;
  }

  if (!rowRData) {
    alert(`could not find rdata row`);
    return;
  }

  if (!row2) {
    alert(`could not find row 2`);
    return;
  }

  if (!row3) {
    alert(`could not find row 3`);
    return;
  }

  for (let i = 1; i < row2.length; i += 1) {
    const testId = `${row3[i]} ${row2[i]}`;
    dataObj[testId] = {
      result: parseFloat(rowFacility[i]),
      mean: parseFloat(rowMean[i]),
      count: parseFloat(rowCount[i]),
      stdev: parseFloat(rowSD[i]),
      rastm: parseFloat(rowRASTM[i]),
      r: parseFloat(rowRData[i]),
      round: parseInt(dataId, 10) // round
    };
    dataObj[testId].z = (dataObj[testId].result - dataObj[testId].mean) / dataObj[testId].stdev;
    dataObj[testId].o = '';
    totalFoundCount += 1;
  }
  if (dataId) {
    // eslint-disable-next-line no-alert
    alert(`Found ${totalFoundCount} tests for round ${dataId}`);
    saveDataToFirebase(dataId, dataObj, ilcdpData, true);
  }
} // parseCSVInnotec

const readILCPFile = ({ type, workbook, ilcpData, round, lab }) => {
  if (type === 'xlsx') {
    /* Build data object from this File */
    if (workbook.Sheets) {
      // valid workbook, so identify which one:
      if (workbook.Sheets.Index) {
        parseXLSXASTM(workbook, ilcpData, lab);
      } else if (workbook.Sheets.Data) {
        parseXLSXGL(workbook, ilcpData);
      } else if (workbook.Sheets.CodeLabs) {
        const labs = parseCodeLabs(workbook);

        updateIlcpData(mergeLabs(ilcpData, labs));
      } else {
        // eslint-disable-next-line no-alert
        alert('Invalid/unknown ILCP format.');
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('No sheets (tabs) found in this workbook.');
    }
  } else {
    // CSV
    // eslint-disable-next-line no-lonely-if
    if (Array.isArray(workbook)) {
      parseCSVInnotec(workbook, ilcpData, round);
    } else {
      // eslint-disable-next-line no-alert
      alert('No data found in the supplied file.');
    }
  }
};

export default readILCPFile;
