import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { Link } from '../../../util/router';
import { renderMUILoadingButton } from '../../../util/util';

const ModalAddPerson = ({ show, handleClose, handleSave, groups = [] }) => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register, reset } = useForm({
    defaultValues: {}
  });

  useEffect(() => {
    reset({});
  }, [show]);

  const onSubmit = async (data) => {
    setLoading(true);
    await handleSave(data);
    setLoading(false);
  };

  return (
    <>
      <Modal size="md" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>Add Person</Modal.Header>
          <Modal.Body>
            {groups.length
              ? groups.map((item) => (
                  <Form.Group key={`${item.id}-${Math.random() * 1000}`}>
                    <Form.Check label={`${item.name}`} {...register(item.id)} />
                  </Form.Group>
                ))
              : ''}
            <Form.Group>
              <Button component={Link} to="/instrument/settings/notification-groups">
                + Add new group
              </Button>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            &nbsp; &nbsp;
            {renderMUILoadingButton({
              buttonText: 'Save',
              isLoading: loading,
              variant: 'contained',
              type: 'submit'
            })}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalAddPerson;
