import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from './BackgroundImage';
import './Section.scss';

function Section(props) {
  const {
    bg,
    textColor,
    bgImage,
    bgImageOpacity,
    bgImageRepeat,
    className,
    children,
    size,
    ...otherProps
  } = props;

  return (
    <section
      className={`SectionComponent py-1 position-relative${bg ? ` bg-${bg}` : ''}${
        textColor ? ` text-${textColor}` : ''
      }${className ? ` ${className}` : ''}`}
      {...otherProps}
    >
      {bgImage && (
        <BackgroundImage image={bgImage} opacity={bgImageOpacity} repeat={bgImageRepeat} />
      )}
      <div
        className={`${['md', 'lg'].includes(size) ? ' container py-md-1' : ''}${
          size === 'lg' ? ' container my-md-1' : ''
        }`}
      >
        {children}
      </div>
    </section>
  );
}

Section.propTypes = {
  bg: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.string,
  bgImage: PropTypes.string,
  bgImageOpacity: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  bgImageRepeat: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
};

Section.defaultProps = {
  bg: null,
  textColor: null,
  size: null,
  bgImage: null,
  bgImageOpacity: null,
  title: null,
  subtitle: null,
  bgImageRepeat: null,
  className: null,
  children: null
};

export default Section;
