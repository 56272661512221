import React from 'react';
import Meta from '../components/Meta';
import ProductReport from '../components/ILCPSection/ILCPProduct/ProductReport';
import { requireAuth } from '../util/auth';

const ReportPage = () => {
  return (
    <>
      <Meta title="ILCP" />
      <ProductReport />
    </>
  );
};

export default requireAuth(ReportPage);
