import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  Brush,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import Form from 'react-bootstrap/Form';
import ChartContext from './DataEntrySection/DataEntryProvider';
import { getSigDigitsUtil } from '../util/util';

function SQCAnalystChart({ analysts, analystsDots, data, lines, syncId, xAxis, yMin, yMax }) {
  const formatXAxis = (tickItem) => {
    return String(data[tickItem]?.date) || '';
  };
  const { currentControlStrategyConfigurations } = useContext(ChartContext);
  const [analystsArray, setAnalystsArray] = useState(analysts);

  const formatNothing = () => {
    return '';
  };

  const renderSigDigits = (stringValue) => {
    return getSigDigitsUtil(stringValue, currentControlStrategyConfigurations.chartSigDigits);
  };

  const formatYAxis = (tickItem) => {
    return renderSigDigits(tickItem);
  };

  const handleCheckboxClick = (e) => {
    const updatedAnalystsArray = analystsArray.map((analyst) => {
      if (analyst.name === e.target.id) {
        return {
          ...analyst,
          checked: e.target.checked
        };
      }
      return analyst;
    });
    setAnalystsArray(updatedAnalystsArray);
  };

  function renderCurrentPersonnelCheckboxes() {
    const checkboxesToReturn = [];
    analystsArray.forEach((analyst, index) => {
      checkboxesToReturn.push(
        <div key={uuidv4()} className="checkbox-buttons">
          <div
            style={{
              marginTop: '5%',
              height: '13px',
              width: '13px',
              backgroundColor: analystsArray[index].color,
              borderRadius: '100%',
              marginRight: '4px'
            }}
          />
          {analyst.name}
          <Form.Check
            checked={analystsArray[index].checked}
            onChange={handleCheckboxClick}
            id={analyst.name}
          />
        </div>
      );
    });
    return checkboxesToReturn;
  }

  const CustomizedDot = ({ cx, cy, payload, dataKey }) => {
    const dotToReturn = [];
    if (dataKey === 'resultOmits') {
      const currentAnalyst = analystsDots.find((analyst) => analyst.analyst === payload.personnel);
      const currentIndex = analystsArray.findIndex(
        (analyst) => analyst.name === currentAnalyst.analyst
      );
      if (analystsArray[currentIndex].checked) {
        dotToReturn.push(
          <svg
            x={cx - 3}
            y={cy - 3}
            width={15}
            height={15}
            key={uuidv4()}
            fill={currentAnalyst.color}
            viewBox="0 0 1024 1024"
          >
            <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z" />
          </svg>
        );
      }
    }
    return dotToReturn;
  };

  return (
    <>
      <div className="checkbox-buttons-container">{renderCurrentPersonnelCheckboxes()}</div>
      <ResponsiveContainer key={uuidv4()} height={400}>
        <LineChart
          data={data}
          key={uuidv4()}
          syncId={syncId}
          margin={{
            top: 5,
            right: 20,
            bottom: 15,
            left: 20
          }}
        >
          {lines
            .filter((l) => !l.reference)
            .map((line) => (
              <Line
                type="linear"
                key={line.yAxis || line.name}
                dataKey={line.yAxis}
                isAnimationActive={false}
                stroke={line.color}
                name={line.name}
                dot={<CustomizedDot key={uuidv4()} analystsDots={analystsDots} />}
              />
            ))}

          {lines
            .filter((l) => l.reference)
            .map((line) => (
              <ReferenceLine
                y={line.overrideValue || (data[0] && data[0][line.yAxis])}
                key={line.yAxis || line.name}
                stroke={line.color}
                label={line.label}
              />
            ))}

          <CartesianGrid stroke="#ccc" key={uuidv4()} strokeDasharray="5 5" />
          <XAxis dataKey={xAxis} key={uuidv4()} tickFormatter={formatXAxis} />
          <YAxis type="number" key={uuidv4()} domain={[yMin, yMax]} tickFormatter={formatYAxis} />
          <Brush dataKey={xAxis} key={uuidv4()} ticketFormatter={formatNothing} />
          <Legend verticalAlign="top" key={uuidv4()} height={36} />
          <Tooltip key={uuidv4()} formatter={(value) => `${value}`} />
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

SQCAnalystChart.propTypes = {
  analysts: PropTypes.arrayOf(PropTypes.object).isRequired,
  analystsDots: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  lines: PropTypes.arrayOf(PropTypes.object).isRequired,
  xAxis: PropTypes.string.isRequired,
  syncId: PropTypes.string.isRequired,
  yMin: PropTypes.number.isRequired,
  yMax: PropTypes.number.isRequired
};

export default SQCAnalystChart;
