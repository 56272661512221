import React, { useState } from 'react';
import './PersonnelSection.scss';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { v4 as uuidv4 } from 'uuid';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import { SCREEN_PERMISSION, UPDATE_TYPE, CUSTOM_FIELD } from '../../util/Constant';
import './PersonnelSection.scss';

const PermissionConfig = ({
  showPermissionModal,
  setShowPermissionModal,
  permissionData,
  setPermissionData
}) => {
  const [table, setTable] = useState(null);
  const [tableValue, setTableValue] = useState('');
  const [custom, setCustom] = useState(false);
  const [customData, setCustomData] = useState([]);

  const addTable = () => {
    setPermissionData({ ...permissionData, [tableValue]: table });
    setShowPermissionModal(false);
  };

  const handleScreenChange = (e) => {
    const value = e.target.value;
    setTableValue(value);
    if (value && permissionData[value]) {
      setTable(permissionData[value]);
      const customValue = permissionData[value]?.update?.type;
      setCustom(customValue === UPDATE_TYPE.CUSTOM);
      if (customValue === UPDATE_TYPE.CUSTOM) {
        setCustomData(CUSTOM_FIELD[value] || []);
      }
    } else {
      setTable({
        create: false,
        delete: false,
        update: { type: UPDATE_TYPE.NONE },
        view: false
      });
    }
  };

  const onCheckBoxChange = (e, type) => {
    const value = e.target.checked;
    setTable({ ...table, [type]: value });
  };

  const onUpdateChange = (e, type) => {
    const value = e.target.checked;
    setCustom(type === UPDATE_TYPE.CUSTOM && value);
    setTable({ ...table, update: { ...table['update'], type: type } });
    if (value && type === UPDATE_TYPE.CUSTOM) {
      const a = CUSTOM_FIELD[tableValue];
      setCustomData(a);
    }
  };

  const onAllowFieldChange = (e, type) => {
    const value = e.target.checked;
    let listField;
    if (value) {
      listField = table['update'].fieldAllow;
      listField.push(type);
    } else {
      listField = table['update'].fieldAllow.filter((item) => {
        return item !== type;
      });
    }
    setTable({
      ...table,
      update: { ...table['update'], fieldAllow: listField }
    });
  };

  return (
    <Modal size="xl" show={showPermissionModal} onHide={() => setShowPermissionModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Contribution Permission Config</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="manage-data-modal">
          <Form.Group>
            <Form.Label>Screen</Form.Label>
            <Form.Select
              aria-label="Default select example"
              onChange={handleScreenChange}
              value={tableValue}
            >
              <option disabled selected value="">
                Select Screen
              </option>
              {SCREEN_PERMISSION.map((item) => {
                return (
                  <option key={uuidv4()} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Permission</Form.Label>
            <div className="permission-row">
              <span className="permission-title">View</span>
              <Form.Check
                disabled={table === null}
                checked={table?.view || false}
                onChange={(e) => onCheckBoxChange(e, 'view')}
                id="view"
              />
            </div>
            <div className="permission-row">
              <span className="permission-title">Create</span>
              <Form.Check
                disabled={table === null}
                checked={table?.create || false}
                onChange={(e) => onCheckBoxChange(e, 'create')}
                id="create"
              />
            </div>
            <div className="permission-row">
              <span className="permission-title">Delete</span>

              <Form.Check
                disabled={table === null}
                checked={table?.delete || false}
                onChange={(e) => onCheckBoxChange(e, 'delete')}
              />
            </div>
            <div className="permission-row">
              <span className="permission-title">Update</span>
              <div>
                <input
                  disabled={table === null}
                  class="form-check-input"
                  type="radio"
                  name="update"
                  checked={table?.update?.type === UPDATE_TYPE.NONE || false}
                  id="none"
                  onChange={(e) => {
                    onUpdateChange(e, UPDATE_TYPE.NONE);
                  }}
                />
                <label className="checkbox-title" for="None">
                  None
                </label>
              </div>
              <div>
                <input
                  disabled={table === null}
                  class="form-check-input"
                  type="radio"
                  name="update"
                  id="all"
                  checked={table?.update?.type === UPDATE_TYPE.ALL || false}
                  onChange={(e) => {
                    onUpdateChange(e, UPDATE_TYPE.ALL);
                  }}
                />
                <label className="checkbox-title" for="all">
                  All
                </label>
              </div>
              <div class="form-check">
                <input
                  disabled={table === null}
                  class="form-check-input"
                  type="radio"
                  name="update"
                  id="custom"
                  checked={table?.update?.type === UPDATE_TYPE.CUSTOM || false}
                  onChange={(e) => {
                    onUpdateChange(e, UPDATE_TYPE.CUSTOM);
                  }}
                />
                <label className="checkbox-title" for="custom">
                  Custom
                </label>
              </div>
            </div>
            <div>
              {custom &&
                customData &&
                customData.length > 0 &&
                customData.map((item) => {
                  return (
                    <div className="permission-row">
                      <Form.Check
                        disabled={table === null}
                        defaultChecked={table?.update?.fieldAllow?.includes(item.value) || false}
                        onChange={(e) => onAllowFieldChange(e, item.value)}
                        id="create"
                      />
                      <span className="checkbox-title">{item.lable}</span>
                    </div>
                  );
                })}
            </div>
          </Form.Group>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton text="Cancel" clicked={() => setShowPermissionModal(false)} />
        <PrimaryButton text="Add" clicked={() => addTable()} />
      </Modal.Footer>
    </Modal>
  );
};

export default PermissionConfig;
