import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Section from './Section';

function PageLoader({ style, children, ...otherProps }) {
  return (
    <Section
      bg="white"
      className="d-flex justify-content-center align-items-center"
      style={{
        height: '400px',
        ...style
      }}
      {...otherProps}
    >
      <Container>
        {!children && <Spinner animation="border" variant="primary" />}

        {children && <>{children}</>}
      </Container>
    </Section>
  );
}

PageLoader.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node
};

PageLoader.defaultProps = {
  style: null,
  children: null
};

export default PageLoader;
