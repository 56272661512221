import { Button } from '@mui/material';
import { Col, Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { renderMUILoadingButton } from '../../../util/util';
import moment from 'moment';
import { setInstrumentStatusLog } from '../../../util/db';
import firebaseApp from '../../../util/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { DOCUMENT_LOG_TYPE } from '../TabSettings/DefineCategories';

const ModalFormInstrument = ({
  show,
  handleClose,
  handleSave,
  data,
  allLocations,
  allManufacturers,
  instrumentSettingsInstrumentStatus,
  instrumentSettingsInstrumentType,
  instrumentSettingsInstrumentParameter,
  personnel = []
}) => {
  const [loading, setLoading] = useState(false);
  const functions = getFunctions(firebaseApp);

  const sendInstantEmailNotificationFunction = httpsCallable(
    functions,
    'sendInstrumentInstantEmailNotification'
  );
  const { handleSubmit, register, reset, control } = useForm({
    defaultValues: {
      id: data?.id || '',
      name: data?.name || '',
      parameter: data?.parameter || '',
      location: data?.location || '',
      manufacturer: data?.manufacturer || '',
      serialNumber: data?.serialNumber || '',
      model: data?.model || '',
      status: data?.status || '',
      notes: data?.notes || '',
      internalId: data?.internalId || '',
      cost: data?.cost || '',
      type: data?.type || '',
      responsibleParty: data?.responsibleParty || '',
      inServiceDate: data?.inServiceDate
        ? moment(data?.inServiceDate.seconds * 1000).toDate()
        : null,
      highPriorityInstrument: data?.highPriorityInstrument || false,
      connectedInstrument: data?.connectedInstrument || false,
      nickname: data?.nickname || '',
      expectedLifeYears: data?.expectedLifeYears || ''
    }
  });

  useEffect(() => {
    reset({
      id: data?.id || '',
      name: data?.name || '',
      parameter: data?.parameter || '',
      location: data?.location || '',
      manufacturer: data?.manufacturer || '',
      serialNumber: data?.serialNumber || '',
      model: data?.model || '',
      status: data?.status || '',
      notes: data?.notes || '',
      internalId: data?.internalId || '',
      cost: data?.cost || '',
      type: data?.type || '',
      responsibleParty: data?.responsibleParty || '',
      inServiceDate: data?.inServiceDate
        ? moment(data?.inServiceDate.seconds * 1000).toDate()
        : null,
      highPriorityInstrument: data?.highPriorityInstrument || false,
      connectedInstrument: data?.connectedInstrument || false,
      nickname: data?.nickname || '',
      expectedLifeYears: data?.expectedLifeYears || ''
    });
  }, [show]);

  const onSubmit = async (formData) => {
    setLoading(true);

    if (data?.id && data?.status !== formData.status) {
      await logInstrumentStatus(data.id, data?.status, formData?.status);
      await sendInstantEmailNotificationWhenStatusChanged(formData, data?.status);
    }

    if (data?.id && data?.responsibleParty !== formData.responsibleParty) {
      await sendInstantEmailNotificationWhenResponsiblePartyChanged(
        formData,
        data?.responsibleParty,
        formData.responsibleParty
      );
    }

    await handleSave(formData);
    setLoading(false);
  };

  const logInstrumentStatus = async (instrumentId, prevStatusId, currentStatusId) => {
    const prevStatus = instrumentSettingsInstrumentStatus.find((s) => s.id === prevStatusId);
    const currentStatus = instrumentSettingsInstrumentStatus.find((s) => s.id === currentStatusId);
    const instrumentStatusLog = {
      type: DOCUMENT_LOG_TYPE.INSTRUMENT,
      documentId: instrumentId,
      prevStatus: prevStatus?.id || '',
      prevStatusName: prevStatus?.name || '',
      currentStatus: currentStatus?.id || '',
      currentStatusName: currentStatus?.name || ''
    };

    await setInstrumentStatusLog(instrumentStatusLog);
  };

  const sendInstantEmailNotificationWhenStatusChanged = async (instrument, prevStatusId) => {
    const responsibleParty = personnel.find((item) => item.id === instrument.responsibleParty);
    const prevStatus = instrumentSettingsInstrumentStatus.find((s) => s.id === prevStatusId);
    const status = instrumentSettingsInstrumentStatus.find((item) => item.id === instrument.status);
    const parameter = instrumentSettingsInstrumentParameter.find(
      (item) => item.id === instrument.parameter
    );
    const type = instrumentSettingsInstrumentType.find((item) => item.id === instrument.type);
    const location = allLocations.find((item) => item.id === instrument?.location);
    const sendInstantEmailRequest = {
      sendToPersons: [
        {
          email: responsibleParty.email,
          name: responsibleParty.name
        }
      ],
      instrument: {
        ...instrument,
        location: location?.name,
        instrumentName: instrument.name,
        cost: instrument.cost || '',
        expectedLifeYears: instrument.expectedLifeYears || '',
        connectedInstrument: instrument.connectedInstrument ? 'Yes' : 'No',
        parameter: parameter.name,
        instrumentType: type.name,
        inServiceDate: moment(instrument.inServiceDate).format('MM/DD/YYYY'),
        responsibleParty: responsibleParty.name,
        prevStatus: prevStatus?.name,
        status: status.name,
        statusChanged: true,
        detailLink: `instrument/detail/${instrument.id}`
      }
    };
    await sendInstantEmailNotificationFunction(sendInstantEmailRequest);
  };

  const sendInstantEmailNotificationWhenResponsiblePartyChanged = async (
    instrument,
    prevResponsiblePartyId,
    currentResponsiblePartyId
  ) => {
    const prevResponsibleParty = personnel.find((item) => item.id === prevResponsiblePartyId);
    const currentResponsibleParty = personnel.find((item) => item.id === currentResponsiblePartyId);
    const status = instrumentSettingsInstrumentStatus.find((item) => item.id === instrument.status);
    const parameter = instrumentSettingsInstrumentParameter.find(
      (item) => item.id === instrument.parameter
    );
    const type = instrumentSettingsInstrumentType.find((item) => item.id === instrument.type);
    const location = allLocations.find((item) => item.id === instrument?.location);
    const sendInstantEmailRequest = {
      sendToPersons: [
        {
          email: prevResponsibleParty.email,
          name: prevResponsibleParty.name
        },
        {
          email: currentResponsibleParty.email,
          name: currentResponsibleParty.name
        }
      ],
      instrument: {
        ...instrument,
        cost: instrument.cost || '',
        location: location?.name,
        expectedLifeYears: instrument.expectedLifeYears || '',
        connectedInstrument: instrument.connectedInstrument ? 'Yes' : 'No',
        instrumentName: instrument.name,
        parameter: parameter.name,
        instrumentType: type.name,
        inServiceDate: moment(instrument.inServiceDate).format('MM/DD/YYYY'),
        prevResponsibleParty: prevResponsibleParty.name,
        responsibleParty: currentResponsibleParty.name,
        responsiblePartyChanged: true,
        status: status.name,
        detailLink: `instrument/detail/${instrument.id}`
      }
    };
    await sendInstantEmailNotificationFunction(sendInstantEmailRequest);
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)} onReset={handleClose}>
          <Modal.Header closeButton>{data?.id ? 'Edit Instrument' : 'Add Instrument'}</Modal.Header>

          <Modal.Body>
            <Row>
              <Form.Group as={Col}>
                <Form.Label>Location</Form.Label>
                <Form.Select name="location" {...register('location', { required: true })}>
                  <option value="">-- Select --</option>
                  {allLocations.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Name</Form.Label>
                <Form.Control name="name" {...register('name', { required: true })} />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Status</Form.Label>
                <Form.Select name="status" {...register('status', { required: true })}>
                  <option value="">-- Select --</option>
                  {instrumentSettingsInstrumentStatus.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Type</Form.Label>
                <Form.Select name="type" {...register('type', { required: true })}>
                  <option value="">-- Select --</option>
                  {instrumentSettingsInstrumentType.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Parameter</Form.Label>
                <Form.Select name="parameter" {...register('parameter', { required: true })}>
                  <option value="">-- Select --</option>
                  {instrumentSettingsInstrumentParameter.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Responsible Party</Form.Label>
                <Form.Select
                  name="responsibleParty"
                  {...register('responsibleParty', { required: true })}
                >
                  <option value="">-- Select --</option>
                  {personnel.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Model</Form.Label>
                <Form.Control name="model" {...register('model', { required: true })} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Serial Number</Form.Label>
                <Form.Control
                  name="serialNumber"
                  {...register('serialNumber', { required: true })}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Manufacturer</Form.Label>
                <Form.Select name="manufacturer" {...register('manufacturer', { required: true })}>
                  <option value="">-- Select --</option>
                  {(allManufacturers ?? []).map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Internal ID</Form.Label>
                <Form.Control name="internalId" {...register('internalId', { required: true })} />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Cost</Form.Label>
                <Form.Control
                  name="cost"
                  type="number"
                  {...register('cost', { valueAsNumber: true })}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>In Service Date</Form.Label>
                <Controller
                  control={control}
                  name="inServiceDate"
                  render={({ field }) => <DatePicker {...field} selected={field.value} />}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Nickname</Form.Label>
                <Form.Control name="nickname" {...register('nickname')} />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Instrument Priority</Form.Label>
                <Form.Select
                  name="highPriorityInstrument"
                  {...register('highPriorityInstrument', { required: true })}
                >
                  <option value="">-- Select --</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </Form.Select>
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }} className="flex-end">
              <Form.Group as={Col}>
                <Form.Label>Expected Life (Years)</Form.Label>
                <Form.Control
                  name="expectedLifeYears"
                  type="number"
                  {...register('expectedLifeYears', { valueAsNumber: true })}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Check
                  name="connectedInstrument"
                  label="Connected Instrument"
                  {...register('connectedInstrument')}
                />
              </Form.Group>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Form.Group as={Col}>
                <Form.Label>Notes</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="notes"
                  {...register('notes', { required: false })}
                />
              </Form.Group>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="text" type="reset">
              Cancel
            </Button>
            &nbsp; &nbsp;
            {renderMUILoadingButton({
              buttonText: 'Save',
              isLoading: loading,
              variant: 'contained',
              type: 'submit'
            })}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ModalFormInstrument;
