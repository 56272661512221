import React from 'react';
import Meta from '../components/Meta';
import DashboardSection from '../components/DashboardSection';
import { requireAuth } from '../util/auth';

function DashboardPage() {
  return (
    <>
      <Meta title="Dashboard" />
      <DashboardSection
        bg="white"
        textColor="dark"
        bgImage=""
        bgImageOpacity={1}
        title="Dashboard"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(DashboardPage);
