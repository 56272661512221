import { linearRegression } from '../../../../util/util';

function fixName(incoming) {
  const fixed = [];
  fixed.ewmaucl = '+1.5 Sigma';
  fixed.ewmalcl = '-1.5 Sigma';
  fixed.ewmatrend = 'Trend';
  fixed.average = 'Average';
  fixed.result = 'Data';
  fixed.mr = 'Data';
  fixed.mravg = 'Average';
  fixed.mrucl = 'UCL';
  fixed.ucl = 'UCL';
  fixed.lcl = 'LCL';
  fixed.pp = 'Data';
  if (fixed[incoming]) {
    return fixed[incoming];
  }
  return incoming;
}

function mapToChart(megaChartData, isDotted) {
  const headers = [isDotted ? 'Z-Score' : 'Round', ...megaChartData.map((item) => item.key)];

  const xAxis = (i) => {
    if (!isDotted) {
      const x = megaChartData.find((item) => item.key === 'Data')?.values?.[i]?.x;

      return `${x}` || i;
    }

    return megaChartData.find((item) => item.key === 'Trend')?.values?.[i]?.y || i;
  };

  const newArray = [...Array(megaChartData?.[0]?.values?.length || 0).keys()].map((index) => {
    return [xAxis(index), ...megaChartData.map((item) => item.values[index].y)];
  });

  return [headers, ...newArray];
}

function getMegaChartData({
  includeAll,
  trendLine,
  xDataField,
  yDataField,
  yDataField2,
  yDataField3,
  yDataField4,
  yDataField5,
  yDataField6,
  yDataField7,
  color1,
  color2,
  color3,
  color4,
  color5,
  color6,
  color7,
  chartData,
  isDotted = false
}) {
  const thedata = [];
  const thedatamore = [];
  const thedatamorenames = [];
  const thedatamorecolors = [];
  const maxFieldCount = 7;
  for (let idx = 0; idx <= maxFieldCount; idx += 1) {
    thedatamore.push([]);
  }
  thedatamorenames[2] = yDataField2;
  thedatamorenames[3] = yDataField3;
  thedatamorenames[4] = yDataField4;
  thedatamorenames[5] = yDataField5;
  thedatamorenames[6] = yDataField6;
  thedatamorenames[7] = yDataField7;
  thedatamorecolors[2] = color2;
  thedatamorecolors[3] = color3;
  thedatamorecolors[4] = color4;
  thedatamorecolors[5] = color5;
  thedatamorecolors[6] = color6;
  thedatamorecolors[7] = color7;

  // Data is represented as an array of {x,y} pairs.

  const activeArray = chartData;
  const allX = [];
  const allY = [];
  let minX = 9999;
  let maxX = -99999;

  for (let i = 0; i < activeArray.length; i += 1) {
    const dataObj = activeArray[i];
    if (includeAll || dataObj.omit === 'N') {
      const xVal = dataObj[xDataField];
      const yVal = dataObj[yDataField];
      const yValArr = [];
      for (let idx = 2; idx <= maxFieldCount; idx += 1) {
        const fieldName = thedatamorenames[idx];
        if (dataObj[fieldName] !== '') {
          yValArr[idx] = dataObj[fieldName];
        }
      }

      if (!Number.isNaN(yVal) && !Number.isNaN(xVal) && xVal !== '' && yVal !== '') {
        if (includeAll && dataObj.outlier > 0 && xDataField !== 'zScoreAll') {
          thedata.push({
            y: yVal,
            x: xVal,
            shape: 'triangle-down',
            size: 100,
            color: 'red'
          });
        } else if (includeAll && dataObj.omit === 'Y' && xDataField !== 'zScoreAll') {
          thedata.push({
            y: yVal,
            x: xVal,
            shape: 'triangle-up',
            size: 100,
            color: 'red'
          });
        } else {
          thedata.push({
            y: yVal,
            x: xVal
          });
        }
        for (let idx = 2; idx <= maxFieldCount; idx += 1) {
          if (typeof yValArr[idx] !== 'undefined' && !Number.isNaN(yValArr[idx])) {
            thedatamore[idx].push({
              y: yValArr[idx],
              x: xVal
            });
          }
        }
        allY.push(yVal);
        allX.push(xVal);
        if (xVal > maxX) maxX = xVal;
        if (xVal < minX) minX = xVal;
      }
    }
  }

  const returnArray = [
    {
      values: thedata,
      type: 'scatter',
      color: color1,
      key: fixName(yDataField)
    }
  ];

  // if we have additional y values:
  for (let idx = 2; idx <= maxFieldCount; idx += 1) {
    if (typeof thedatamore[idx] !== 'undefined' && thedatamore[idx].length > 0) {
      const displayName = fixName(thedatamorenames[idx]);
      returnArray.push({
        values: thedatamore[idx], // values - represents the array of {x,y} data points
        key: displayName, // key  - the name of the series.
        color: thedatamorecolors[idx],
        type: 'line'
      });
    } // thedatamore[idx].length > 0
  }

  if (trendLine) {
    // run regression and create trend line:
    const lr = linearRegression(allY, allX);
    const numPts = returnArray.find((cd) => cd.key === 'Data')?.values?.length || 0;
    const trendData = new Array(numPts);
    for (let idx = 0; idx < numPts; idx += 1) {
      trendData[idx] = {
        x: minX + (maxX - minX) * (idx / numPts),
        y: lr.slope * (minX + ((maxX - minX) * idx) / numPts) + lr.intercept
      };
    }
    returnArray.push({
      values: trendData,
      type: 'line',
      key: 'Trend',
      color: '#ff7f0e'
    });
  } // if trendLine

  return mapToChart(returnArray, isDotted);
}

export default getMegaChartData;
