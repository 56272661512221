import React, { useState } from 'react';

import 'rsuite/dist/rsuite-no-reset.min.css';
import '../styles/global.scss';
import fontawesome from '@fortawesome/fontawesome';
import { faCheckSquare, faCoffee, faTrash } from '@fortawesome/fontawesome-free-solid';
import NavbarCustom from '../components/NavbarCustom';
import IndexPage from './index';
import AboutPage from './about';
import FaqPage from './faq';
import ContactPage from './contact';
import PricingPage from './pricing';
import NotificationsPage from './notifications';
import DataEntryPage from './data-entry';
import DashboardPage from './dashboard';
import ChartPage from './chart';
import AuthPage from './auth';
import PersonnelPage from './personnel';
import SettingsPage from './settings';
import InstrumentPage from './instrument';
import LegalPage from './legal';
import { Route, Router, Switch } from '../util/router';

import { currentLogo, currentLogoAncillary } from '../util/firebase';
import FirebaseActionPage from './firebase-action';
import NotFoundPage from './404';
import Footer from '../components/Footer';
import '../util/analytics';
import { AuthProvider } from '../util/auth';
import { QueryClientProvider } from '../util/db';
import IlcpPage from './ilcp';
import ReportPage from './ReportPage';
import ILCPProduct from '../components/ILCPSection/ILCPProduct/ILCPProduct';
import PrintCalculationPage from './print-calculation';
import PrintReportPage from '../components/ReportsSection/PrintReportPage';
import AppContext from '../components/AppContext';
import InstrumentManagementPage from '../components/InstrumentManagement';
import PrintInitialAssessmentPage from '../components/AssessmentsSection/PrintInitialAssessmentPage';
import PrintPeriodicAssessmentPage from '../components/AssessmentsSection/PrintPeriodicAssessmentPage';

fontawesome.library.add(faCheckSquare, faCoffee, faTrash);

function App() {
  const pathsWithoutLayout = ['/print-calculation'];

  const [currentFullKey, setCurrentFullKey] = useState(null);

  return (
    <QueryClientProvider>
      <AuthProvider>
        <AppContext.Provider value={{ currentFullKey, setCurrentFullKey }}>
          <Router>
            <>
              {!pathsWithoutLayout.includes(location) && (
                <NavbarCustom
                  bg="white"
                  variant="light"
                  expand="md"
                  logo={currentLogo}
                  logoAncillary={currentLogoAncillary}
                />
              )}
              <Switch>
                <Route exact path="/" component={IndexPage} />

                <Route exact path="/about" component={AboutPage} />

                <Route exact path="/faq" component={FaqPage} />

                <Route exact path="/contact" component={ContactPage} />

                <Route exact path="/dashboard" component={DashboardPage} />

                <Route exact path="/chart" component={ChartPage} />

                <Route exact path="/data-entry" component={DataEntryPage} />

                <Route exact path="/scheduler" component={NotificationsPage} />

                <Route exact path="/personnel" component={PersonnelPage} />

                <Route exact path="/auth/:type" component={AuthPage} />

                <Route exact path="/settings/:section" component={SettingsPage} />

                <Route exact path="/legal/:section" component={LegalPage} />
                <Route exact path="/ilcp" component={IlcpPage} />

                <Route path="/instrument" component={InstrumentPage} />

                <Route exact path="/product" component={ILCPProduct} />
                <Route exact path="/stdcmp/report" component={ReportPage} />

                <Route exact path="/firebase-action" component={FirebaseActionPage} />
                <Route exact path="/print-calculation" component={PrintCalculationPage} />
                <Route exact path="/print-report" component={PrintReportPage} />
                <Route
                  exact
                  path="/print-initial-assessment"
                  component={PrintInitialAssessmentPage}
                />
                <Route
                  exact
                  path="/print-periodic-assessment"
                  component={PrintPeriodicAssessmentPage}
                />
                <Route component={NotFoundPage} />
              </Switch>
              {!pathsWithoutLayout.includes(location) && (
                <Footer
                  bg="light"
                  textColor="dark"
                  bgImage=""
                  bgImageOpacity={1}
                  copyright={`© ${new Date().getFullYear()} FuelSQC`}
                  logo={currentLogo}
                  logoAncillary={currentLogoAncillary}
                />
              )}
            </>
          </Router>
        </AppContext.Provider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
