import React from 'react';
import { currentLogo } from '../../../util/firebase';

const CalculationDetailHeader = () => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '3fr 1fr',
      alignItems: 'center',
      justifyItems: 'center'
    }}
  >
    <img width="100%" style={{ maxWidth: '120px' }} src={`../../..${currentLogo}`} alt="logo" />
    <h2>ASTM SQC Insights</h2>
    <h3 style={{ gridColumn: '1/3' }}>EPA Compliance Report for Accuracy: 40 CFR 1090.1375</h3>
  </div>
);

export default CalculationDetailHeader;
