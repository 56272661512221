import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Section from './Section';
import SectionHeader from './SectionHeader';
import './FeaturesSection.scss';

function FeaturesSection({ bg, textColor, size, bgImage, bgImageOpacity, title, subtitle }) {
  const items = [
    {
      title: 'Compliance Made Easy',
      description:
        'All of your quality control data in one easy place. Everything from charting to audit preparation in a secure online environment.',
      image: 'https://uploads.divjoy.com/undraw-mind_map_cwng.svg'
    },
    {
      title: 'Instrument to Organization',
      description:
        'View performance across the entire organization or quickly click down to inspect results from a specific instrument in seconds.',
      image: 'https://uploads.divjoy.com/undraw-personal_settings_kihd.svg'
    },
    {
      title: 'Workflow That Works',
      description:
        'ASTM SQC integrates with your business and management practices with easy data integration tools, efficient user controls and a notification system that keeps the right people informed.',
      image: 'https://uploads.divjoy.com/undraw-having_fun_iais.svg'
    },
    {
      title: 'Enterprise Ready',
      description:
        'ASTM SQC is built for use across an enterprise with multiple labs, an array of instruments, multiple parameters, and users across the globe.',
      image: 'https://uploads.divjoy.com/undraw-balloons_vxx5.svg'
    }
  ];

  return (
    <Section
      bg={bg}
      textColor={textColor}
      size={size}
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      <Container>
        <SectionHeader title={title} subtitle={subtitle} size={2} spaced className="text-center" />
        <div className="FeaturesSection__features">
          {items.map((item) => (
            <Row className="align-items-center" key={item.title}>
              <Col xs={12} lg={6}>
                <SectionHeader
                  title={item.title}
                  subtitle={item.description}
                  spaced
                  size={3}
                  className="text-center text-lg-left"
                />
              </Col>
              <Col>
                <figure className="FeaturesSection__image-container">
                  <Image src={item.image} alt={item.title} fluid />
                </figure>
              </Col>
            </Row>
          ))}
        </div>
      </Container>
    </Section>
  );
}

FeaturesSection.propTypes = {
  bg: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageOpacity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default FeaturesSection;
