import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

const AppTextField = ({ name, control, rules, type = 'text', ...props }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField
          fullWidth
          type={type}
          error={!!fieldState.error}
          helperText={fieldState.error?.message}
          {...props}
          {...field}
        />
      )}
    />
  );
};

export default AppTextField;
