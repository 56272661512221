import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from 'rsuite/Table';
import Pagination from 'rsuite/Pagination';

const PaginatedTable = ({ loading, bodyData, children }) => {
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  if (!bodyData) return <div>Nothing</div>;
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  useEffect(() => {
    setPage(1);
  }, [bodyData]);

  const data = bodyData?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const rowHeight = 50;
  const headerHeight = 35;
  const minLength = Math.max(bodyData.length, 1);
  const tableHeight = rowHeight * (minLength < 10 ? minLength : 10) + headerHeight;

  return (
    <div className="contentTable ">
      <Table
        loading={loading}
        data={data}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        bordered
        cellBordered
        height={tableHeight}
      >
        {children}
      </Table>
      <div className="paginationButton">
        <div className="contentPage">
          <Pagination
            className="pagination"
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={bodyData?.length}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    </div>
  );
};

PaginatedTable.propTypes = {
  loading: PropTypes.bool,
  bodyData: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node
};

PaginatedTable.defaultProps = {
  loading: false,
  bodyData: [],
  children: null
};

export default PaginatedTable;
