import React from 'react';
import PropTypes from 'prop-types';
import CustomChart from '../CustomChart';

const ProductCharts = ({
  loadingChartData,
  scChartAllData,
  mrChartAllData,
  iChartAllDataStdCmp
}) => {
  const firstChart = {
    title: 'Standard Comparison (screened data)',
    legend: {
      position: 'top'
    },
    pointSize: 10,
    pointShape: { type: 'square' },
    hAxis: { format: 'MMM yyyy' },
    series: {
      0: { color: 'yellow' }, // Reference
      1: { color: 'green' } // Result
    }
  };

  const optionsChart = {
    title: 'Dummy Chart',
    curveType: 'function',
    legend: { position: 'top' },
    series: {
      0: {
        curveType: 'none',
        pointSize: 5
      }
    }
  };

  return (
    <>
      <CustomChart
        loading={loadingChartData}
        data={scChartAllData}
        options={firstChart}
        type="ScatterChart"
      />
      <CustomChart
        loading={loadingChartData}
        data={mrChartAllData}
        options={{
          ...optionsChart,
          title: 'Moving Range and Control Limits (all data)'
        }}
        type="LineChart"
      />
      <CustomChart
        loading={loadingChartData}
        data={iChartAllDataStdCmp}
        type="LineChart"
        options={{
          ...optionsChart,
          title: 'I Chart (all data)'
        }}
      />
    </>
  );
};

ProductCharts.propTypes = {
  loadingChartData: PropTypes.bool.isRequired,
  scChartAllData: PropTypes.arrayOf(PropTypes.object).isRequired,
  mrChartAllData: PropTypes.arrayOf(PropTypes.object).isRequired,
  iChartAllDataStdCmp: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ProductCharts;
