import { useEffect, useState } from 'react';
import { getProductDataByMethod, updateStdcmpData, addNewStdcmpData } from '../../../util/db';
import mapToSelect from './mapToSelect';

const useProductData = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);
  const [parameters, setParameters] = useState({});

  useEffect(() => {
    const { method, programId } = parameters;
    if (method && programId) {
      setLoading(true);
      getProductDataByMethod(method, programId)
        .then((prodValues) => {
          setValues(prodValues);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [parameters]);

  const getOwners = () => {
    const owners = new Set();
    values.forEach(({ owner }) => owners.add(owner));
    return Array.from(['any', ...owners])
      .filter((v) => v)
      .map(mapToSelect);
  };

  const getProviders = () => {
    const providers = new Set();
    values.forEach(({ provider }) => providers.add(provider));
    return Array.from(['any', ...providers])
      .filter((v) => v)
      .map(mapToSelect);
  };

  const updateProductComp = async (updateData) => {
    const listUpdate = updateData.filter((item) => item.id);
    await updateStdcmpData(listUpdate);
    const listAddNew = updateData.filter((item) => !item.id);
    if (listAddNew && listAddNew.length > 0) {
      await addNewStdcmpData(listAddNew);
      // update current list with new item
      const { method, programId } = parameters;
      if (method && programId) {
        const dataUpdate = await getProductDataByMethod(method, programId);
        setValues(dataUpdate);
      }
    }
  };

  return [
    loading,
    values,
    (newParameters) => setParameters(newParameters),
    getOwners,
    getProviders,
    setValues,
    updateProductComp
  ];
};

export default useProductData;
