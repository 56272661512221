import { useState } from 'react';
import jsonpCall from '../JsonpCall';

function validDate(d) {
  if (Object.prototype.toString.call(d) === '[object Date]') {
    // it is a date
    if (!Number.isNaN(d.getTime())) {
      // d.valueOf() could also work
      return true;
    }
  }
  return false;
}

function getFormattedDate(theDate) {
  const date = new Date(theDate);
  date.setDate(date.getDate() + 1); // need to increment by 1 because of UTC stuff
  if (validDate(date)) {
    const year = date.getFullYear();
    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : `0${month}`;
    let day = date.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;
    return `${month}/${day}/${year}`;
  }
  return '';
}

function isNumeric(n) {
  return (!Number.isNaN(parseFloat(n)) && Number.isFinite(n)) || validDate(n);
}

function dateStringToEpoch(datestring) {
  const dateTime = +new Date(datestring);
  return dateTime ? Math.floor(dateTime / 1000) : 0;
}

function jsToExcelDate(dt) {
  const timeZoneOffset = -8; // pacific
  return ((new Date(dt).getTime() + timeZoneOffset * 3600000) / 86400000 + 25569).toFixed(0);
}

function JSDateToExcelDate(inDate) {
  if (inDate instanceof Date && !Number.isNaN(inDate.valueOf())) {
    const returnDateTime =
      25569.0 + (inDate.getTime() - inDate.getTimezoneOffset() * 60 * 1000) / (1000 * 60 * 60 * 24);
    return returnDateTime.toString().substr(0, 20);
  }
  return 0;
}

const useLoadData = () => {
  const [chartData, setChartData] = useState([]);
  const [gSheetStdCmpRequests, setGSheetStdCmpRequests] = useState([]);
  const [body, setBody] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(false);

  const makeNextGSheetStdCmpCall = async () => {
    if (gSheetStdCmpRequests.length > 0) {
      const myUrl = gSheetStdCmpRequests[0];
      await jsonpCall(myUrl);
      setGSheetStdCmpRequests(gSheetStdCmpRequests.slice(1));
    }
  };

  const gsheetCallbackStdCmp = async (dataIn) => {
    const data = dataIn;
    setLoading(true);

    if (typeof data?.tableReturn !== 'undefined') {
      // $scope.ADRMS = data.globalReturn[5][0];
      // $scope.ADMR = data.globalReturn[6][0];
      // $scope.chartData = [];
      const newChartData = [];
      for (let i = 1; i < data.chartReturn.length; i += 1) {
        // start after header
        if (data.chartReturn[i][0] !== '#N/A') {
          for (let j = 0; j < 16; j += 1) {
            if (data.chartReturn[i][j] === '#N/A') {
              data.chartReturn[i][j] = undefined;
            }
          }
          newChartData.push({
            date: data.chartReturn[i][0],
            result: data.chartReturn[i][1],
            refHigh: data.chartReturn[i][2],
            refLow: data.chartReturn[i][3],
            rpd: data.chartReturn[i][4],
            mrRpd: data.chartReturn[i][5],
            orderedRpd: data.chartReturn[i][6],
            zScore: data.chartReturn[i][7],
            trendRpd: data.chartReturn[i][8],
            outlierRpd: data.chartReturn[i][9],
            reference: data.chartReturn[i][10],
            mrAvg: data.chartReturn[i][11],
            mrUcl: data.chartReturn[i][12],
            iAvg: data.chartReturn[i][13],
            iUcl: data.chartReturn[i][14],
            iLcl: data.chartReturn[i][15],
            i15sig: data.chartReturn[i][16],
            i15signeg: data.chartReturn[i][17],

            checkHigh: data.chartReturn[i][19],
            checkLow: data.chartReturn[i][20],
            checkEval: data.chartReturn[i][21],
            r: data.chartReturn[i][22],
            difference: data.chartReturn[i][23]
          });
        }
      }

      setChartData(newChartData);

      const t1head = [];
      let t1body = [];

      const t1colct = 11;
      for (let i = 0; i < t1colct; i += 1) {
        t1head.push(data.tableReturn[0][i]);
      }

      const localBody = [];
      for (let r = 1; r < data.tableReturn.length; r += 1) {
        const t1row = [];
        if (data.tableReturn[r][0] !== '') {
          for (let i = 0; i < t1colct; i += 1) {
            if (i === 0) {
              t1row.push(getFormattedDate(data.tableReturn[r][i]));
            } else {
              t1row.push(
                String(data.tableReturn[r][i]).length > 5 &&
                  isNumeric(data.tableReturn[r][i]) &&
                  i !== 2
                  ? data.tableReturn[r][i].toFixed(3)
                  : decodeURI(data.tableReturn[r][i])
              );
            }
          }
          localBody.push(t1row);
        }
      }

      // sort the body:
      t1body = localBody.sort((a, b) => {
        if (!a || !a[0]) {
          return -1;
        }
        if (!b || !b[0]) {
          return 1;
        }
        if (dateStringToEpoch(a[0]) < dateStringToEpoch(b[0])) {
          return 1;
        }
        if (dateStringToEpoch(a[0]) > dateStringToEpoch(b[0])) {
          return -1;
        }
        return 0;
      });

      setBody(t1body);
      setHeaders(t1head);
    } else {
      await makeNextGSheetStdCmpCall();
    }
    setLoading(false);
  };

  const updateChartData = async ({ viewableData, sheetId, filters }) => {
    setLoading(true);
    let numRows = 0;
    const dataBlock = [];
    const parameterBlock = [];

    const setFacilityId = 'HEP'; // I hardcoded this for now

    for (let i = 0; i < viewableData.length; i += 1) {
      numRows += 1;
      const dataObj = viewableData[i];
      dataBlock.push(dataObj.sampleID);
      dataBlock.push(dataObj.ui);
      dataBlock.push(jsToExcelDate(dataObj.date));
      dataBlock.push(encodeURIComponent(dataObj.owner));
      dataBlock.push(dataObj.batchID ? encodeURIComponent(dataObj.batchID) : '');
      dataBlock.push(dataObj.value);
      dataBlock.push(dataObj.provided);
      dataBlock.push(dataObj.provider ? encodeURIComponent(dataObj.provider) : 'any');
      dataBlock.push(dataObj.high);
      dataBlock.push(dataObj.low);
      dataBlock.push(dataObj.omit);
      dataBlock.push(' ');
    }

    parameterBlock.push(JSDateToExcelDate(filters.minDate));
    parameterBlock.push(JSDateToExcelDate(filters.maxDate));
    parameterBlock.push(filters.testSubstanceParameter);
    parameterBlock.push(filters.owner || 'any');
    parameterBlock.push(filters.provider);
    parameterBlock.push(filters.batchID);
    parameterBlock.push(filters.uniqueID);
    parameterBlock.push(filters.testSubstance.parameter);

    if (numRows > 50) {
      const parameter = JSON.stringify({
        scriptId: 'AKfycbzQbNJyddoa2e01ifpeS9_J4wQNaoJNi6gXuVIpBxZ4Lz6JZL4',
        dataBlock,
        parameterBlock,
        sheetId, // : "1GdIaZzKItOswxK6Ug-d2K02mqcorRC1uJ5MNf8SgaMk",
        facility: setFacilityId
      });

      const endpoint =
        'https://us-central1-project-6660034744144425013.cloudfunctions.net/proxyPostStdCmp';

      await fetch(endpoint, {
        method: 'POST',
        body: parameter,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Error in processing data.');
        })
        .then((data) => {
          gsheetCallbackStdCmp(data.body);
        })
        .catch((error) => {
          // eslint-disable-next-line no-alert
          alert('Error in communicating data.', error);
        });
    } else {
      // run through sheets
      const rowChunk = 50;
      const newGSheetStdCmpRequests = [];

      for (let startRow = 1; startRow <= numRows; startRow += rowChunk) {
        let run = '';
        let thisNumRows = rowChunk;
        if (startRow + rowChunk >= numRows) {
          run = 1;
          thisNumRows = numRows + 1 - startRow;
        }
        const startIdx = (startRow - 1) * 12;
        const endIdx = (startRow - 1) * 12 + thisNumRows * 12;

        const myUrl = `${
          'https://script.google.com/macros/s/AKfycbzQbNJyddoa2e01ifpeS9_J4wQNaoJNi6gXuVIpBxZ4Lz6JZL4/exec?' +
          'sheetId='
        }${sheetId}&parameterBlock=${parameterBlock.join(
          '|'
        )}&facility=${setFacilityId}&dataBlock=${dataBlock
          .slice(startIdx, endIdx)
          .join(
            '|'
          )}&totalRows=${numRows}&numRows=${thisNumRows}&startRow=${startRow}&run=${run}&callback=gsheetCallbackStdCmp`;
        newGSheetStdCmpRequests.push(myUrl);
      }

      await setGSheetStdCmpRequests([...newGSheetStdCmpRequests]);
      await makeNextGSheetStdCmpCall();
    }

    setLoading(false);
  };

  return {
    body,
    headers,
    chartData,
    updateChartData,
    gsheetCallbackStdCmp,
    loading
  };
};

export default useLoadData;
