import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormAlert from './FormAlert';
import AuthForm from './AuthForm';
import AuthSocial from './AuthSocial';
import { useRouter } from '../util/router';
import CreateAccountForm from './CreateAccountForm';

function Auth({ afterAuthPath, type, buttonAction, providers }) {
  const router = useRouter();
  const [formAlert, setFormAlert] = useState(null);

  const handleAuth = () => {
    router.push(afterAuthPath);
  };

  const handleFormAlert = (data) => {
    setFormAlert(data);
  };

  const renderSpecificAuthForm = (authType) => {
    const authFormToReturn = [];
    if (authType !== 'signup') {
      authFormToReturn.push(
        <AuthForm
          type={type}
          buttonAction={buttonAction}
          onAuth={handleAuth}
          onFormAlert={handleFormAlert}
        />
      );
    } else {
      authFormToReturn.push(
        <CreateAccountForm
          type={type}
          buttonAction={buttonAction}
          onAuth={handleAuth}
          onFormAlert={handleFormAlert}
        />
      );
    }
    return authFormToReturn;
  };

  return (
    <>
      {formAlert && <FormAlert type={formAlert.type} message={formAlert.message} />}
      {renderSpecificAuthForm(type)}
      {['signup', 'signin'].includes(type) && (
        <>
          {providers && providers.length && (
            <>
              <small className="text-center d-block my-3">OR</small>
              <AuthSocial
                buttonAction={buttonAction}
                providers={providers}
                showLastUsed
                onAuth={handleAuth}
                onError={(message) => {
                  handleFormAlert({
                    type: 'error',
                    message
                  });
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

Auth.propTypes = {
  afterAuthPath: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['signup', 'signin', 'forgotpass']).isRequired,
  buttonAction: PropTypes.oneOf(['signup', 'signin', 'forgotpass']).isRequired,
  providers: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default Auth;
