import React, { useEffect, useState } from 'react';
import './index.scss';
import { Link, Route, Switch, useRouteMatch, useLocation } from '../../util/router';
import { Box, Button } from '@mui/material';
import TabEvents from './TabEvents';
import EventDetails from './TabEvents/EventDetails';
import EventAtGlance from './TabEvents/EventAtGlance';
import TabInstruments from './TabInstruments';
import InstrumentDetails from './TabInstruments/InstrumentDetails';
import TabSettings from './TabSettings';
import { useAuth } from '../../util/auth';
import { useGetPersonnel } from '../../util/db';
import { hasPermission } from './utils';
import { RULES, SCREEN } from '../../util/Constant';

const CustomTabPanel = (props) => {
  const { children, value, tab, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== tab} {...other}>
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
};

const InstrumentManagementPage = () => {
  const auth = useAuth();
  const { pathname } = useLocation();
  let { path, url } = useRouteMatch();
  const { data: personnel } = useGetPersonnel();
  const [permissionData, setPermissionData] = useState(null);

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        if (auth.permissionData) setPermissionData(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  return (
    <div className="instrument-management">
      <div className="title">
        <h2>Instrument Manager</h2>
      </div>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <div className="tabs">
          {hasPermission(permissionData, SCREEN.INSTRUMENT_EVENTS, RULES.VIEW) ? (
            <Button
              className={`${pathname.includes('events') ? 'active' : ''}`}
              component={Link}
              to={`${url}/events`}
            >
              Events
            </Button>
          ) : (
            <></>
          )}
          {hasPermission(permissionData, SCREEN.INSTRUMENT_INSTRUMENTS, RULES.VIEW) ? (
            <Button
              className={`${
                pathname.includes('instrument/list') ||
                pathname.includes('instrument/create') ||
                pathname.includes('instrument/detail')
                  ? 'active'
                  : ''
              }`}
              component={Link}
              to={`${url}/list`}
            >
              Instruments
            </Button>
          ) : (
            <></>
          )}
          {hasPermission(permissionData, SCREEN.INSTRUMENT_SETTINGS, RULES.VIEW) ? (
            <Button
              className={`${pathname.includes('settings') ? 'active' : ''}`}
              component={Link}
              to={`${url}/settings/vendors`}
            >
              Settings
            </Button>
          ) : (
            <></>
          )}
        </div>
      </Box>
      <Box sx={{ p: 3 }}>
        <Switch>
          <Route path={`${path}/events/at-a-glance`} component={EventAtGlance} />
          <Route path={`${path}/events/create`} component={EventDetails} />
          <Route path={`${path}/events/:id`} component={EventDetails} />
          <Route path={`${path}/events`} component={TabEvents} />

          <Route path={`${path}/settings`} component={TabSettings} />

          <Route path={`${path}/create`} component={InstrumentDetails} />
          <Route path={`${path}/list`} component={TabInstruments} />
          <Route path={`${path}/detail/:id`} component={InstrumentDetails} />
        </Switch>
      </Box>
    </div>
  );
};

export default InstrumentManagementPage;
