import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

function FormAlert({ type, message, ...otherProps }) {
  return (
    <Alert variant={type === 'error' ? 'danger' : 'success'} {...otherProps}>
      {message}
    </Alert>
  );
}

FormAlert.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default FormAlert;
