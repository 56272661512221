import React, { useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import './ParameterSection.scss';
import {
  setOrderOfParameters,
  useGetIndexValues,
  useGetCompanyType,
  useGetOrderOfParameters,
  useGetPersonnel
} from '../../util/db';
import { Col, Row } from 'react-bootstrap';
import { alphaNumericSorter, isAdmin } from '../../util/util';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import { useAuth } from '../../util/auth';

function localReducer(state, action) {
  switch (action.type) {
    case 'location':
      return {
        ...state,
        currentLocation: action.payload,
        currentMethod: null,
        currentSampleName: null,
        currentInstrument: null
      };
    case 'method':
      return {
        ...state,
        currentMethod: action.payload,
        currentSampleName: null,
        currentInstrument: null
      };
    case 'sampleName':
      return state.instrumentBeforeSampleName
        ? {
            ...state,
            currentSampleName: action.payload
          }
        : {
            ...state,
            currentSampleName: action.payload,
            currentInstrument: null
          };
    case 'instrument':
      return state.instrumentBeforeSampleName
        ? {
            ...state,
            currentInstrument: action.payload,
            currentSampleName: null
          }
        : {
            ...state,
            currentInstrument: action.payload
          };
    case 'instrumentBeforeSampleName':
      return {
        ...state,
        instrumentBeforeSampleName: action.payload
      };
    default:
      return state;
  }
}

function ParameterSection() {
  const auth = useAuth();
  const { data: allLocations } = useGetIndexValues();
  const [indexes, setIndexes] = useState([]);
  const { data: orderOfParameters } = useGetOrderOfParameters();
  const { data: personnel } = useGetPersonnel();
  const { data: companyTypes } = useGetCompanyType();
  const [permission, setPermission] = useState(null);
  const [activeLocations, setActiveLocations] = useState();
  const [localState, localDispatch] = useReducer(localReducer, {
    currentLocation: null,
    currentMethod: null,
    currentSampleName: null,
    currentInstrument: null,
    instrumentBeforeSampleName: false
  });

  useEffect(() => {
    if (Array.isArray(personnel) && personnel.length > 0) {
      const matchingUser = personnel.find((person) => person.email === auth.user.email);
      if (matchingUser) {
        setPermission(auth.permissionData[matchingUser.permission]);
      }
    }
  }, [personnel]);

  useEffect(() => {
    const mainLocations = allLocations?.find((location) => location.id === 'mainIndex-location');
    setActiveLocations(mainLocations);
    localDispatch({
      type: 'instrumentBeforeSampleName',
      payload:
        (companyTypes && companyTypes[0] && companyTypes[0]['orderTypeInstrumentBefore']) || false
    });
  }, [allLocations]);

  const handleChange = (type, event) => {
    switch (type) {
      case 'location':
        const currentLocation = activeLocations.index.find(
          (location) => location.name === event.target.value
        );
        localDispatch({ type, payload: currentLocation });
        break;
      case 'method':
        const currentMethod = localState.currentLocation.method.find(
          (method) => method.name === event.target.value
        );
        localDispatch({ type, payload: currentMethod });
        break;
      case 'sampleName':
        let currentSampleName = (
          localState.instrumentBeforeSampleName
            ? localState.currentInstrument
            : localState.currentMethod
        ).sampleName.find((sampleName) => sampleName.name === event.target.value);

        if (localState.instrumentBeforeSampleName) {
          const shortKey = `${localState.currentLocation.name}|${localState.currentMethod.name}|${event.target.value}|${localState.currentInstrument.name}`;
          if (orderOfParameters && orderOfParameters[0] && orderOfParameters[0][shortKey]) {
            const paramsOrdered = orderOfParameters[0][shortKey];
            currentSampleName = {
              ...currentSampleName,
              parameter: currentSampleName.parameter.map((param) => {
                return { ...param, order: paramsOrdered[param.name] || 0 };
              })
            };
          }
        }
        localDispatch({ type, payload: currentSampleName });
        break;
      case 'instrument':
        let currentInstrument = (
          localState.instrumentBeforeSampleName
            ? localState.currentMethod
            : localState.currentSampleName
        ).instrument.find((instrument) => instrument.name === event.target.value);

        if (!localState.instrumentBeforeSampleName) {
          const shortKey = `${localState.currentLocation.name}|${localState.currentMethod.name}|${localState.currentSampleName.name}|${event.target.value}`;
          if (orderOfParameters && orderOfParameters[0] && orderOfParameters[0][shortKey]) {
            const paramsOrdered = orderOfParameters[0][shortKey];
            currentInstrument = {
              ...currentInstrument,
              parameter: currentInstrument.parameter.map((param) => {
                return { ...param, order: paramsOrdered[param.name] || 0 };
              })
            };
          }
        }
        localDispatch({ type, payload: currentInstrument });
        break;
    }
  };

  const handleChangeOrder = (event) => {
    if (localState.instrumentBeforeSampleName) {
      const currentSampleName = {
        ...localState.currentSampleName,
        parameter: localState.currentSampleName.parameter.map((param) => {
          if (param.name === event.target.name) {
            return { ...param, nextOrder: Number(event.target.value) || 0 };
          } else {
            return param;
          }
        })
      };
      localDispatch({ type: 'sampleName', payload: currentSampleName });
    } else {
      const currentInstrument = {
        ...localState.currentInstrument,
        parameter: localState.currentInstrument.parameter.map((param) => {
          if (param.name === event.target.name) {
            return { ...param, nextOrder: Number(event.target.value) || 0 };
          } else {
            return param;
          }
        })
      };
      localDispatch({ type: 'instrument', payload: currentInstrument });
    }
  };

  const handleSaveOrder = () => {
    const form = document.querySelector('#formOrder');
    const formData = new FormData(form);
    const currentOrders = {};
    for (let item of formData) {
      currentOrders[item[0]] = Number(item[1]) || 0;
    }

    if (localState.instrumentBeforeSampleName) {
      const currentSampleName = {
        ...localState.currentSampleName,
        parameter: localState.currentSampleName.parameter.map((param) => {
          if (currentOrders[param.name]) {
            return { ...param, order: currentOrders[param.name] };
          } else {
            return param;
          }
        })
      };
      localDispatch({ type: 'sampleName', payload: currentSampleName });
    } else {
      const currentInstrument = {
        ...localState.currentInstrument,
        parameter: localState.currentInstrument.parameter.map((param) => {
          if (currentOrders[param.name]) {
            return { ...param, order: currentOrders[param.name] };
          } else {
            return param;
          }
        })
      };
      localDispatch({ type: 'instrument', payload: currentInstrument });
    }

    const shortKey = `${localState.currentLocation.name}|${localState.currentMethod.name}|${localState.currentSampleName.name}|${localState.currentInstrument.name}`;
    if (orderOfParameters && orderOfParameters[0]) {
      setOrderOfParameters({
        ...orderOfParameters[0],
        [shortKey]: currentOrders
      });
    } else {
      setOrderOfParameters({
        [shortKey]: currentOrders
      });
    }
  };

  return (
    <div>
      <div className="header">
        <h2>Order of Parameters</h2>
      </div>
      <div className="underline-header">
        <hr />
      </div>
      <div className="personnel-information-container">
        <Row className="mb-3 global-dropdowns">
          <Form.Group as={Col} controlId="location">
            <Form.Label>Location</Form.Label>
            <Form.Select
              value={localState.currentLocation?.name || ''}
              onChange={(event) => handleChange('location', event)}
            >
              <option value="">Select location</option>
              {(activeLocations?.index || []).sort(alphaNumericSorter).map((option) => {
                return (
                  <option key={option.name} value={option.name}>
                    {option.name} ({option.count})
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group as={Col} controlId="method">
            <Form.Label>Method</Form.Label>
            <Form.Select
              value={localState.currentMethod?.name || ''}
              onChange={(event) => handleChange('method', event)}
            >
              <option value="">Select method</option>
              {(localState.currentLocation?.method || []).sort(alphaNumericSorter).map((option) => {
                return (
                  <option key={option.name} value={option.name}>
                    {option.name} ({option.count})
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>

          {localState.instrumentBeforeSampleName ? (
            <>
              <Form.Group as={Col} controlId="instrument">
                <Form.Label>Instrument</Form.Label>
                <Form.Select
                  value={localState.currentInstrument?.name || ''}
                  onChange={(event) => handleChange('instrument', event)}
                >
                  <option value="">Select instrument</option>
                  {(localState.currentMethod?.instrument || [])
                    .sort(alphaNumericSorter)
                    .map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name} ({option.count})
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="sampleName">
                <Form.Label>Sample Name</Form.Label>
                <Form.Select
                  value={localState.currentSampleName?.name || ''}
                  onChange={(event) => handleChange('sampleName', event)}
                >
                  <option value="">Select sample name</option>
                  {(localState.currentInstrument?.sampleName || [])
                    .sort(alphaNumericSorter)
                    .map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name} ({option.count})
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group as={Col} controlId="sampleName">
                <Form.Label>Sample Name</Form.Label>
                <Form.Select
                  value={localState.currentSampleName?.name || ''}
                  onChange={(event) => handleChange('sampleName', event)}
                >
                  <option value="">Select sample name</option>
                  {(localState.currentMethod?.sampleName || [])
                    .sort(alphaNumericSorter)
                    .map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name} ({option.count})
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="instrument">
                <Form.Label>Instrument</Form.Label>
                <Form.Select
                  value={localState.currentInstrument?.name || ''}
                  onChange={(event) => handleChange('instrument', event)}
                >
                  <option value="">Select instrument</option>
                  {(localState.currentSampleName?.instrument || [])
                    .sort(alphaNumericSorter)
                    .map((option) => {
                      return (
                        <option key={option.name} value={option.name}>
                          {option.name} ({option.count})
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </>
          )}
        </Row>
        <Row>
          <Col style={{ maxWidth: 500, margin: '0 auto' }}>
            <Form id="formOrder">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell align="right">Order</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(
                      (localState.instrumentBeforeSampleName
                        ? localState.currentSampleName?.parameter
                        : localState.currentInstrument?.parameter) || []
                    )
                      .filter((param) => param.name !== 'Combined Z-score')
                      .sort(alphaNumericSorter)
                      .sort((a, b) => a.order - b.order)
                      .map((row) => {
                        return (
                          <TableRow key={row.name}>
                            <TableCell size="small">{row.name}</TableCell>
                            <TableCell size="small" align="right">
                              <TextField
                                disabled={!isAdmin(permission)}
                                name={row.name}
                                value={row.nextOrder || row.order}
                                type="number"
                                size="small"
                                onChange={handleChangeOrder}
                                sx={{ width: 60 }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  {isAdmin(permission) && (
                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={2} align="right">
                          <Button
                            variant="contained"
                            onClick={handleSaveOrder}
                            disabled={
                              (
                                (localState.instrumentBeforeSampleName
                                  ? localState.currentSampleName?.parameter
                                  : localState.currentInstrument?.parameter) || []
                              ).length === 0
                            }
                          >
                            Save
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </TableContainer>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ParameterSection;
