export const standardDeviation = (arr, meanIn, usePopulation = false) => {
  if (arr.length <= 0) {
    return undefined;
  }
  const mean = meanIn || arr.reduce((acc, val) => Number(acc) + Number(val), 0) / arr.length;
  return Math.sqrt(
    arr
      .reduce((acc, val) => acc.concat((Number(val) - mean) ** 2), [])
      .reduce((acc, val) => Number(acc) + Number(val), 0) /
      (arr.length - (usePopulation ? 0 : 1))
  );
};

export const getEarlyZ = (row, earlierArray) =>
  earlierArray?.rows?.find(
    (item) => item.equipment === row.equipment && item.facility === row.facility
  )?.z ?? '';
