import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import FormAlert from './FormAlert';
import contact from '../util/contact';
import { useForm } from 'react-hook-form';
import AppTextField from './Common/AppTextField';
function Contact({ showNameField, buttonText, buttonColor }) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      name: '',
      email: '',
      message: ''
    }
  });

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);

    contact
      .submit(data)
      .then(() => {
        // Clear form
        reset();
        // Show success alert message
        setFormAlert({
          type: 'success',
          message: 'Your message has been sent!'
        });
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: 'error',
          message: error.message
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <>
      {formAlert && <FormAlert type={formAlert.type} message={formAlert.message} />}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-2">
          {showNameField && (
            <Form.Group as={Col} xs={12} sm={6} controlId="formName">
              <AppTextField
                name="name"
                placeholder="Name"
                control={control}
                rules={{ required: 'Please enter your name' }}
              />
            </Form.Group>
          )}

          <Form.Group as={Col} xs={12} sm={showNameField ? 6 : 12} controlId="formEmail">
            <AppTextField
              name="email"
              control={control}
              rules={{ required: 'Please enter your email' }}
              placeholder="Email"
              type="email"
            />
          </Form.Group>
        </Row>
        <Form.Group controlId="formMessage" className="mb-2">
          <AppTextField
            name="message"
            control={control}
            rules={{ required: 'Please enter a message' }}
            placeholder="Message"
            multiline
          />
        </Form.Group>
        <Button variant={buttonColor} size="lg" type="submit" disabled={pending} className="w-100">
          <span>{buttonText}</span>

          {pending && (
            <Spinner animation="border" size="sm" role="status" aria-hidden className="ml-2">
              <span className="sr-only">Sending...</span>
            </Spinner>
          )}
        </Button>
      </Form>
    </>
  );
}

Contact.propTypes = {
  showNameField: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string
};

Contact.defaultProps = {
  showNameField: true,
  buttonText: 'Send',
  buttonColor: 'primary'
};

export default Contact;
