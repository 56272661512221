import { useState } from 'react';
import getMegaChartData from './Charts';

const useCharts = () => {
  const [chartData, setChartData] = useState([]);

  const scChartAllData = getMegaChartData(
    true,
    false,
    false,
    false,
    0,
    undefined,
    undefined,
    'date',
    'result',
    'refHigh',
    'refLow',
    'reference',
    '',
    '',
    '',
    'green',
    'red',
    'red',
    'yellow',
    '',
    '',
    '',
    100,
    'square',
    chartData
  );
  const mrChartAllData = getMegaChartData(
    true,
    false,
    false,
    false,
    0,
    undefined,
    undefined,
    'date',
    'mrRpd',
    'mrAvg',
    'mrUcl',
    '',
    '',
    '',
    '',
    'green',
    'orange',
    'orange',
    '',
    '',
    '',
    '',
    100,
    '',
    chartData
  );
  const iChartAllDataStdCmp = getMegaChartData(
    true,
    false,
    false,
    false,
    0,
    undefined,
    undefined,
    'date',
    'rpd',
    'trendRpd',
    'iAvg',
    'iUcl',
    'iLcl',
    'i15sig',
    'i15signeg',
    'green',
    'orange',
    'blue',
    'orange',
    'orange',
    'purple',
    'purple',
    100,
    '',
    chartData
  );

  return {
    fromData: (d) => setChartData(d),
    scChartAllData,
    mrChartAllData,
    iChartAllDataStdCmp
  };
};

export default useCharts;
