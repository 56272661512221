import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from 'rsuite/Table';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import './dashboard.scss';
import IconButton from 'rsuite/IconButton';
import DetailIcon from '@rsuite/icons/Detail';
import Tag from 'rsuite/Tag';
import Toggle from 'rsuite/Toggle';
import VisibleIcon from '@rsuite/icons/Visible';
import EyeCloseIcon from '@rsuite/icons/EyeClose';
import Drawer from 'rsuite/Drawer';
import LowerTable from '../../../LowerTable';
import Round from '../../round';
import { standardDeviation } from '../../common/utils';

const { Column, HeaderCell, Cell } = Table;

const rowKey = 'facility';
const DetailInfoCell = ({ rowData, dataKey, onChange, ...props }) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData);
      }}
      icon={<DetailIcon />}
    />
  </Cell>
);

DetailInfoCell.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string,
  onChange: PropTypes.func
};

DetailInfoCell.defaultProps = {
  rowData: {},
  dataKey: '',
  onChange: () => {}
};

const CheckCell = ({ rowData, dataKey, ...props }) => (
  <Cell style={{ padding: '6px' }} {...props}>
    {rowData[dataKey] ?? false ? (
      <WarningRoundIcon style={{ color: 'red' }} />
    ) : (
      <CheckRoundIcon style={{ color: 'green' }} />
    )}
  </Cell>
);

CheckCell.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string
};

CheckCell.defaultProps = {
  rowData: {},
  dataKey: ''
};

function TagsInfo({ facilityOpened }) {
  const tags = [
    {
      key: 'outlier',
      label: 'Outlier (GESD)'
    },
    {
      key: 'z3',
      label: 'Z > 3'
    },
    {
      key: 'zavg2',
      label: 'Z avg > 2'
    }
  ];

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '1rem'
      }}
    >
      {tags.map((t) => (
        <Tag key={t.key} size="lg" color={facilityOpened[t.key] ? 'red' : 'green'}>
          {t.label}
        </Tag>
      ))}
    </div>
  );
}

TagsInfo.propTypes = {
  facilityOpened: PropTypes.object
};

TagsInfo.defaultProps = {
  facilityOpened: {}
};

const DashboardScreen = ({ data, metaData, runData, loading, locationData, updateMetaData }) => {
  const [dataByRound, setDataByRound] = useState({});
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [latestRound, setLatestRound] = useState(undefined);
  const [facilityOpened, setFacilityOpened] = useState({});

  useEffect(() => {
    const result = {};
    const uniqueRunkey = new Set();
    let roundResult;
    data
      .sort((a, b) => {
        const roundA = new Round(a.round);
        const roundB = new Round(b.round);
        return roundB.compare(roundA);
      })
      .forEach((item) => {
        const { round, facility, runKey } = item;

        if (!result[facility]) {
          result[facility] = { latestRound: new Round(round) };
        }

        if (!result[facility][round]) {
          result[facility][round] = [];
        }
        uniqueRunkey.add(runKey);
        result[facility][round].push(item);

        if (!roundResult) {
          roundResult = new Round(round);
        } else if (roundResult.compare(new Round(round)) < 0) {
          roundResult = new Round(round);
        }
      });
    setLatestRound(roundResult);
    setDataByRound(result);
  }, [data]);

  const getEarlyZ = (row, earlierArray, parameter, method) => {
    let result = '';
    for (const item of earlierArray) {
      const metadata = metaData[item?.key];
      if (
        item?.parameter === parameter &&
        item?.method === method &&
        item?.rows &&
        item.equipment === row.equipment
      ) {
        const rowResult = item.rows.find((value) => {
          return (
            value.facility === row.facility && value.equipment === row.equipment && !metadata?.omit
          );
        });
        if (rowResult) {
          result = rowResult.z;
          break;
        }
      }
    }
    return result;
  };

  const calculateTableData = () => {
    if (Object.keys(dataByRound).length < 1) {
      return [];
    }

    return Object.keys(dataByRound)
      .map((facility) => {
        const roundV = Object.keys(dataByRound[facility])
          .filter((round) => dataByRound[facility][round].length > 0)
          .map((round) => {
            const values = dataByRound[facility][round].map((item) => {
              const rd = runData[item.runKey];
              return { ...item, ...rd };
            });

            return { [round]: values };
          })
          .reduce((acc, val) => Object.assign(acc, val), {});

        const thisRound = latestRound.toString();

        let rows = [];
        if (roundV[thisRound]) {
          rows = roundV[thisRound]
            .filter((rvKey) => {
              const metadata = metaData[rvKey?.key];
              return !metadata?.omit;
            })
            .flatMap((rv) => {
              const { method, parameter, round } = rv;
              return rv?.rows
                ?.filter(
                  (item) =>
                    item.facility === rv.facility &&
                    item.equipment === rv.equipment &&
                    Number(rv.result) === item.result
                )
                .map((item) => {
                  const priorRoundZ = getEarlyZ(
                    item,
                    roundV[latestRound.quarterEarlier()],
                    rv.parameter,
                    rv.method
                  );
                  const priorRound2Z = getEarlyZ(
                    item,
                    roundV[latestRound.halfYearEarlier()],
                    rv.parameter,
                    rv.method
                  );
                  const priorRound3Z = getEarlyZ(
                    item,
                    roundV[latestRound.threeQuarterEarlier()],
                    rv.parameter,
                    rv.method
                  );
                  const array = [item.z, priorRoundZ, priorRound2Z, priorRound3Z]
                    .filter((i) => !!i)
                    .map((i) => Number(i));

                  const avgZ = array.reduce((a, b) => a + b, 0) / array.length;
                  const stdZ = standardDeviation(array, avgZ);

                  const silenceKey =
                    `${method}-${parameter}-${round}-${item.facility}-${item.equipment}`
                      .replace(/["%$#/][.]/g, '')
                      .trim()
                      .toUpperCase();

                  const silenced = metaData[silenceKey]?.include ?? false;
                  const gesdOutlier = item.notes?.includes('R') ?? false ? 'Y' : 'N';
                  return {
                    ...item,
                    priorRound2Z,
                    priorRound3Z,
                    priorRoundZ,
                    avgZ,
                    stdZ,
                    silenceKey,
                    silenced,
                    round,
                    parameter,
                    method,
                    gesdOutlier
                  };
                });
            })
            .filter((fd) => {
              return (
                fd &&
                (String(fd.notes).includes('R') ||
                  Math.abs(Number(fd.z)) > 3 ||
                  Math.abs(Number(fd.avgZ)) > 2)
              );
            });
        }
        if (rows.length > 0) {
          // remove duplicate
          rows = rows.filter(
            (value, index, self) =>
              index ===
              self.findIndex(
                (t) =>
                  t.method === value.method &&
                  t.equipment === value.equipment &&
                  t.parameter === value.parameter
              )
          );
        }
        const outlier = rows.some((fd) => !fd.silenced && String(fd.notes).includes('R'));
        const facilityName = locationData.get(facility)?.locationName || facility;
        return {
          facility,
          facilityName,
          display: true,
          outlier,
          z3: rows.some((fd) => !fd.silenced && Math.abs(Number(fd.z)) > 3),
          zavg2: rows.some((fd) => !fd.silenced && Math.abs(Number(fd.avgZ)) > 2),
          rows
        };
      })
      .filter((item) => item !== null)
      .sort((a, b) => {
        const nameA = a.facilityName.toUpperCase();
        const nameB = b.facilityName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  };

  useEffect(() => {
    if (Object.keys(runData).length > 0 && Object.keys(metaData).length) {
      const newTableData = calculateTableData();
      setTableData(newTableData);
      // update ui for bottom Drawer.
      if (open && facilityOpened) {
        const openItem = newTableData.find((item) => item.facility === facilityOpened.facility);
        if (openItem) {
          setFacilityOpened(openItem);
        }
      }
    }
  }, [runData, metaData, dataByRound]);

  const handleExpanded = (rowData) => {
    setOpen(true);
    setFacilityOpened(rowData);
  };

  const labels = [
    {
      label: 'Method',
      key: 'method'
    },
    {
      label: 'Parameter',
      key: 'parameter'
    },
    {
      label: 'Instrument',
      key: 'equipment'
    },
    {
      label: 'Round',
      key: 'round'
    },
    {
      label: 'GESD Outlier',
      key: 'gesdOutlier'
    },
    {
      label: 'Z-score',
      key: 'z'
    },
    {
      label: 'Z-average',
      key: 'avgZ'
    },
    {
      label: 'Silence',
      key: 'silenced'
    }
  ];

  function changeSilence(rowData, silenced) {
    updateMetaData({ ...metaData[rowData.silenceKey], include: silenced, key: rowData.silenceKey });

    setFacilityOpened({
      ...facilityOpened,
      rows: facilityOpened.rows.map((row) => {
        if (row.silenceKey === rowData.silenceKey) {
          return {
            ...row,
            silenced
          };
        }
        return row;
      })
    });
  }

  function CustomCell(dataKey) {
    switch (dataKey) {
      case 'silenced':
        return (
          <Cell>
            {(rd) => {
              return (
                <Toggle
                  style={{ width: '100%' }}
                  size="lg"
                  unCheckedChildren={<VisibleIcon />}
                  checkedChildren={<EyeCloseIcon />}
                  checked={rd.silenced}
                  onChange={(include) => {
                    changeSilence(rd, include);
                  }}
                />
              );
            }}
          </Cell>
        );
      case 'z':
      case 'avgZ':
        return (
          <Cell>
            {(rowData) => {
              return Math.round(rowData[dataKey] * 1000) / 1000;
            }}
          </Cell>
        );
      default:
        return <Cell dataKey={dataKey} />;
    }
  }

  return (
    <div className="content">
      <div className="contentTable">
        <Table
          data={tableData}
          bordered
          cellBordered
          rowHeight={50}
          rowKey={rowKey}
          height={600}
          loading={loading}
        >
          <Column width={70} align="center">
            <HeaderCell>#</HeaderCell>
            <DetailInfoCell dataKey="id" onChange={handleExpanded} />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Facility</HeaderCell>
            <Cell dataKey="facilityName" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Outlier (GESD)</HeaderCell>
            <CheckCell dataKey="outlier" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>{`Z > 3`}</HeaderCell>
            <CheckCell dataKey="z3" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>{`Z avg > 2`} </HeaderCell>
            <CheckCell dataKey="zavg2" />
          </Column>
        </Table>

        <Drawer size="lg" placement="bottom" open={open} onClose={() => setOpen(false)}>
          <Drawer.Header>
            <Drawer.Title>{facilityOpened?.facilityName}</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            {TagsInfo({ facilityOpened })}

            <LowerTable body={facilityOpened?.rows} fullHeight>
              {labels.map((item) => {
                return (
                  <Column flexGrow={1} align="center" key={item.key}>
                    <HeaderCell>{item.label}</HeaderCell>
                    {CustomCell(item.key)}
                  </Column>
                );
              })}
            </LowerTable>
          </Drawer.Body>
        </Drawer>
      </div>
    </div>
  );
};

DashboardScreen.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  metaData: PropTypes.object,
  runData: PropTypes.object,
  loading: PropTypes.bool,
  locationData: PropTypes.instanceOf(Map),
  updateMetaData: PropTypes.func
};

DashboardScreen.defaultProps = {
  data: [],
  metaData: {},
  runData: {},
  loading: false,
  locationData: new Map(),
  updateMetaData: () => {}
};

export default DashboardScreen;
