import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalError = ({ show, setShow, title, messages, textButton, runSkip, skip }) => {
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal size="md" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>{title}</Modal.Header>

        <Modal.Body>
          {messages?.map((message, index) => (
            <p key={index}>{message}</p>
          ))}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            {textButton}
          </Button>
          {skip && (
            <Button
              variant="primary"
              onClick={() => {
                runSkip();
                handleClose();
              }}
            >
              {skip}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalError;
