import React from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Section from './Section';
import { Link } from '../util/router';
import './Footer.scss';

function Footer({ bg, textColor, bgImage, bgImageOpacity, logo, copyright }) {
  return (
    <Section
      bg={bg}
      textColor={textColor}
      size="sm"
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
      className="footer"
    >
      <Container>
        <div className="FooterComponent__inner">
          <div className="brand left">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="links right">
            <Link to="/about">About</Link>
            <Link to="/faq">FAQ</Link>
            <Link to="/contact">Contact</Link>
            <a target="_blank" href="https://medium.com" rel="noopener noreferrer">
              Blog
            </a>
          </div>
          <div className="social right">
            <a href="https://twitter.com/divjoy" target="_blank" rel="noopener noreferrer">
              <span className="icon">
                <i className="fab fa-twitter" />
              </span>
            </a>
            <a href="https://facebook.com/DivjoyOfficial" target="_blank" rel="noopener noreferrer">
              <span className="icon">
                <i className="fab fa-facebook-f" />
              </span>
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <span className="icon">
                <i className="fab fa-instagram" />
              </span>
            </a>
          </div>
          <div className="copyright left">
            {copyright}
            <Link to="/legal/terms-of-service">Terms</Link>
            <Link to="/legal/privacy-policy">Privacy</Link>
          </div>
        </div>
      </Container>
    </Section>
  );
}

Footer.propTypes = {
  bg: PropTypes.string,
  textColor: PropTypes.string,
  bgImage: PropTypes.string,
  bgImageOpacity: PropTypes.number,
  logo: PropTypes.string,
  copyright: PropTypes.string
};

Footer.defaultProps = {
  bg: 'dark',
  textColor: 'light',
  bgImage: '',
  bgImageOpacity: 0.8,
  logo: '',
  copyright: ''
};

export default Footer;
