import React from 'react';
import Meta from '../components/Meta';
import FaqSection from '../components/FaqSection';

function FaqPage() {
  return (
    <>
      <Meta title="Faq" />
      <FaqSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
    </>
  );
}

export default FaqPage;
