import Tag from 'rsuite/Tag';
import React from 'react';
import PropTypes from 'prop-types';

// Location:
// Date:
// Assessment by:
const SampleInfo = ({ program, parameter, fuelType, user, location }) => {
  const tagStyle = { margin: 0, color: 'black' };
  const blueStyle = { ...tagStyle, backgroundColor: '#7fc3ff' };
  return (
    <div
      style={{
        display: 'grid',
        gap: '0.5rem',
        gridTemplateColumns: 'repeat(4, 1fr)'
      }}
    >
      <Tag style={blueStyle}>Program:</Tag>
      <Tag style={tagStyle}>{program}</Tag>

      <Tag style={blueStyle}>Location:</Tag>
      <Tag style={tagStyle}>{location}</Tag>

      <Tag style={blueStyle}>Parameter:</Tag>
      <Tag style={tagStyle}>{parameter}</Tag>

      <Tag style={blueStyle}>Date:</Tag>
      <Tag style={tagStyle}>{new Date().toLocaleDateString()}</Tag>
      <Tag style={blueStyle}>Fuel Type:</Tag>
      <Tag style={tagStyle}>{fuelType}</Tag>

      <Tag style={blueStyle}>Assesment by:</Tag>
      <Tag style={tagStyle}>{user}</Tag>
    </div>
  );
};

SampleInfo.propTypes = {
  program: PropTypes.string.isRequired,
  parameter: PropTypes.string.isRequired,
  fuelType: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired
};

export default SampleInfo;
