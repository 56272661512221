class Round {
  constructor(round) {
    const split = round.split('Q');

    this.year = parseInt(split[0], 10);
    if (split[1].includes('_')) {
      const [quarter, extra] = split[1].split('_');
      this.quarter = parseInt(quarter, 10);
      this.extra = extra;
    } else {
      [, this.quarter] = split;
      this.extra = '';
    }
  }

  minus(quarter) {
    let quarterBefore = this.quarter - quarter;
    let yearBefore = this.year;
    if (quarterBefore <= 0) {
      quarterBefore += 4;
      yearBefore -= 1;
    }
    return `${yearBefore}Q${quarterBefore}`;
  }

  quarterEarlier() {
    return this.minus(1);
  }

  halfYearEarlier() {
    return this.minus(2);
  }

  threeQuarterEarlier() {
    return this.minus(3);
  }

  compare(round) {
    if (this.year !== round.year) return this.year - round.year;

    if (this.quarter !== round.quarter) return this.quarter - round.quarter;

    if (this.extra !== round.extra) return this.extra.localeCompare(round.extra);

    return 0;
  }

  latestQuarters() {
    return [
      this.toString(),
      this.quarterEarlier(),
      this.halfYearEarlier(),
      this.threeQuarterEarlier()
    ];
  }

  toString() {
    return `${this.year}Q${this.quarter}${this.extra ? `_${this.extra}` : ''}`;
  }

  isAfter(toCompare) {
    return this.compare(toCompare) > 0;
  }
}

export default Round;
