import React from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Section from './Section';
import SectionHeader from './SectionHeader';
import Avatar from './Avatar';

function TeamBiosSection({ bg, textColor, size, bgImage, bgImageOpacity, title, subtitle }) {
  const items = [
    {
      avatar: 'https://uploads.divjoy.com/pravatar-150x-68.jpeg',
      name: 'John Smith',
      role: 'Software Engineer',
      bio: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo.'
    },
    {
      avatar: 'https://uploads.divjoy.com/pravatar-150x-35.jpeg',
      name: 'Lisa Zinn',
      role: 'Software Engineer',
      bio: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!'
    },
    {
      avatar: 'https://uploads.divjoy.com/pravatar-150x-16.jpeg',
      name: 'Diana Low',
      role: 'Designer',
      bio: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!'
    }
  ];

  return (
    <Section
      bg={bg}
      textColor={textColor}
      size={size}
      bgImage={bgImage}
      bgImageOpacity={bgImageOpacity}
    >
      <Container>
        <SectionHeader title={title} subtitle={subtitle} size={2} spaced className="text-center" />
        <Row className="justify-content-center">
          {items.map((item) => (
            <Col xs={12} md={6} lg={4} className="py-3 d-flex align-items-stretch" key={item.name}>
              <Card>
                <Card.Body className="d-flex flex-column text-center align-items-center p-4">
                  <Avatar src={item.avatar} alt={item.name} size="128px" />
                  <h6 className="font-weight-bold mb-0 mt-4">{item.name}</h6>
                  <small>{item.role}</small>
                  <Card.Text className="mt-4">{item.bio}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

TeamBiosSection.propTypes = {
  bg: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  bgImage: PropTypes.string.isRequired,
  bgImageOpacity: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
};

export default TeamBiosSection;
