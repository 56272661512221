import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import FormAlert from './FormAlert';
import AuthSocial from './AuthSocial';
import { useAuth } from '../util/auth';
import { useForm } from 'react-hook-form';
import AppTextField from '../components/Common/AppTextField';
function ReauthModal({ callback, onDone, provider }) {
  const auth = useAuth();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);

  const { handleSubmit, control, getValues } = useForm({
    defaultValues: {
      pass: ''
    }
  });

  const onSubmit = (data) => {
    const { pass } = data;
    setPending(true);

    auth
      .signin(auth.user.email, pass)
      .then(() => {
        // Call failed action that originally required reauth
        callback();
        // Let parent know we're done so they can hide modal
        onDone();
      })
      .catch((error) => {
        // Hide pending indicator
        setPending(false);
        // Show error alert message
        setFormAlert({
          type: 'error',
          message: error.message
        });
      });
  };

  return (
    <Modal show centered animation={false} onHide={onDone}>
      <Modal.Header closeButton>Please sign in again to complete this action</Modal.Header>
      <Modal.Body>
        {formAlert && <FormAlert type={formAlert.type} message={formAlert.message} />}

        {provider === 'password' && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group controlId="formConfirmPass">
              <AppTextField
                name="pass"
                control={control}
                rules={{ required: 'Please enter your password' }}
                placeholder="Password"
                type="password"
              />
            </Form.Group>
            <Button size="lg" variant="primary" block type="submit" disabled={pending}>
              <span>Submit</span>

              {pending && (
                <Spinner animation="border" size="sm" role="status" aria-hidden className="ml-2">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </Button>
          </Form>
        )}

        {provider !== 'password' && (
          <AuthSocial
            type="signin"
            buttonText="Sign in"
            providers={[provider]}
            showLastUsed={false}
            onAuth={() => {
              callback();
              onDone();
            }}
            onError={(message) => {
              setFormAlert({
                type: 'error',
                message
              });
            }}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

ReauthModal.propTypes = {
  callback: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  provider: PropTypes.string.isRequired
};

export default ReauthModal;
