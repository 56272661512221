import React from 'react';
import PropTypes from 'prop-types';
import './BackgroundImage.scss';

function BackgroundImage({ image, opacity, repeat }) {
  return (
    <div
      className={`BackgroundImage${repeat ? ' repeat' : ''}`}
      style={{
        '--image': `url(${image})`,
        '--opacity': opacity
      }}
    />
  );
}

BackgroundImage.propTypes = {
  image: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
  repeat: PropTypes.bool.isRequired
};

export default BackgroundImage;
